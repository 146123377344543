import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, addDoc, setDoc, doc, serverTimestamp, query, where, deleteDoc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import styled from 'styled-components';
import {
  FaSearch,
  FaIndustry,
  FaEnvelope,
  FaUser,
  FaStar,
  FaMapMarkerAlt,
  FaPhone,
  FaGlobe,
  FaRegBuilding,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import ExpertNavbar from '../pages/expertnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import HeroImage from '../assets/images/hero/bg4.jpg';

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(0);
  const [reviews, setReviews] = useState({});
  const [filter, setFilter] = useState({ industry: '', country: '', name: '' });
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [followers, setFollowers] = useState({});
  const companiesPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      const snapshot = await getDocs(collection(db, 'companies'));
      setCompanies(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      if (snapshot.docs.length > 0) {
        setSelectedCompany(snapshot.docs[0].data());
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    const fetchFollowersAndReviews = async () => {
      const followersSnapshot = await getDocs(collection(db, 'followers'));
      const followersData = {};
      followersSnapshot.forEach((doc) => {
        const data = doc.data();
        if (!followersData[data.companyId]) {
          followersData[data.companyId] = [];
        }
        followersData[data.companyId].push(data.userEmail);
      });
      setFollowers(followersData);

      const reviewsSnapshot = await getDocs(collection(db, 'reviews'));
      const reviewsData = {};
      reviewsSnapshot.forEach((doc) => {
        const data = doc.data();
        if (!reviewsData[data.companyId]) {
          reviewsData[data.companyId] = [];
        }
        reviewsData[data.companyId].push({ id: doc.id, ...data });
      });
      setReviews(reviewsData);
    };

    fetchFollowersAndReviews();
  }, []);

  const handleViewCompany = (company) => {
    setSelectedCompany(company);
  };

  const handleFollowCompany = async (companyId) => {
    if (!user) {
      navigate('/login');
      return;
    }

    const followDoc = doc(db, 'followers', `${user.email}_${companyId}`);
    try {
      const docSnapshot = await getDocs(
        query(
          collection(db, 'followers'),
          where('userEmail', '==', user.email),
          where('companyId', '==', companyId)
        )
      );
      if (docSnapshot.empty) {
        await setDoc(followDoc, {
          userEmail: user.email,
          companyId,
          timestamp: serverTimestamp(),
        });
        setFollowers((prev) => ({
          ...prev,
          [companyId]: [...(prev[companyId] || []), user.email],
        }));
      } else {
        await deleteDoc(followDoc);
        setFollowers((prev) => ({
          ...prev,
          [companyId]: (prev[companyId] || []).filter((email) => email !== user.email),
        }));
      }
      await addDoc(collection(db, 'notifications'), {
        email: selectedCompany.userEmail,
        message: `${user.email} ${docSnapshot.empty ? 'followed' : 'unfollowed'} your company.`,
        read: false,
        timestamp: serverTimestamp(),
      });
      await addDoc(collection(db, 'expertsNotification'), {
        email: user.email,
        message: `You ${docSnapshot.empty ? 'followed' : 'unfollowed'} ${selectedCompany.companyName}.`,
        read: false,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error following/unfollowing company: ', error);
    }
  };

  const handleReviewSubmit = async () => {
    if (!reviewText.trim()) {
      console.error('Cannot submit an empty review.');
      return;
    }
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      await addDoc(collection(db, 'reviews'), {
        companyId: selectedCompany.id,
        userEmail: user.email,
        reviewText,
        timestamp: serverTimestamp(),
        rating: reviewRating,
      });
      setReviews((prev) => ({
        ...prev,
        [selectedCompany.id]: [
          ...(prev[selectedCompany.id] || []),
          { reviewText, timestamp: new Date(), userEmail: user.email, rating: reviewRating },
        ],
      }));
      setReviewText('');
      setReviewRating(0);
      await addDoc(collection(db, 'notifications'), {
        email: selectedCompany.userEmail,
        message: `${user.email} reviewed your company.`,
        read: false,
        timestamp: serverTimestamp(),
      });
      await addDoc(collection(db, 'expertsNotification'), {
        email: user.email,
        message: `You reviewed ${selectedCompany.companyName}.`,
        read: false,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error submitting review: ', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const filteredCompanies = companies.filter((company) => {
    const matchesIndustry = filter.industry === '' || company.industry === filter.industry;
    const matchesCountry = filter.country === '' || company.country === filter.country;
    const matchesName = company.companyName.toLowerCase().includes(filter.name.toLowerCase());
    return matchesIndustry && matchesCountry && matchesName;
  });

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getAverageRating = (companyId) => {
    const companyReviews = reviews[companyId] || [];
    if (companyReviews.length === 0) return 'No Ratings';
    const averageRating = (
      companyReviews.reduce((acc, review) => acc + (review.rating || 0), 0) / companyReviews.length
    ).toFixed(1);
    return `${averageRating} Stars`;
  };

  return (
    <>
      <ExpertNavbar />
      <HeroSection>
        <HeroTitle>Discover Companies</HeroTitle>
        <HeroSubtitle>Find the best companies to follow and review</HeroSubtitle>
      </HeroSection>
      <BackButton onClick={() => navigate('/expertdashboard')}>
        <FaChevronLeft style={{ marginRight: '10px' }} />
        Back to Dashboard
      </BackButton>

      <Container>
        <FilterContainer>
          <FilterTitle>Filter Companies</FilterTitle>
          <FilterGroup>
            <FilterLabel>
              <FaIndustry style={{ marginRight: '5px' }} />
              Industry
            </FilterLabel>
            <FilterSelect name="industry" onChange={handleFilterChange}>
              <option value="">All Industries</option>
              {/* Add industry options here */}
            </FilterSelect>
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>
              <FaGlobe style={{ marginRight: '5px' }} />
              Country
            </FilterLabel>
            <FilterSelect name="country" onChange={handleFilterChange}>
              <option value="">All Countries</option>
              {/* Add country options here */}
            </FilterSelect>
          </FilterGroup>
          <FilterGroup>
            <FilterLabel>
              <FaSearch style={{ marginRight: '5px' }} />
              Name
            </FilterLabel>
            <FilterInput name="name" onChange={handleFilterChange} placeholder="Search by name..." />
          </FilterGroup>
        </FilterContainer>
        <Sidebar>
          {currentCompanies.map((company) => (
            <CompanyCard key={company.id}>
              <Logo src={company.logo || 'https://via.placeholder.com/60'} alt="Company Logo" />
              <CompanyInfo>
                <CompanyName>{company.companyName}</CompanyName>
                <Industry>{company.industry}</Industry>
                <Badge color="#007bff">
                  <FaRegBuilding style={{ marginRight: '5px' }} />
                  {followers[company.id]?.length || 0} Followers
                </Badge>
                <Badge color="#28a745">
                  <FaStar style={{ marginRight: '5px' }} />
                  {getAverageRating(company.id)}
                </Badge>
              </CompanyInfo>
              <ActionButtons>
                <Button onClick={() => handleFollowCompany(company.id)} primary>
                  {followers[company.id] && followers[company.id].includes(user?.email)
                    ? 'Unfollow'
                    : 'Follow'}
                </Button>
                <Button onClick={() => handleViewCompany(company)}>View Details</Button>
              </ActionButtons>
            </CompanyCard>
          ))}
          <Pagination>
            {Array.from({ length: Math.ceil(filteredCompanies.length / companiesPerPage) }, (_, index) => (
              <PageNumber key={index} onClick={() => paginate(index + 1)}>
                {index + 1}
              </PageNumber>
            ))}
          </Pagination>
        </Sidebar>
        {selectedCompany && (
          <CompanyDetailsContainer>
            <CompanyDetailsContent>
              <CloseButton onClick={() => setSelectedCompany(null)}>×</CloseButton>
              <CompanyHeader>
                <Logo src={selectedCompany.logo || 'https://via.placeholder.com/120'} alt="Company Logo" />
                <HeaderInfo>
                  <CompanyName>{selectedCompany.companyName}</CompanyName>
                  <Industry>{selectedCompany.industry}</Industry>
                  <Badge color="#007bff">
                    <FaRegBuilding style={{ marginRight: '5px' }} />
                    {followers[selectedCompany.id]?.length || 0} Followers
                  </Badge>
                  <Badge color="#28a745">
                    <FaStar style={{ marginRight: '5px' }} />
                    {getAverageRating(selectedCompany.id)}
                  </Badge>
                </HeaderInfo>
              </CompanyHeader>
              <CompanyDetails>
                <Detail>
                  <FaMapMarkerAlt style={{ marginRight: '5px', color: '#007bff' }} />
                  <strong>Address:</strong> {selectedCompany.streetAddress}, {selectedCompany.state},{' '}
                  {selectedCompany.country}
                </Detail>
                <Detail>
                  <FaEnvelope style={{ marginRight: '5px', color: '#007bff' }} />
                  <strong>Email:</strong> {selectedCompany.contactEmail}
                </Detail>
                <Detail>
                  <FaPhone style={{ marginRight: '5px', color: '#007bff' }} />
                  <strong>Phone:</strong> {selectedCompany.contactPhone}
                </Detail>
                <Detail>
                  <FaGlobe style={{ marginRight: '5px', color: '#007bff' }} />
                  <strong>Website:</strong>{' '}
                  <a href={selectedCompany.website} target="_blank" rel="noopener noreferrer">
                    {selectedCompany.website}
                  </a>
                </Detail>
                <Detail>
                  <FaUser style={{ marginRight: '5px', color: '#007bff' }} />
                  <strong>Employee Size:</strong> {selectedCompany.employeeSize}
                </Detail>
                <PhotosSection>
                  <SectionTitle>Office Photos</SectionTitle>
                  <OfficePhotos>
                    {selectedCompany.officePhotos.map((photo, index) => (
                      <OfficePhoto key={index} src={photo} alt={`Office Photo ${index + 1}`} />
                    ))}
                  </OfficePhotos>
                </PhotosSection>
                <JobsSection>
                  <SectionTitle>Jobs Posted</SectionTitle>
                  {/* Implement fetching and displaying jobs posted by the company */}
                </JobsSection>
              </CompanyDetails>
              <ReviewSection>
                <ReviewHeader>Reviews</ReviewHeader>
                <StarRating>
                  {Array.from({ length: 5 }, (_, index) => (
                    <FaStar
                      key={index}
                      onClick={() => setReviewRating(index + 1)}
                      color={index < reviewRating ? '#ffc107' : '#e4e5e9'}
                      style={{ cursor: 'pointer', marginRight: '5px' }}
                    />
                  ))}
                </StarRating>
                <ReviewInput
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  placeholder="Write a review..."
                />
                <SubmitReviewButton onClick={handleReviewSubmit}>Submit Review</SubmitReviewButton>
                {(reviews[selectedCompany.id] || []).length === 0 ? (
                  <p>No Reviews</p>
                ) : (
                  reviews[selectedCompany.id].map((review) => (
                    <Review key={review.id}>
                      <FaUser style={{ marginRight: '8px' }} />
                      {review.userEmail}: {review.reviewText}
                      <StarRating>
                        {Array.from({ length: 5 }, (_, starIndex) => (
                          <FaStar
                            key={starIndex}
                            color={starIndex < (review.rating || 0) ? '#ffc107' : '#e4e5e9'}
                          />
                        ))}
                      </StarRating>
                    </Review>
                  ))
                )}
              </ReviewSection>
            </CompanyDetailsContent>
          </CompanyDetailsContainer>
        )}
      </Container>
      <Footer />
      <ScrollTop />
    </>
  );
};

const HeroSection = styled.div`
  background-image: url(${HeroImage});
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 0 20px;
  animation: fadeIn 1.5s ease-in-out;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  max-width: 600px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const BackButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const FilterContainer = styled.div`
  width: 100%;
  max-width: 350px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterTitle = styled.h3`
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const FilterGroup = styled.div`
  margin-bottom: 20px;
`;

const FilterLabel = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  display: block;
  color: #333;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
`;

const FilterInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
`;

const Sidebar = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CompanyCard = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
  margin-bottom: 15px;
  background: white;
  border: 3px solid #007bff;
`;

const CompanyInfo = styled.div`
  text-align: center;
`;

const CompanyName = styled.h3`
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Industry = styled.p`
  color: #6c757d;
  font-size: 1rem;
  margin-bottom: 15px;
`;

const Badge = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 5px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#007bff' : '#ffffff')};
  color: ${(props) => (props.primary ? '#ffffff' : '#007bff')};
  border: ${(props) => (props.primary ? 'none' : '2px solid #007bff')};
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#f0f0f0')};
    transform: translateY(-3px);
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageNumber = styled.span`
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  background-color: #f8f9fa;
  border-radius: 8px;
  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;

const CompanyDetailsContainer = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-left: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    position: fixed;
    top: 10%;
    bottom: 10%;
    left: 5%;
    right: 5%;
    background-color: rgba(255, 255, 255, 0.95);
    overflow-y: scroll;
    z-index: 1000;
  }
`;

const CompanyDetailsContent = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #007bff;

  @media (min-width: 769px) {
    display: none;
  }
`;

const CompanyHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const HeaderInfo = styled.div`
  margin-left: 20px;
`;

const CompanyDetails = styled.div`
  margin-bottom: 20px;
`;

const Detail = styled.p`
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
  display: flex;
  align-items: center;
`;

const PhotosSection = styled.div`
  margin-top: 20px;
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
`;

const OfficePhotos = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const OfficePhoto = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const JobsSection = styled.div`
  margin-top: 20px;
`;

const ReviewSection = styled.div`
  margin-top: 30px;
`;

const ReviewHeader = styled.h4`
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
`;

const StarRating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ReviewInput = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  font-size: 1rem;
  resize: vertical;
`;

const SubmitReviewButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const Review = styled.div`
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Companies;
