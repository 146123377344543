import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Select from 'react-select';
import styled, { createGlobalStyle } from 'styled-components';
import { storage } from '../firebase';
import CustomNavbar from './customnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { FaPlus, FaMinus, FaUpload, FaArrowLeft, FaCalendar, FaArrowRight, FaCheck, FaBriefcase, FaFileAlt, FaDollarSign, FaGlobe, FaClipboardList } from 'react-icons/fa';
import jobPostingImage from '../assets/images/blog/04.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const categories = [
  'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
  'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
  'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
  'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
];

const skillsByCategory = {
  "Marketing": [
    "SEO", "Digital Advertising", "Content Strategy", "Social Media", "Email Marketing",
    "Brand Management", "Analytics", "PPC", "Content Creation", "Video Marketing",
    "Market Research", "Consumer Behavior", "Strategic Planning", "CRM", "Public Relations",
    "Event Planning", "Copywriting", "Influencer Marketing", "Affiliate Marketing", "Graphic Design"
  ],
  "Fullstack Engineer": [
    "JavaScript", "React", "Node.js", "SQL", "NoSQL", "CSS", "HTML", "Angular", "Vue.js", "Python",
    "PHP", "Ruby on Rails", "Java", "C#", ".NET", "TypeScript", "API Development", "Microservices", "AWS", "Docker"
  ],
  "Data Science": [
    "Python", "R", "SQL", "Machine Learning", "Deep Learning", "Data Visualization", "Big Data", "Statistics",
    "Data Mining", "Data Wrangling", "AI", "Neural Networks", "Predictive Modeling", "NLP", "Computer Vision",
    "Bayesian Methods", "Regression Analysis", "Decision Trees", "Cluster Analysis", "Data Integration"
  ],
  "Product Management": [
    "Product Lifecycle Management", "Market Analysis", "Scrum", "Agile", "User Testing",
    "Roadmap Planning", "Feature Definition", "Product Strategy", "Competitive Analysis", "Pricing Strategy",
    "Customer Engagement", "KPIs", "Stakeholder Management", "Backlog Management", "Sprint Planning",
    "Market Positioning", "Launch Management", "Cross-functional Team Leadership", "UX/UI Design", "Analytics"
  ],
  "Graphic Design": [
    "Adobe Photoshop", "Illustrator", "InDesign", "Sketch", "Figma", "Corel Draw", "Adobe XD", "UX Design",
    "UI Design", "Print Design", "Digital Illustration", "Branding", "Typography", "Color Theory", "Layout Design",
    "Motion Graphics", "3D Design", "Packaging Design", "Logo Creation", "Web Design"
  ],
  "UI/UX Design": [
    "User Research", "Wireframing", "Prototyping", "User Interface Design", "User Experience Design",
    "Usability Testing", "Interaction Design", "Visual Design", "Information Architecture", "Responsive Design",
    "Figma", "Sketch", "Adobe XD", "InVision", "Axure", "Accessibility", "CSS", "HTML", "JavaScript", "Agile UX"
  ],
  "Software Development": [
    "Java", "Python", "C#", "Ruby", "JavaScript", "PHP", "C++", "Mobile Development", "Web Development",
    "Software Testing", "DevOps", "Agile Methodologies", "API Design", "Security Practices", "Source Control",
    "Database Management", "Cloud Computing", "Performance Optimization", "System Architecture", "Continuous Integration"
  ],
  "Project Management": [
    "Agile Project Management", "Waterfall", "Scrum", "Kanban", "Risk Management", "Resource Allocation",
    "Stakeholder Communication", "Budget Management", "Scheduling", "Quality Control",
    "Procurement Management", "Change Management", "Project Planning", "Team Leadership", "Performance Tracking",
    "MS Project", "JIRA", "Asana", "Trello", "Lean Management"
  ],
  "Sales": [
    "Customer Relationship Management", "Negotiation", "Lead Generation", "Sales Planning", "Market Analysis",
    "Product Knowledge", "Customer Needs Analysis", "Sales Presentations", "Closing Techniques", "Team Management",
    "Revenue Growth", "Account Management", "B2B Sales", "B2C Sales", "Contract Negotiation",
    "Direct Sales", "Channel Sales", "E-commerce Sales", "Social Selling", "Sales Reporting"
  ],
  "Customer Support": [
    "Customer Service", "Technical Support", "Help Desk", "Client Relations", "Troubleshooting",
    "Product Support", "User Training", "Complaint Resolution", "Customer Satisfaction Analysis", "Service Level Agreements",
    "Support Ticket Management", "Live Chat Support", "Call Center Operations", "Feedback Collection", "Escalation Procedures",
    "Knowledge Base Maintenance", "Customer Onboarding", "CRM Software", "Remote Support Tools", "Customer Engagement"
  ],
  "Human Resources": [
    "Recruiting", "Talent Management", "Employee Relations", "Performance Management", "HR Policies",
    "Benefits Administration", "Workforce Planning", "Compliance", "Training & Development", "Succession Planning",
    "Compensation and Payroll", "Labor Relations", "HR Metrics", "Employee Engagement", "HR Software",
    "Organizational Development", "Diversity and Inclusion", "Employee Wellness", "Conflict Resolution", "Strategic HRM"
  ],
  "Finance": [
    "Financial Analysis", "Budgeting", "Forecasting", "Accounting", "Financial Reporting",
    "Risk Management", "Audit Compliance", "Tax Planning", "Investment Strategies", "Cash Flow Management",
    "Corporate Finance", "Cost Reduction", "Financial Planning", "Treasury Management", "Credit Analysis",
    "Bookkeeping", "GAAP Compliance", "Regulatory Compliance", "Financial Modeling", "ERP Systems"
  ],
  "Legal": [
    "Contract Law", "Corporate Law", "Litigation", "Intellectual Property", "Compliance",
    "Legal Research", "Legal Writing", "Mergers & Acquisitions", "Employment Law", "Real Estate Law",
    "Tax Law", "Bankruptcy", "Data Privacy", "Environmental Law", "Family Law",
    "Criminal Law", "International Law", "Civil Rights Law", "Estate Planning", "Arbitration"
  ],
  "Education": [
    "Curriculum Development", "Educational Leadership", "Classroom Management", "Educational Technology", "Student Assessment",
    "Special Education", "E-Learning", "Instructional Design", "Early Childhood Education", "Higher Education",
    "Teaching", "Academic Advising", "Learning Management Systems", "Student Engagement", "Course Development",
    "Education Policy", "Teacher Training", "Distance Learning", "Education Research", "Education Administration"
  ],
  "Healthcare": [
    "Patient Care", "Medical Knowledge", "Healthcare Administration", "Clinical Skills", "Emergency Care",
    "Medical Diagnostics", "Healthcare Compliance", "Surgical Procedures", "Patient Safety", "Pharmacology",
    "Therapeutic Management", "Medical Research", "Health Information Systems", "Public Health", "Mental Health",
    "Nursing", "Medical Imaging", "Healthcare Consulting", "Occupational Therapy", "Physical Therapy"
  ],
  "Real Estate": [
    "Property Management", "Real Estate Development", "Leasing", "Real Estate Economics", "Property Marketing",
    "Negotiation", "Market Analysis", "Real Estate Transactions", "Urban Planning", "Portfolio Management",
    "Commercial Real Estate", "Residential Homes", "Real Estate Financing", "Land Acquisition", "Real Estate Appraisal",
    "Investment Properties", "Due Diligence", "Building Management", "Property Law", "Escrow Management"
  ],
  "Logistics": [
    "Supply Chain Management", "Warehousing", "Transportation", "Logistics Planning", "Inventory Management",
    "Freight Forwarding", "Demand Planning", "Supply Chain Optimization", "Logistics Analysis", "International Logistics",
    "Customs Regulations", "Distribution Management", "Fleet Management", "Supply Chain Security", "Materials Management",
    "Procurement", "Shipping", "Supply Chain Software", "Vendor Management", "Operational Efficiency"
  ],
  "Manufacturing": [
    "Production Planning", "Manufacturing Processes", "Quality Control", "Lean Manufacturing", "Six Sigma",
    "Supply Chain Management", "Equipment Maintenance", "Product Development", "Continuous Improvement", "Safety Management",
    "Operations Management", "Cost Reduction", "Inventory Control", "Factory Automation", "Process Engineering",
    "ISO Standards", "Materials Science", "Workforce Management", "Plant Management", "Compliance Management"
  ],
  "Hospitality": [
    "Customer Service", "Hotel Management", "Event Management", "Food and Beverage Management", "Hospitality Marketing",
    "Housekeeping", "Front Office", "Revenue Management", "Reservations", "Guest Relations",
    "Hospitality Technology", "Tourism", "Catering Management", "Property Management Systems", "Banquet Operations",
    "Hospitality Training", "Menu Planning", "Hospitality Law", "Spa Management", "Crisis Management"
  ],
  "Other": [
    "Other"
  ]
};

const countries = [
  "USA", "Canada", "United Kingdom", "Australia", "Germany", "France", "Spain", "Italy", "Brazil", "China",
  "India", "Japan", "Russia", "South Africa", "Mexico", "Indonesia", "Netherlands", "Turkey", "Saudi Arabia",
  "Switzerland", "Argentina", "Sweden", "Nigeria", "Poland", "Belgium", "Thailand", "Austria", "Norway",
  "Iran", "United Arab Emirates", "Colombia", "South Korea", "Malaysia", "Singapore", "Denmark", "Finland",
  "Chile", "Ireland", "Pakistan", "Egypt", "Philippines", "Portugal", "Czech Republic", "Romania", "Vietnam",
  "Israel", "Greece", "Hong Kong", "Peru"
];

const statesByCountry = {
  "USA": ["New York", "California", "Texas", "Florida", "Illinois"],
  "Canada": ["Ontario", "Quebec", "British Columbia", "Alberta", "Nova Scotia"],
  "Australia": ["New South Wales", "Victoria", "Queensland", "Western Australia", "South Australia"],
  "Germany": ["Berlin", "Bavaria", "Hamburg", "North Rhine-Westphalia", "Baden-Württemberg"],
  "France": ["Île-de-France", "Provence-Alpes-Côte d'Azur", "Occitanie", "Nouvelle-Aquitaine", "Auvergne-Rhône-Alpes"],
  "Brazil": ["São Paulo", "Rio de Janeiro", "Minas Gerais", "Bahia", "Paraná"],
  "India": ["Maharashtra", "Karnataka", "Tamil Nadu", "Uttar Pradesh", "Gujarat"],
  "Russia": ["Moscow", "Saint Petersburg", "Novosibirsk", "Yekaterinburg", "Kazan"]
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.10);
  margin-top: 20px;
`;

const FormField = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-weight: 600;
  color: #333;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconWrapper = styled.div`
  color: #007bff;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  flex: 3;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  flex: 3;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
`;

const SelectStyled = styled(Select)`
  flex: 3;

  .react-select__control {
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:hover {
      border-color: #aaa;
    }

    &.react-select__control--is-focused {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    }
  }
`;

const Button = styled.button`
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const Progress = styled.div`
  width: 100%;
  background-color: #e9ecef;
  border-radius: 8px;
  margin-top: 10px;
`;

const ProgressBar = styled.div`
  height: 10px;
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
  border-radius: 8px;
  width: ${({ value }) => value}%;
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconButton = styled(Button)`
  padding: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroSection = styled.div`
  background: url(${jobPostingImage}) no-repeat center center;
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;

  .hero-text {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
`;

const Notification = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
  color: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  font-size: 1.2em;
`;

const StepOne = ({ jobDetails, updateJobDetails, categoriesOptions, handleSelectChange, companyOptions }) => (
  <Container>
    <FormField>
      <Label htmlFor="title"><IconWrapper><FaBriefcase /></IconWrapper>Job Title <span style={{ color: 'red' }}>*</span></Label>
      <Input id="title" name="title" value={jobDetails.title} onChange={updateJobDetails} placeholder="Enter job title" required />
    </FormField>
    <FormField>
      <Label htmlFor="description"><IconWrapper><FaFileAlt /></IconWrapper>Description <span style={{ color: 'red' }}>*</span></Label>
      <TextArea id="description" name="description" value={jobDetails.description} onChange={updateJobDetails} rows="3" placeholder="Provide a detailed description of the job role" required />
    </FormField>
    <FormField>
      <Label htmlFor="type"><IconWrapper><FaClipboardList /></IconWrapper>Job Type <span style={{ color: 'red' }}>*</span></Label>
      <select className="form-control" id="type" name="type" value={jobDetails.type} onChange={updateJobDetails} required>
        <option value="">Select job type</option>
        <option value="Full-Time">Full-Time</option>
        <option value="Part-Time">Part-Time</option>
        <option value="Contract">Contract</option>
        <option value="Internship">Internship</option>
      </select>
    </FormField>
    <FormField>
      <Label><IconWrapper><FaClipboardList /></IconWrapper>Company <span style={{ color: 'red' }}>*</span></Label>
      <SelectStyled
        options={companyOptions}
        onChange={option => handleSelectChange('company', option)}
        required
        formatOptionLabel={option => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={option.logo} alt="company logo" style={{ width: '20px', height: '20px', marginRight: '10px', borderRadius: '50%' }} />
            {option.label}
          </div>
        )}
      />
    </FormField>
    <FormField>
      <Label><IconWrapper><FaClipboardList /></IconWrapper>Category <span style={{ color: 'red' }}>*</span></Label>
      <SelectStyled
        options={categoriesOptions}
        onChange={option => handleSelectChange('category', option)}
        required
      />
    </FormField>
    <FormField>
      <Label htmlFor="skills"><IconWrapper><FaClipboardList /></IconWrapper>Skills Required <span style={{ color: 'red' }}>*</span></Label>
      <SelectStyled
        isMulti
        options={(jobDetails.category ? skillsByCategory[jobDetails.category.value] : []).map(skill => ({ value: skill, label: skill }))}
        value={jobDetails.skills}
        onChange={options => updateJobDetails({ target: { name: 'skills', value: options } })}
        placeholder="Select skills"
        required
      />
    </FormField>
  </Container>
);

const StepTwo = ({ jobDetails, updateJobDetails }) => (
  <Container>
    <FormField>
      <Label htmlFor="salaryType"><IconWrapper><FaDollarSign /></IconWrapper>Salary Type <span style={{ color: 'red' }}>*</span></Label>
      <select className="form-control" id="salaryType" name="salaryType" value={jobDetails.salaryType} onChange={updateJobDetails} required>
        <option value="">Select salary type</option>
        <option value="Annual">Annual</option>
        <option value="Hourly">Hourly</option>
        <option value="Commission">Commission</option>
        <option value="Project-based">Project-based</option>
      </select>
    </FormField>
    <FormField>
      <Label htmlFor="minSalary"><IconWrapper><FaDollarSign /></IconWrapper>Minimum Salary <span style={{ color: 'red' }}>*</span></Label>
      <Input id="minSalary" type="number" name="minSalary" value={jobDetails.minSalary} onChange={updateJobDetails} placeholder="Minimum salary" required />
    </FormField>
    <FormField>
      <Label htmlFor="maxSalary"><IconWrapper><FaDollarSign /></IconWrapper>Maximum Salary <span style={{ color: 'red' }}>*</span></Label>
      <Input id="maxSalary" type="number" name="maxSalary" value={jobDetails.maxSalary} onChange={updateJobDetails} placeholder="Maximum salary" required />
    </FormField>
  </Container>
);

const StepThree = ({ jobDetails, updateJobDetails }) => (
  <Container>
    <FormField>
      <Label htmlFor="qualifications"><IconWrapper><FaClipboardList /></IconWrapper>Qualifications <span style={{ color: 'red' }}>*</span></Label>
      <Input id="qualifications" type="text" name="qualifications" value={jobDetails.qualifications} onChange={updateJobDetails} placeholder="Required qualifications for the job" required />
    </FormField>
    <FormField>
      <Label htmlFor="experience"><IconWrapper><FaClipboardList /></IconWrapper>Experience Required <span style={{ color: 'red' }}>*</span></Label>
      <Input id="experience" type="text" name="experience" value={jobDetails.experience} onChange={updateJobDetails} placeholder="e.g., 3+ years in software development" required />
    </FormField>
  </Container>
);

const StepFour = ({ jobDetails, updateJobDetails }) => {
  return (
    <Container>
      <FormField>
        <Label htmlFor="address"><IconWrapper><FaGlobe /></IconWrapper>Address <span style={{ color: 'red' }}>*</span></Label>
        <Input id="address" type="text" name="address" value={jobDetails.address} onChange={updateJobDetails} placeholder="Full address of the job location" required />
      </FormField>
      <FormField>
        <Label htmlFor="country"><IconWrapper><FaGlobe /></IconWrapper>Country <span style={{ color: 'red' }}>*</span></Label>
        <select className="form-control" id="country" name="country" value={jobDetails.country} onChange={updateJobDetails} required>
          <option value="">Select country</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>{country}</option>
          ))}
        </select>
      </FormField>
      <FormField>
        <Label htmlFor="state"><IconWrapper><FaGlobe /></IconWrapper>State/Region <span style={{ color: 'red' }}>*</span></Label>
        <select className="form-control" id="state" name="state" value={jobDetails.state} onChange={updateJobDetails} required>
          <option value="">Select state/region</option>
          {(statesByCountry[jobDetails.country] || []).map((state, index) => (
            <option key={index} value={state}>{state}</option>
          ))}
        </select>
      </FormField>
      <FormField>
        <Label htmlFor="expiryDate"><IconWrapper><FaCalendar /></IconWrapper>Job Expiry Date <span style={{ color: 'red' }}>*</span></Label>
        <Input id="expiryDate" type="date" name="expiryDate" value={jobDetails.expiryDate} onChange={updateJobDetails} required />
      </FormField>
    </Container>
  );
};

const StepFive = ({ jobDetails, setJobDetails }) => {
  const addQuestion = () => {
    if (jobDetails.questions.length < 10) {
      setJobDetails(prevDetails => ({
        ...prevDetails,
        questions: [...prevDetails.questions, '']
      }));
    }
  };

  const removeQuestion = index => {
    if (jobDetails.questions.length > 1) {
      setJobDetails(prevDetails => ({
        ...prevDetails,
        questions: prevDetails.questions.filter((_, idx) => idx !== index)
      }));
    }
  };

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...jobDetails.questions];
    newQuestions[index] = event.target.value;
    setJobDetails(prevDetails => ({
      ...prevDetails,
      questions: newQuestions
    }));
  };

  return (
    <Container>
      <p>Submit your questions for the expert to answer in a video response. (Max 10 Questions)</p>
      {jobDetails.questions.map((question, index) => (
        <QuestionContainer key={index}>
          <FormField style={{ flex: 1 }}>
            <Label htmlFor={`question-${index}`}><IconWrapper><FaClipboardList /></IconWrapper>Question {index + 1} <span style={{ color: 'red' }}>*</span></Label>
            <Input
              id={`question-${index}`}
              type="text"
              name={`question-${index}`}
              value={question}
              onChange={e => handleQuestionChange(index, e)}
              placeholder={`Question ${index + 1}`}
              required
            />
          </FormField>
          {index === jobDetails.questions.length - 1 && (
            <IconButton onClick={addQuestion} disabled={jobDetails.questions.length === 10}>
              <FaPlus />
            </IconButton>
          )}
          {jobDetails.questions.length > 1 && (
            <IconButton onClick={() => removeQuestion(index)}>
              <FaMinus />
            </IconButton>
          )}
        </QuestionContainer>
      ))}
    </Container>
  );
};

const StepSix = ({ jobDetails, updateJobDetails, videoURL, setVideoURL }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const videoRef = useRef(null);
  const recordedVideoRef = useRef(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    const chunks = [];
    recorder.ondataavailable = (event) => chunks.push(event.data);
    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'video/webm' });
      setVideoBlob(blob);
      setVideoURL(URL.createObjectURL(blob));
      if (recordedVideoRef.current) {
        recordedVideoRef.current.srcObject = null;
        recordedVideoRef.current.src = URL.createObjectURL(blob);
      }
    };

    recorder.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach(track => track.stop());
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setIsRecording(false);
    }
  };

  const handleVideoUpload = async () => {
    if (videoBlob) {
      const fileRef = ref(storage, `videos/${Date.now()}.webm`);
      const uploadTask = uploadBytesResumable(fileRef, videoBlob);

      uploadTask.on('state_changed',
        snapshot => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        error => {
          console.error('Upload failed:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          updateJobDetails({ target: { name: 'videoURL', value: downloadURL } });
        }
      );
    }
  };

  return (
    <Container>
      <FormField>
        <Label htmlFor="video"><IconWrapper><FaClipboardList /></IconWrapper>Record a Short Video About Your Company (Max 5 minutes)</Label>
        <div>
          {isRecording ? (
            <Button type="button" onClick={stopRecording}>Stop Recording</Button>
          ) : (
            <Button type="button" onClick={startRecording}>Start Recording</Button>
          )}
          <div>
            <video ref={videoRef} autoPlay muted style={{ width: '100%', marginTop: '10px', borderRadius: '8px' }} />
          </div>
          {videoURL && (
            <div>
              <video ref={recordedVideoRef} controls style={{ width: '100%', marginTop: '10px', borderRadius: '8px' }} />
              <Button type="button" onClick={handleVideoUpload}>Upload Video</Button>
            </div>
          )}
        </div>
      </FormField>
    </Container>
  );
};

export default function JobPost() {
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [jobDetails, setJobDetails] = useState({
    title: '',
    description: '',
    type: '',
    category: '',
    salaryType: '',
    minSalary: '',
    maxSalary: '',
    skills: [],
    qualifications: '',
    experience: '',
    address: '',
    country: '',
    state: '',
    logo: '',
    questions: [''],
    expiryDate: '',
    videoURL: ''
  });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [submissionMessage, setSubmissionMessage] = useState('');
  const [companies, setCompanies] = useState([]);
  const [videoURL, setVideoURL] = useState('');

  const steps = [
    'Job Details',
    'Salary & Skills',
    'Qualifications & Experience',
    'Location & Job Expiry',
    'Questions',
    'Video'
  ];

  const categoriesOptions = categories.map(category => ({ value: category, label: category }));

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user);
        fetchCompanies(user.email);
      } else {
        navigate("/clientlogin");
      }
    });
  }, [auth, navigate]);

  const fetchCompanies = async (userEmail) => {
    const q = query(collection(db, 'companies'), where("userEmail", "==", userEmail));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      toast.info('To post a job, you must first create a company.', {
        position: 'top-center',
        autoClose: 5000,
        onClose: () => navigate('/company')
      });
    } else {
      const companyList = querySnapshot.docs.map(doc => ({
        value: doc.id,
        label: doc.data().companyName,
        logo: doc.data().logo
      }));
      setCompanies(companyList);
    }
  };

  const updateJobDetails = (e) => {
    const { name, value } = e.target;
    setJobDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = async (name, value) => {
    if (name === 'company') {
      const companyDoc = await getDocs(query(collection(db, 'companies'), where('userEmail', '==', currentUser.email), where('companyName', '==', value.label)));
      if (!companyDoc.empty) {
        const companyData = companyDoc.docs[0].data();
        setJobDetails(prev => ({
          ...prev,
          company: value,
          logo: companyData.logo,
          address: companyData.address,
          country: companyData.country,
          state: companyData.state
        }));
      }
    } else {
      setJobDetails(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        await addDoc(collection(db, 'jobs'), { ...jobDetails, userEmail: currentUser.email });
        setSubmissionMessage('Job posted successfully.');
        toast.success('Job posted successfully.', {
          position: 'top-center',
          autoClose: 5000
        });
        navigate("/clientdashboard");
      } catch (error) {
        console.error("Error posting job:", error);
        setSubmissionMessage('An error occurred during submission. Please try again.');
        toast.error('An error occurred during submission. Please try again.', {
          position: 'top-center',
          autoClose: 5000
        });
      }
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="job-post-container">
      <GlobalStyle />
      <CustomNavbar />
      {/* <HeroSection>
        <div className="hero-text">
          <h1>Create Your Job with Experts Too</h1>
          <p>Find the best experts to join your team</p>
        </div>
      </HeroSection> */}
      <div className="container my-5">
        <Button type="button" onClick={() => navigate('/clientdashboard')} style={{ marginBottom: '20px' }}>Back to Client Dashboard</Button>
        <form onSubmit={handleSubmit}>
          <div className="steps-header mb-5">
            <Progress>
              <ProgressBar value={(currentStep / (steps.length - 1)) * 100} />
            </Progress>
            <div className="progress-percentage">
              {Math.round((currentStep / (steps.length - 1)) * 100)}%
            </div>
          </div>

          {currentStep === 0 && <StepOne jobDetails={jobDetails} updateJobDetails={updateJobDetails} categoriesOptions={categoriesOptions} handleSelectChange={handleSelectChange} companyOptions={companies} />}
          {currentStep === 1 && <StepTwo jobDetails={jobDetails} updateJobDetails={updateJobDetails} />}
          {currentStep === 2 && <StepThree jobDetails={jobDetails} updateJobDetails={updateJobDetails} />}
          {currentStep === 3 && <StepFour jobDetails={jobDetails} updateJobDetails={updateJobDetails} />}
          {currentStep === 4 && <StepFive jobDetails={jobDetails} setJobDetails={setJobDetails} />}
          {currentStep === 5 && <StepSix jobDetails={jobDetails} updateJobDetails={updateJobDetails} videoURL={videoURL} setVideoURL={setVideoURL} />}

          {submissionMessage && (
            <Notification>
              {submissionMessage}
            </Notification>
          )}

          <div className="form-navigation d-flex justify-content-between mt-4">
            <Button type="button" onClick={handleBack} disabled={currentStep === 0}><FaArrowLeft /> Back</Button>
            <Button type="button" onClick={handleNext} disabled={currentStep === steps.length - 1}><FaArrowRight /> Next</Button>
            {currentStep === steps.length - 1 && (
              <Button type="submit"><FaCheck /> Submit</Button>
            )}
          </div>
        </form>
      </div>
      <Footer />
      <ScrollTop />
      <ToastContainer />
    </div>
  );
}