import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faCommentDots, faUserCircle, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../firebase'; // Make sure this path is correct
import logoDark from "../assets/images/logo-dark.png";
import logoWhite from "../assets/images/logo-white.png";
import logoLight from "../assets/images/logo-light.png";
import Companies from "../components/companies";
import Blog from "../components/blog";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { Link } from "react-router-dom";
import { jobData } from "../data/data";

import { FiClock, FiMapPin, FiBookmark } from "../assets/icons/vander"
import ExpertsSlider from "./expertsslider"

function NavDash() {
  const [user, setUser] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const firstName = firebaseUser.displayName ? firebaseUser.displayName.split(' ')[0] : 'User';
        setUser({
          displayName: firstName, // Grabbing only the first name
          photoURL: firebaseUser.photoURL,
          email: firebaseUser.email,
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      navigate('/login'); // Redirect to login page after sign out
    } catch (error) {
      console.error('Sign Out Error', error);
    }
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const navbarStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#0a58ca', // Orange background
    color: 'white',
    padding: '0.5rem 2rem',
  };

  const profileImageStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    cursor: 'pointer',
    objectFit: 'cover',
    marginLeft: '10px',
  };

  const dropdownStyle = {
    display: dropdownVisible ? 'block' : 'none',
    position: 'absolute',
    right: '2rem',
    top: '3rem',
    backgroundColor: 'white',
    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
    zIndex: 100,
    borderRadius: '4px',
  };

  const dropdownItemStyle = {
    padding: '12px 16px',
    borderBottom: '1px solid #f1f1f1',
    color: 'black',
  };

  return (
    <>
    <nav style={navbarStyle}>
      <div> 
         <div className="logo" to="/">
                 
                        <img src={ logoDark } className="" alt="" style={{borderRadius: '50%',
        height: '50px',}} />
                       
                </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Other navbar items */}
        <input
          type="text"
          placeholder="Search"
          style={{
            padding: '5px 10px',
            margin: '0 10px',
            borderRadius: '20px',
            border: '1px solid #ddd',
            width: '200px',
          }}
        />
        <FontAwesomeIcon icon={faSearch} style={{ color: 'white', margin: '0 10px' }} />
        <FontAwesomeIcon icon={faBell} style={{ color: 'white', margin: '0 10px' }} />
        <FontAwesomeIcon icon={faCommentDots} style={{ color: 'white', margin: '0 10px' }} />
        {user ? (
          <>
            <span style={{ color: 'white', marginRight: '10px' }}>Hello, {user.displayName}</span>
            <img
              src={user.photoURL || '/path-to-default-profile-image.png'}
              alt="Profile"
              style={profileImageStyle}
              onClick={toggleDropdown}
            />
            <div style={dropdownStyle}>
              <div style={dropdownItemStyle} onClick={() => navigate('/edit-profile')}>Edit Profile</div>
              <div style={dropdownItemStyle} onClick={() => navigate('/account-settings')}>Account Settings</div>
              <div style={dropdownItemStyle} onClick={signOut}>Sign Out</div>
            </div>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faUserCircle} style={{ color: 'white', margin: '0 10px' }} onClick={() => navigate('/login')} />
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/login')}>Login</span>
          </>
        )}
            
      </div>
    </nav>
    
    </>
  );
}

export default NavDash;

