import React, { useEffect, useState, useRef } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import ExpertNavbar from './expertnavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faBriefcase,
  faClock,
  faInfoCircle,
  faDownload,
  faCheck,
  faTimes,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import heroImage from '../assets/images/company/1.jpg';
import styled from 'styled-components';

const ExpertContract = () => {
  const [contracts, setContracts] = useState([]);
  const [stages, setStages] = useState([]);
  const auth = getAuth();
  const [userEmail, setUserEmail] = useState('');
  const [activeTab, setActiveTab] = useState('shortlisted');
  const [signature, setSignature] = useState('');
  const [signingContractId, setSigningContractId] = useState(null);
  const sigCanvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        await fetchContracts(user.email);
        await fetchStages(user.email);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  const fetchContracts = async (email) => {
    const contractsQuery = query(collection(db, 'contracts'), where('expertEmail', '==', email));
    const contractsSnapshot = await getDocs(contractsQuery);
    const contractsData = contractsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setContracts(contractsData);
  };

  const fetchStages = async (email) => {
    const stagesQuery = query(collection(db, 'jobApplications'), where('email', '==', email));
    const stagesSnapshot = await getDocs(stagesQuery);
    const stagesData = stagesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setStages(stagesData);
  };

  const handleAcceptContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    await updateDoc(contractRef, { status: 'accepted' });
    setContracts(
      contracts.map((contract) =>
        contract.id === contractId ? { ...contract, status: 'accepted' } : contract
      )
    );
  };

  const handleRejectContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    await updateDoc(contractRef, { status: 'rejected' });
    setContracts(
      contracts.map((contract) =>
        contract.id === contractId ? { ...contract, status: 'rejected' } : contract
      )
    );
  };

  const handleSignContract = async (contractId) => {
    const contractRef = doc(db, 'contracts', contractId);
    const signatureUrl = sigCanvasRef.current.toDataURL();
    await updateDoc(contractRef, {
      expertSignature: signatureUrl,
      signingDate: new Date(),
    });
    setContracts(
      contracts.map((contract) =>
        contract.id === contractId
          ? { ...contract, expertSignature: signatureUrl, signingDate: new Date() }
          : contract
      )
    );
    setSigningContractId(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleBackToDashboard = () => {
    navigate('/expertdashboard');
  };

  return (
    <Container>
      <ExpertNavbar />
      <HeroSection>
        <HeroTitle>Welcome to Your Contracts Dashboard</HeroTitle>
        <HeroSubtitle>Manage and Sign Contracts with Ease</HeroSubtitle>
      </HeroSection>
      <ButtonWrapper>
        <StyledButton onClick={handleBackToDashboard}>
          <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />
          Back To Dashboard
        </StyledButton>
      </ButtonWrapper>
      <Tabs>
        <TabButton
          isActive={activeTab === 'shortlisted'}
          onClick={() => handleTabChange('shortlisted')}
        >
          Shortlisted
        </TabButton>
        <TabButton
          isActive={activeTab === 'interview'}
          onClick={() => handleTabChange('interview')}
        >
          Interview
        </TabButton>
        <TabButton
          isActive={activeTab === 'rejected'}
          onClick={() => handleTabChange('rejected')}
        >
          Rejected
        </TabButton>
        <TabButton
          isActive={activeTab === 'contracts'}
          onClick={() => handleTabChange('contracts')}
        >
          Contracts
        </TabButton>
      </Tabs>
      <ContentWrapper>
        {activeTab === 'shortlisted' && (
          <TabContent>
            <TabTitle>Shortlisted Opportunities</TabTitle>
            {stages.filter((stage) => stage.stage === 'shortlisted').length > 0 ? (
              stages
                .filter((stage) => stage.stage === 'shortlisted')
                .map((stage) => (
                  <Card key={stage.id}>
                    <CardTitle>{stage.jobTitle}</CardTitle>
                    <CardBody>
                      <Info>
                        <FontAwesomeIcon icon={faBriefcase} />
                        <strong>Job Title:</strong> {stage.jobTitle}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <strong>Email:</strong> {stage.email}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faClock} />
                        <strong>Applied Date:</strong>{' '}
                        {stage.createdAt
                          ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString()
                          : 'N/A'}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <strong>Stage:</strong> {stage.stage}
                      </Info>
                    </CardBody>
                    <StageWrapper>
                      <Stage isActive={stage.stage === 'screening'}>Screening</Stage>
                      <Stage isActive={stage.stage === 'interview'}>Interview</Stage>
                      <Stage isActive={stage.stage === 'considered'}>Considered</Stage>
                      <Stage isActive={stage.stage === 'offer'}>Offer</Stage>
                    </StageWrapper>
                  </Card>
                ))
            ) : (
              <NoDataMessage>No shortlisted jobs found.</NoDataMessage>
            )}
          </TabContent>
        )}
        {activeTab === 'interview' && (
          <TabContent>
            <TabTitle>Interview Schedule</TabTitle>
            {stages.filter((stage) => stage.stage === 'interview').length > 0 ? (
              stages
                .filter((stage) => stage.stage === 'interview')
                .map((stage) => (
                  <Card key={stage.id}>
                    <CardTitle>{stage.jobTitle}</CardTitle>
                    <CardBody>
                      <Info>
                        <FontAwesomeIcon icon={faBriefcase} />
                        <strong>Job Title:</strong> {stage.jobTitle}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <strong>Email:</strong> {stage.email}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faClock} />
                        <strong>Applied Date:</strong>{' '}
                        {stage.createdAt
                          ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString()
                          : 'N/A'}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <strong>Stage:</strong> {stage.stage}
                      </Info>
                    </CardBody>
                    <StageWrapper>
                      <Stage isActive={stage.stage === 'screening'}>Screening</Stage>
                      <Stage isActive={stage.stage === 'interview'}>Interview</Stage>
                      <Stage isActive={stage.stage === 'considered'}>Considered</Stage>
                      <Stage isActive={stage.stage === 'offer'}>Offer</Stage>
                    </StageWrapper>
                  </Card>
                ))
            ) : (
              <NoDataMessage>No interviews scheduled.</NoDataMessage>
            )}
          </TabContent>
        )}
        {activeTab === 'rejected' && (
          <TabContent>
            <TabTitle>Rejected Applications</TabTitle>
            {stages.filter((stage) => stage.stage === 'rejected').length > 0 ? (
              stages
                .filter((stage) => stage.stage === 'rejected')
                .map((stage) => (
                  <Card key={stage.id}>
                    <CardTitle>{stage.jobTitle}</CardTitle>
                    <CardBody>
                      <Info>
                        <FontAwesomeIcon icon={faBriefcase} />
                        <strong>Job Title:</strong> {stage.jobTitle}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <strong>Email:</strong> {stage.email}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faClock} />
                        <strong>Applied Date:</strong>{' '}
                        {stage.createdAt
                          ? new Date(stage.createdAt.seconds * 1000).toLocaleDateString()
                          : 'N/A'}
                      </Info>
                      <Info>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <strong>Stage:</strong> {stage.stage}
                      </Info>
                    </CardBody>
                  </Card>
                ))
            ) : (
              <NoDataMessage>No rejected jobs found.</NoDataMessage>
            )}
          </TabContent>
        )}
        {activeTab === 'contracts' && (
          <TabContent>
            <TabTitle>Contracts</TabTitle>
            {contracts.length > 0 ? (
              contracts.map((contract) => (
                <Card key={contract.id}>
                  <CardBody>
                    <Info>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <strong>Client Email:</strong> {contract.clientEmail}
                    </Info>
                    <Info>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <strong>Expert Email:</strong> {contract.expertEmail}
                    </Info>
                    <Info>
                      <FontAwesomeIcon icon={faBriefcase} />
                      <strong>Job Title:</strong> {contract.jobTitle}
                    </Info>
                    <Info>
                      <FontAwesomeIcon icon={faClock} />
                      <strong>Hire Date:</strong>{' '}
                      {contract.createdAt
                        ? new Date(contract.createdAt.seconds * 1000).toLocaleDateString()
                        : 'N/A'}
                    </Info>
                    <TermsWrapper>
                      <FontAwesomeIcon icon={faInfoCircle} />
                      <strong>Terms:</strong>
                      <Terms>{contract.terms}</Terms>
                    </TermsWrapper>
                    {contract.expertSignature && (
                      <SignatureWrapper>
                        <PDFDownloadLink
                          document={<ContractPDF contract={contract} />}
                          fileName={`${contract.clientName}_Signed_Contract.pdf`}
                        >
                          <StyledButton>
                            <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />
                            Download Signed Contract
                          </StyledButton>
                        </PDFDownloadLink>
                      </SignatureWrapper>
                    )}
                    {!contract.expertSignature && (
                      <NoDataMessage style={{ color: 'red' }}>The Expert has not signed yet.</NoDataMessage>
                    )}
                    <ActionWrapper>
                      <StyledButton onClick={() => setSigningContractId(contract.id)}>
                        <FontAwesomeIcon icon={faSignature} style={{ marginRight: '10px' }} />
                        Sign Contract
                      </StyledButton>
                      <AcceptButton onClick={() => handleAcceptContract(contract.id)}>
                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />
                        Accept
                      </AcceptButton>
                      <RejectButton onClick={() => handleRejectContract(contract.id)}>
                        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />
                        Reject
                      </RejectButton>
                      <PDFDownloadLink
                        document={<ContractPDF contract={contract} />}
                        fileName={`${contract.clientName}_Contract.pdf`}
                      >
                        <StyledButton>
                          <FontAwesomeIcon icon={faDownload} style={{ marginRight: '10px' }} />
                          Download
                        </StyledButton>
                      </PDFDownloadLink>
                    </ActionWrapper>
                  </CardBody>
                </Card>
              ))
            ) : (
              <NoDataMessage>No contracts found.</NoDataMessage>
            )}
          </TabContent>
        )}
      </ContentWrapper>
      {signingContractId && (
        <SignatureModal>
          <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            onEnd={() => setSignature(sigCanvasRef.current.toDataURL())}
          />
          <ActionWrapper>
            <StyledButton onClick={() => handleSignContract(signingContractId)}>
              Save Signature
            </StyledButton>
            <RejectButton onClick={() => setSigningContractId(null)}>Cancel</RejectButton>
          </ActionWrapper>
        </SignatureModal>
      )}
    </Container>
  );
};

// Styled components for a more modern and cleaner UI

const Container = styled.div`
  padding: 20px;
  animation: fadeIn 1s ease-in-out;
`;

const HeroSection = styled.div`
  background-image: url(${heroImage});
  background-size: cover;
  background-position: center;
  padding: 50px 20px;
  text-align: center;
  color: white;
  animation: fadeIn 1s ease-in-out;
`;

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 20px;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  padding: 12px 24px;
  margin-top: 40px;
  border: none;
  border-radius: 50px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.3);
  transition: background-color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0px 6px 12px rgba(0, 123, 255, 0.5);
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
  justify-content: center;
`;

const TabButton = styled.button`
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  background-color: ${(props) => (props.isActive ? '#007bff' : '#f8f9fa')};
  color: ${(props) => (props.isActive ? '#fff' : '#333')};
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#0056b3' : '#e0e0e0')};
    box-shadow: ${(props) =>
      props.isActive ? '0px 4px 8px rgba(0, 123, 255, 0.3)' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};
    color: ${(props) => (props.isActive ? '#fff' : '#333')};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TabContent = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const TabTitle = styled.h2`
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const Card = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h4`
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 20px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Info = styled.p`
  font-size: 1.1rem;
  color: #555;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StageWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  justify-content: center;
`;

const Stage = styled.div`
  padding: 10px 20px;
  border-radius: 50px;
  background-color: ${(props) => (props.isActive ? '#007bff' : '#f8f9fa')};
  color: ${(props) => (props.isActive ? '#fff' : '#333')};
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;

  &:hover {
    background-color: ${(props) => (props.isActive ? '#0056b3' : '#e0e0e0')};
    box-shadow: ${(props) =>
      props.isActive ? '0px 4px 8px rgba(0, 123, 255, 0.3)' : '0px 2px 4px rgba(0, 0, 0, 0.1)'};
    color: ${(props) => (props.isActive ? '#fff' : '#333')};
  }
`;

const TermsWrapper = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
  background: #f9f9f9;
`;

const Terms = styled.div`
  white-space: pre-wrap;
  font-size: 1rem;
  color: #555;
`;

const SignatureWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
`;

const AcceptButton = styled(StyledButton)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

const RejectButton = styled(StyledButton)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

const NoDataMessage = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #999;
`;

const SignatureModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 600px;
  width: 100%;
`;

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
  header: {
    textAlign: 'center',
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 10,
  },
  label: {
    fontWeight: 'bold',
  },
  signatureSection: {
    marginTop: 20,
  },
  signatureLabel: {
    fontWeight: 'bold',
  },
  signature: {
    fontStyle: 'italic',
  },
  signatureImage: {
    width: 200,
    height: 100,
  },
});

const ContractPDF = ({ contract }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.title}>Contract Agreement</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Client Email:</Text>
        <Text>{contract.clientEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Expert Email:</Text>
        <Text>{contract.expertEmail}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Job Title:</Text>
        <Text>{contract.jobTitle}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Hire Date:</Text>
        <Text>{contract.createdAt ? new Date(contract.createdAt.seconds * 1000).toLocaleDateString() : 'N/A'}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.label}>Terms:</Text>
        <Text>{contract.terms}</Text>
      </View>
      <View style={styles.signatureSection}>
        <Text style={styles.signatureLabel}>Client Signature:</Text>
        <Text style={styles.signature}>{contract.clientName}</Text>
      </View>
      {contract.expertSignature && (
        <View style={styles.signatureSection}>
          <Text style={styles.signatureLabel}>Expert Signature:</Text>
          <Image style={styles.signatureImage} src={contract.expertSignature} />
        </View>
      )}
      {contract.signingDate && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Date:</Text>
          <Text>{new Date(contract.signingDate).toLocaleDateString()}</Text>
        </View>
      )}
      {contract.signingTime && (
        <View style={styles.section}>
          <Text style={styles.label}>Signing Time:</Text>
          <Text>{new Date(contract.signingTime).toLocaleTimeString()}</Text>
        </View>
      )}
    </Page>
  </Document>
);

export default ExpertContract;
