import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase';
import { signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';
import styled from 'styled-components';

const countriesList = ["United States", "United Kingdom", "Canada", "Australia"];

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUpWithEmail = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUpWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Container>
      <Title>Sign up to find work you love</Title>
      <GoogleButton onClick={handleSignUpWithGoogle}>
        Continue with Google
      </GoogleButton>
      <Divider>or</Divider>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Form onSubmit={handleSignUpWithEmail}>
        <Input
          type="text"
          name="firstName"
          placeholder="First name"
          value={formData.firstName}
          onChange={handleInputChange}
          required
        />
        <Input
          type="text"
          name="lastName"
          placeholder="Last name"
          value={formData.lastName}
          onChange={handleInputChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <Input
          type="password"
          name="password"
          placeholder="Password (8 or more characters)"
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <Select
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a country...</option>
          {countriesList.map(country => (
            <option key={country} value={country}>{country}</option>
          ))}
        </Select>
        <SubmitButton type="submit">Create my account</SubmitButton>
      </Form>
      <Footer>
        Already have an account? <LoginLink onClick={() => navigate('/login')}>Log In</LoginLink>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  max-width: 400px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
`;

const Title = styled.h2`
  color: #333;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
`;

const GoogleButton = styled.button`
  color: white;
  padding: 12px 15px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  background-color: #4285F4;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357ae8;
  }
`;

const Divider = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
  line-height: 0.1em;
  margin: 20px 0;
  position: relative;
  
  &::before {
    content: 'or';
    background: #fff;
    padding: 0 10px;
    font-size: 0.875rem;
    color: #bbb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 0.875rem;
  margin: 10px 0;
`;

const Form = styled.form`
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
`;

const SubmitButton = styled.button`
  color: white;
  padding: 12px 15px;
  margin: 10px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  background-color: rgb(253, 127, 44);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(218, 109, 37);
  }
`;

const Footer = styled.div`
  margin-top: 1rem;
`;

const LoginLink = styled.span`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.875rem;
`;

export default RegistrationPage;
