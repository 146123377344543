import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { FiArrowRightCircle, FiCreditCard, FiXCircle, FiCheckCircle, FiDollarSign, FiUsers, FiUserPlus, FiToggleLeft, FiToggleRight } from "react-icons/fi";
import { auth } from '../firebase';
import bg1 from "../assets/images/hero/bg.jpg";

// Initialize Stripe with your public key
const stripePromise = loadStripe("pk_test_51Pt9fEGhaGQiTjEQMhblOB4dCx7lMx1wIDwmNFeVJUA37fVF93rikuo8lEOzq4ZhK7rdgEr3ybqG7yzW21ooGrrX00vH5L6uoB");

const PaymentForm = ({ packageDetails, onClose, billingCycle }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const [cardDetails, setCardDetails] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleInputFocus = (e) => {
        setCardDetails(prev => ({ ...prev, focused: e.target.name }));
    };

    const createPaymentIntent = async () => {
        const amount = packageDetails[billingCycle === 'monthly' ? 'monthlyPrice' : 'annualPrice'] * 100; // Convert price to cents

        try {
            const response = await fetch('https://api.stripe.com/v1/payment_intents', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer sk_test_51Pt9fEGhaGQiTjEQFaKNiahB6fkb0smPYvIkG0983nSP8hVCtoncuuaiL8mKazWkRNqQmVTpR35NfvsJxVwHiGdE003lBamUeq`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    amount: amount.toString(),
                    currency: 'usd',
                    'payment_method_types[]': 'card'
                })
            });

            const data = await response.json();
            if (response.ok) {
                return data.client_secret;
            } else {
                throw new Error(data.error.message);
            }
        } catch (error) {
            console.error("Error creating payment intent:", error.message);
            throw error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setLoading(true);
        setMessage('');
        const cardElement = elements.getElement(CardElement);

        try {
            const clientSecret = await createPaymentIntent();

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: { card: cardElement },
            });

            if (error) {
                console.error(error);
                setMessage(error.message);
                setLoading(false);
            } else {
                console.log("Payment successful:", paymentIntent);
                setMessage("Payment successful!");

                const user = auth.currentUser;

                const userSubscriptionDetails = {
                    fullName: user.displayName || 'Unknown User',
                    email: user.email,
                    subscribed: true,
                    timestamp: new Date().toISOString(),
                    packageName: packageDetails.title,
                    billingCycle: billingCycle,
                };

                // Use your own proxy server
                const proxyUrl = "http://localhost:3000/proxy";
                const zapierWebhookURL = "https://hooks.zapier.com/hooks/catch/8546766/2t2lw35/";

                try {
                    const serverResponse = await fetch(proxyUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            url: zapierWebhookURL,
                            data: userSubscriptionDetails
                        }),
                    });

                    if (serverResponse.ok) {
                        console.log("User data sent to Zapier successfully.");
                    } else {
                        console.error("Failed to send data to Zapier:", serverResponse.statusText);
                    }
                } catch (serverError) {
                    console.error("Error sending data to server:", serverError);
                }

                setLoading(false);
                setTimeout(() => {
                    onClose();
                    navigate('/clientdashboard');
                }, 3000);
            }
        } catch (error) {
            console.error("Payment error:", error);
            setMessage("An unexpected error occurred.");
            setLoading(false);
        }
    };

    return (
        <div style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white', padding: '30px', borderRadius: '10px', boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)', maxWidth: '800px', width: '90%', display: 'flex', flexDirection: 'row', textAlign: 'center', position: 'relative'
            }}>
                <FiXCircle onClick={onClose} style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer', color: '#007bff', fontSize: '24px' }} />
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <h2>{packageDetails.title} Plan</h2>
                    <p>{packageDetails.description}</p>
                    <ul style={{ listStyleType: 'none', padding: '0', marginBottom: '20px', textAlign: 'left' }}>
                        {packageDetails.features.map((feature, idx) => (
                            <li key={idx} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', color: '#343a40' }}>
                                <FiArrowRightCircle style={{ marginRight: '10px', color: '#007bff' }} />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '20px' }}>
                        <Cards
                            number={cardDetails.number}
                            name={cardDetails.name}
                            expiry={cardDetails.expiry}
                            cvc={cardDetails.cvc}
                            focused={cardDetails.focused}
                        />
                    </div>
                    <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                        <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } }, invalid: { color: '#9e2146' } } }} />
                        </div>
                        {message && (
                            <div style={{
                                marginBottom: '20px',
                                padding: '10px',
                                border: '1px solid',
                                borderColor: message === "Payment successful!" ? '#28a745' : '#dc3545',
                                borderRadius: '5px',
                                backgroundColor: message === "Payment successful!" ? '#d4edda' : '#f8d7da',
                                color: message === "Payment successful!" ? '#155724' : '#721c24',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {message === "Payment successful!" ? <FiCheckCircle style={{ marginRight: '10px' }} /> : <FiXCircle style={{ marginRight: '10px' }} />}
                                {message}
                            </div>
                        )}
                        <button type="submit" disabled={!stripe || loading} style={{
                            backgroundColor: '#007bff', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', width: '100%', transition: 'background-color 0.3s'
                        }}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="spinner" style={{
                                        width: '24px', height: '24px', border: '3px solid rgba(255, 255, 255, 0.3)', borderTop: '3px solid white', borderRadius: '50%', animation: 'spin 1s linear infinite'
                                    }}></div>
                                </div>
                            ) : (
                                `Pay $${billingCycle === 'monthly' ? packageDetails.monthlyPrice : packageDetails.annualPrice}`
                            )}
                        </button>
                    </form>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                        <FiCreditCard style={{ width: '50px', height: '50px', margin: '0 10px', color: '#007bff' }} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function Pricing() {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [billingCycle, setBillingCycle] = useState('monthly');

    const packages = [
        {
            title: "Basic",
            description: "Perfect for startups and small enterprises",
            icon: <FiUsers size={24} />,
            monthlyPrice: 49,
            annualPrice: 470,
            features: [
                "Up to 5 Job Postings",
                "Access to Standard Talent Pool",
                "Basic Messaging Capabilities",
                "Basic Analytics on Job Performance",
                "Email Support",
            ],
        },
        {
            title: "Pro",
            description: "Ideal for mid-sized companies with growing needs",
            icon: <FiUserPlus size={24} />,
            monthlyPrice: 99,
            annualPrice: 950,
            features: [
                "Up to 20 Job Postings",
                "Access to Premium Talent Pool",
                "Advanced Messaging Capabilities",
                "Advanced Analytics & Reporting",
                "Priority Email & Chat Support",
            ],
        },
        {
            title: "Enterprise",
            description: "Tailored for large-scale enterprises",
            icon: <FiDollarSign size={24} />,
            monthlyPrice: 199,
            annualPrice: 1900,
            features: [
                "Unlimited Job Postings",
                "Access to Exclusive Talent Pool",
                "Full Messaging & Collaboration Tools",
                "Custom Analytics & Reporting",
                "Dedicated Account Manager",
                "24/7 Priority Support",
            ],
        },
    ];

    const getBillingPrice = (pkg) => {
        return billingCycle === 'monthly' ? pkg.monthlyPrice : pkg.annualPrice;
    };

    return (
        <>
            <section style={{ 
                backgroundImage: `url(${bg1})`, 
                backgroundPosition: 'center', 
                backgroundSize: 'cover',
                padding: '170px 0', 
                position: 'relative' 
            }}>
                <div style={{ 
                    background: 'rgba(0, 0, 0, 0.6)', 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    right: 0, 
                    bottom: 0 
                }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <h5 style={{ color: 'white', fontWeight: '600', marginBottom: '0' }}>Client Pricing Plans</h5>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <nav aria-label="breadcrumb">
                            <ul style={{ display: 'flex', padding: '0', margin: '0', listStyleType: 'none' }}>
                                <li style={{ marginRight: '5px' }}><Link to="/" style={{ color: '#fff' }}>ExpertsToo</Link></li>
                                <li style={{ color: '#fff' }}> / Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
            <div style={{ position: 'relative', overflow: 'hidden', backgroundColor: 'white' }}>
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ display: 'block', width: '100%' }}>
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>

            <section style={{ padding: '60px 0', backgroundColor: '#f8f9fa' }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
                    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <h2 style={{ fontSize: '32px', color: '#007bff', marginBottom: '20px' }}>Choose Your Plan</h2>
                        <div style={{ 
                            display: 'inline-flex', 
                            alignItems: 'center', 
                            backgroundColor: '#e9ecef', 
                            borderRadius: '30px', 
                            padding: '5px'
                        }}>
                            <span style={{ 
                                padding: '10px 20px', 
                                borderRadius: '25px', 
                                cursor: 'pointer',
                                backgroundColor: billingCycle === 'monthly' ? '#007bff' : 'transparent',
                                color: billingCycle === 'monthly' ? 'white' : '#333',
                                transition: 'all 0.3s ease'
                            }} 
                            onClick={() => setBillingCycle('monthly')}
                        >
                            Monthly
                        </span>
                        <span style={{ 
                            padding: '10px 20px', 
                            borderRadius: '25px', 
                            cursor: 'pointer',
                            backgroundColor: billingCycle === 'annual' ? '#007bff' : 'transparent',
                            color: billingCycle === 'annual' ? 'white' : '#333',
                            transition: 'all 0.3s ease'
                        }} 
                        onClick={() => setBillingCycle('annual')}
                    >
                        Annual
                    </span>
                </div>
                {billingCycle === 'annual' && (
                    <p style={{ marginTop: '10px', color: '#28a745' }}>Save up to 20% with annual billing!</p>
                )}
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                {packages.map((pkg, index) => (
                    <div key={index} style={{ 
                        flex: '1 1 300px', 
                        maxWidth: '350px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', 
                        borderRadius: '10px', 
                        overflow: 'hidden', 
                        backgroundColor: 'white', 
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-10px)'
                        }
                    }}>
                        <div style={{ 
                            backgroundColor: pkg.title === "Enterprise" ? '#007bff' : '#343a40', 
                            padding: '40px 20px', 
                            color: 'white', 
                            textAlign: 'center',
                            transition: 'background-color 0.3s ease-in-out' 
                        }}>
                            {pkg.icon}
                            <h3 style={{ margin: '10px 0' }}>{pkg.title}</h3>
                            <p style={{ margin: '0', fontSize: '14px' }}>{pkg.description}</p>
                        </div>
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            backgroundColor: '#f8f9fa', 
                            padding: '20px', 
                            borderBottom: '1px solid #dee2e6' 
                        }}>
                            <span style={{ fontSize: '16px' }}>$</span>
                            <span style={{ fontSize: '36px', fontWeight: 'bold', marginLeft: '5px' }}>
                                {getBillingPrice(pkg)}
                            </span>
                            <span style={{ fontSize: '16px', alignSelf: 'flex-end', marginLeft: '5px' }}>
                                /{billingCycle === 'monthly' ? 'mo' : 'yr'}
                            </span>
                        </div>
                        <div style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
                            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
                                {pkg.features.map((feature, idx) => (
                                    <li key={idx} style={{ 
                                        marginBottom: '10px', 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        color: '#343a40' 
                                    }}>
                                        <FiCheckCircle style={{ marginRight: '10px', color: '#28a745' }} />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <button 
                                    onClick={() => setSelectedPackage(pkg)} 
                                    style={{ 
                                        backgroundColor: '#007bff', 
                                        color: 'white', 
                                        border: 'none', 
                                        padding: '10px 20px', 
                                        borderRadius: '5px', 
                                        cursor: 'pointer', 
                                        transition: 'background-color 0.3s ease-in-out',
                                        '&:hover': {
                                            backgroundColor: '#0056b3'
                                        }
                                    }}
                                >
                                    Get Started
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>

    {selectedPackage && (
        <Elements stripe={stripePromise}>
            <PaymentForm 
                packageDetails={selectedPackage} 
                onClose={() => setSelectedPackage(null)} 
                billingCycle={billingCycle}
            />
        </Elements>
    )}

    <section style={{ padding: '60px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 style={{ fontSize: '32px', color: '#007bff', marginBottom: '20px' }}>Frequently Asked Questions</h2>
            <div style={{ textAlign: 'left' }}>
                {[
                    { 
                        q: "Can I switch plans later?", 
                        a: "Yes, you can upgrade or downgrade your plan at any time. The changes will be reflected in your next billing cycle." 
                    },
                    { 
                        q: "Is there a free trial available?", 
                        a: "We offer a 14-day free trial for our Basic and Pro plans. No credit card is required to start your trial." 
                    },
                    { 
                        q: "What payment methods do you accept?", 
                        a: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover." 
                    },
                    { 
                        q: "Is my data secure?", 
                        a: "Yes, we use industry-standard encryption and security measures to protect your data and transactions." 
                    }
                ].map((faq, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <h3 style={{ color: '#343a40', marginBottom: '10px' }}>{faq.q}</h3>
                        <p style={{ color: '#6c757d' }}>{faq.a}</p>
                    </div>
                ))}
            </div>
        </div>
    </section>

    <Footer />
    <ScrollTop />
</>
);
}