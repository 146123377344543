import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Import Firestore operations
import logo from '../assets/images/logo-dark.png'; // Your logo image

export default function ExpertSignup() {
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;

      // Reference to the user's document in Firestore
      const userRef = doc(db, 'users', user.uid);

      // Check if the user already exists with a different role
      const docSnap = await getDoc(userRef);
      if (docSnap.exists() && docSnap.data().role !== 'expert') {
        alert('You are already registered with a different role. Please log in using your existing role.');
        return;
      }

      // Set or update the user's role to 'expert'
      await setDoc(userRef, {
        email: user.email,
        role: 'expert',
      }, { merge: true });

      navigate('/expertdashboard');
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };



  // Styles for the component
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f4f4f4',
    },
    box: {
      padding: '40px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    logo: {
      marginBottom: '20px',
      height: '70px',
    },
    header: {
      marginBottom: '30px',
      fontSize: '18px',
      fontWeight: '500',
      color: '#333',
    },
    button: {
      width: '100%',
      padding: '12px',
      margin: '10px 0',
      border: 'none',
      borderRadius: '4px',
      fontSize: '16px',
      cursor: 'pointer',
      transition: '0.3s ease background-color',
    },
    googleButton: {
      backgroundColor: '#db4437',
      color: 'white',
      '&:hover': {
        backgroundColor: '#c1351d',
      },
    },
    emailButton: {
      backgroundColor: '#0a58ca',
      color: 'white',
      '&:hover': {
        backgroundColor: '#084298',
      },
    },
    link: {
      marginTop: '20px',
      color: '#0a58ca',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <img src={logo} alt="Experts Blooms Logo" style={styles.logo} />
        <h4 style={styles.header}>Sign up to Join the World's Top Experts Network</h4>
        <button style={{ ...styles.button, ...styles.googleButton }} onClick={handleGoogleSignIn}>
          Continue with Google
        </button>
        <button style={{ ...styles.button, ...styles.emailButton }} onClick={() => navigate('/login')}>
          Continue with Email
        </button>
        <Link to="/login" style={styles.link}>Already have an Experts Blooms account? Log In</Link>
        <p style={{ color: 'darkgray', marginTop: '20px' }}>Don't have an Experts Blooms Account?</p>
        <button style={{ ...styles.button, ...styles.emailButton }} onClick={() => navigate('/register')}>
          Sign Up
        </button>
      </div>
    </div>
  );
}
