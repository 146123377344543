import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, collection, getDocs, arrayUnion } from 'firebase/firestore';
import { db, auth } from "../firebase";
import { Modal, Button, Form, Tooltip, OverlayTrigger, InputGroup, FormControl } from 'react-bootstrap';
import styled, { keyframes } from "styled-components";
import Footer from "../components/footer";
import ScrollTop from '../components/scrollTop';
import CustomNavbar from "./customnavbar";
import { Icon } from '@iconify/react';
import userIcon from '@iconify-icons/mdi/account-circle';
import briefcaseIcon from '@iconify-icons/mdi/briefcase';
import certificateIcon from '@iconify-icons/mdi/certificate';
import resumeIcon from '@iconify-icons/mdi/file-document';
import videoIcon from '@iconify-icons/mdi/video';
import linkedinIcon from '@iconify-icons/mdi/linkedin';
import projectIcon from '@iconify-icons/mdi/format-list-bulleted';
import addIcon from '@iconify-icons/mdi/plus-circle';
import pencilIcon from '@iconify-icons/mdi/pencil';
import shareIcon from '@iconify-icons/mdi/share-variant';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f4f7fc;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const LeftColumn = styled.div`
  flex: 1;
  max-width: 300px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const RightColumn = styled.div`
  flex: 2;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const ProfileImage = styled.img`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #333;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 10px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const SkillTag = styled.span`
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  margin: 5px;
  font-size: 14px;
`;

const EditableSection = styled.div`
  position: relative;
  margin-bottom: 30px;
  &:hover .edit-icon {
    display: block;
  }
`;

const EditIcon = styled(Icon)`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #ff6347;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.2);
  }
`;

const ProfileCompletionBar = styled.div`
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  height: 20px;
  width: 100%;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background-color: #28a745;
  width: ${props => props.width || '0%'};
  transition: width 0.3s ease;
`;

const ProjectGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ProjectCard = styled.div`
  width: calc(33.333% - 20px);
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 15px;
`;

const ProjectTitle = styled.h5`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
`;

const ProjectLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const AvailabilityBadge = styled.div`
  padding: 10px;
  color: #fff;
  background-color: ${props => (props.available ? '#28a745' : '#dc3545')};
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
  &:hover {
    background-color: ${props => (props.available ? '#218838' : '#c82333')};
  }
`;

const AddProjectButton = styled(Button)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  &:hover {
    background-color: #0056b3;
  }
`;

const InstructionsText = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
  text-align: center;
`;

const BackButton = styled(Button)`
  margin-top: 20px;
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  &:hover {
    background-color: #0056b3;
  }
`;

const ShareButton = styled(Button)`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #17a2b8;
  border: none;
  border-radius: 20px;
  &:hover {
    background-color: #138496;
  }
`;

const renderFieldContent = (field, content, handleFieldChange) => {
  if (typeof content === 'object' && !Array.isArray(content)) {
    return Object.keys(content).map((key, index) => (
      <Form.Group key={index}>
        <Form.Label>{key.charAt(0).toUpperCase() + key.slice(1)}</Form.Label>
        <Form.Control
          type="text"
          defaultValue={content[key]}
          onChange={(e) => handleFieldChange(field, { ...content, [key]: e.target.value })}
        />
      </Form.Group>
    ));
  }

  if (Array.isArray(content)) {
    return (
      <Form.Control
        as="textarea"
        rows={3}
        defaultValue={content.join(', ')}
        onChange={(e) => handleFieldChange(field, e.target.value.split(',').map(item => item.trim()))}
      />
    );
  }

  return (
    <Form.Control
      type="text"
      defaultValue={content}
      onChange={(e) => handleFieldChange(field, e.target.value)}
    />
  );
};

// Main Component
export default function CandidateProfileEdit() {
  const { email } = useParams();
  const [profileData, setProfileData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [file, setFile] = useState(null);
  const [newProject, setNewProject] = useState({ name: '', link: '', thumbnail: '' });
  const [shareLink, setShareLink] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, firebaseUser => {
      if (!firebaseUser) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchProfileData = async () => {
      const docRef = collection(db, "profiles");
      const snapshot = await getDocs(docRef);
      const data = snapshot.docs.map(doc => doc.data()).find(profile => profile.email === email);
      if (data) {
        setProfileData(data);
        calculateCompletionPercentage(data);
      }
    };
    fetchProfileData();
  }, [email]);

  const calculateCompletionPercentage = (data) => {
    const totalFields = 10;
    let completedFields = 0;

    if (data.profileImage) completedFields++;
    if (data.gender) completedFields++;
    if (data.country) completedFields++;
    if (data.timezone) completedFields++;
    if (data.currentJobTitle) completedFields++;
    if (Array.isArray(data.primarySkills) && data.primarySkills.length > 0) completedFields++;
    if (Array.isArray(data.certifications) && data.certifications.length > 0) completedFields++;
    if (data.cvUrl) completedFields++;
    if (data.videoIntroUrl) completedFields++;
    if (data.linkedin) completedFields++;

    const percentage = Math.floor((completedFields / totalFields) * 100);
    setCompletionPercentage(percentage);
  };

  const handleEdit = (field, content) => {
    setModalContent({ field, content });
    setShowModal(true);
  };

  const handleFieldChange = (field, newValue) => {
    setModalContent({ ...modalContent, content: newValue });
  };

  const handleSave = async () => {
    const { field, content } = modalContent;
    const profileRef = doc(db, "profiles", profileData.id);
    const updatedData = { ...profileData, [field]: content };
    await updateDoc(profileRef, updatedData);
    setProfileData(updatedData);
    calculateCompletionPercentage(updatedData);
    setShowModal(false);
  };

  const handleProjectAdd = async () => {
    if (!newProject.name || !newProject.link || !newProject.thumbnail) {
      alert('Please fill out all fields.');
      return;
    }
    const profileRef = doc(db, "profiles", profileData.id);
    const updatedData = {
      ...profileData,
      projects: arrayUnion(newProject),
    };
    await updateDoc(profileRef, updatedData);
    setProfileData(updatedData);
    calculateCompletionPercentage(updatedData);
    setShowModal(false);
    setNewProject({ name: '', link: '', thumbnail: '' });
  };

  const handleUploadChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    // Upload logic here (using Firebase storage)
    // Once uploaded, update the `profileData` and save it in Firestore
  };

  const toggleAvailability = async () => {
    const updatedAvailability = !profileData.available;
    const profileRef = doc(db, "profiles", profileData.id);
    const updatedData = { ...profileData, available: updatedAvailability };
    await updateDoc(profileRef, updatedData);
    setProfileData(updatedData);
  };

  const generateShareLink = () => {
    const link = `${window.location.origin}/view-profile/${profileData.id}`;
    setShareLink(link);
  };

  const renderModal = () => {
    if (!modalContent) return null;

    const { field, content } = modalContent;

    return (
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit {field.charAt(0).toUpperCase() + field.slice(1)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {field === 'profileImage' || field === 'cvUrl' || field === 'videoIntroUrl' ? (
            <Form.Group>
              <Form.File label="Upload File" onChange={handleUploadChange} />
              <Button onClick={handleFileUpload}>Upload</Button>
            </Form.Group>
          ) : field === 'projects' ? (
            <>
              <Form.Group>
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.name}
                  onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Project Link</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.link}
                  onChange={(e) => setNewProject({ ...newProject, link: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Project Thumbnail URL</Form.Label>
                <Form.Control
                  type="text"
                  value={newProject.thumbnail}
                  onChange={(e) => setNewProject({ ...newProject, thumbnail: e.target.value })}
                />
              </Form.Group>
              <Button onClick={handleProjectAdd}>Add Project</Button>
            </>
          ) : (
            renderFieldContent(field, content, handleFieldChange)
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <CustomNavbar />
      <ProfileContainer>
        <LeftColumn>
          <EditableSection>
            <ProfileImage
              src={profileData.profileImage || 'https://via.placeholder.com/150'}
              alt="Profile"
            />
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('profileImage', profileData.profileImage)}
              />
            </OverlayTrigger>
          </EditableSection>
          <h3 style={{ marginTop: '20px', textAlign: 'center' }}>{profileData.name}</h3>
          <p style={{ color: '#666', textAlign: 'center', marginBottom: '20px' }}>
            {profileData.currentJobTitle || 'No Job Title'}
          </p>
          <EditableSection>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('currentJobTitle', profileData.currentJobTitle)}
              />
            </OverlayTrigger>
          </EditableSection>
          <ProfileCompletionBar>
            <span>Profile Completion: {completionPercentage}%</span>
            <ProgressBar>
              <ProgressFill width={`${completionPercentage}%`} />
            </ProgressBar>
          </ProfileCompletionBar>
          {profileData.primarySkills && (
            <div style={{ marginBottom: '20px' }}>
              <h5>Skills</h5>
              <div>
                {profileData.primarySkills.map((skill, index) => (
                  <SkillTag key={index}>{skill}</SkillTag>
                ))}
              </div>
              <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
                <EditIcon
                  className="edit-icon"
                  icon={pencilIcon}
                  onClick={() => handleEdit('primarySkills', profileData.primarySkills)}
                />
              </OverlayTrigger>
            </div>
          )}
          <AvailabilityBadge
            available={profileData.available}
            onClick={toggleAvailability}
          >
            {profileData.available ? 'Available' : 'Not Available'}
          </AvailabilityBadge>
          <InstructionsText>Click to toggle your availability status.</InstructionsText>
          <ShareButton onClick={generateShareLink}>
            <Icon icon={shareIcon} style={{ marginRight: '5px' }} /> Generate Shareable Link
          </ShareButton>
          {shareLink && (
            <InputGroup className="mt-3">
              <FormControl
                value={shareLink}
                readOnly
                onClick={(e) => e.target.select()}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => navigator.clipboard.writeText(shareLink)}>Copy</Button>
              </InputGroup.Append>
            </InputGroup>
          )}
          <BackButton onClick={() => navigate('/expertdashboard')}>Back to Expert Dashboard</BackButton>
        </LeftColumn>

        <RightColumn>
          <EditableSection>
            <SectionTitle>
              Personal Details
              <Icon icon={userIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <p>Email: {profileData.email || 'No Email Provided'}</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('gender', profileData.gender)}
              />
            </OverlayTrigger>
            <p>Gender: {profileData.gender || 'Not Specified'}</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('country', profileData.country)}
              />
            </OverlayTrigger>
            <p>Country: {profileData.country || 'Unknown'}</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('timezone', profileData.timezone)}
              />
            </OverlayTrigger>
            <p>Timezone: {profileData.timezone || 'Not Set'}</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('hourlyRate', profileData.hourlyRate)}
              />
            </OverlayTrigger>
            <p>Hourly Rate: {profileData.hourlyRate || 'Not Provided'}</p>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('industryCategory', profileData.industryCategory)}
              />
            </OverlayTrigger>
            <p>Industry Category: {profileData.industryCategory || 'Not Provided'}</p>
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Work Experience
              <Icon icon={briefcaseIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('workExperience', profileData.workExperience || '')}
              />
            </OverlayTrigger>
            <p>{profileData.workExperience || 'No work experience listed.'}</p>
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Certifications
              <Icon icon={certificateIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('certifications', profileData.certifications || [])}
              />
            </OverlayTrigger>
            {Array.isArray(profileData.certifications) && profileData.certifications.length > 0 ? (
              profileData.certifications.map((cert, index) => (
                <div key={index} style={{ marginBottom: '10px' }}>
                  <h5>{cert.title} from {cert.issuer}</h5>
                  <p>{cert.country}, {new Date(cert.date.seconds * 1000).toLocaleDateString()}</p>
                </div>
              ))
            ) : (
              <p>No certifications listed.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Resume
              <Icon icon={resumeIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('cvUrl', profileData.cvUrl)}
              />
            </OverlayTrigger>
            {profileData.cvUrl ? (
              <div>
                <iframe
                  src={profileData.cvUrl}
                  style={{ width: '100%', height: '300px' }}
                  title="Resume Preview"
                  frameBorder="0"
                  allowFullScreen
                />
                <a
                  href={profileData.cvUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'none',
                    color: '#0d6efd',
                    marginTop: '10px',
                  }}
                >
                  <span>Download Resume</span>
                </a>
              </div>
            ) : (
              <p>No resume available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Video Introduction
              <Icon icon={videoIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('videoIntroUrl', profileData.videoIntroUrl)}
              />
            </OverlayTrigger>
            {profileData.videoIntroUrl ? (
              <iframe
                src={profileData.videoIntroUrl}
                style={{ width: '100%', height: '300px' }}
                title="Video Introduction"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <p>No video introduction available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              LinkedIn Profile
              <Icon icon={linkedinIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => handleEdit('linkedin', profileData.linkedin)}
              />
            </OverlayTrigger>
            {profileData.linkedin ? (
              <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: '#0d6efd' }}>
                View LinkedIn Profile
              </a>
            ) : (
              <p>No LinkedIn profile available.</p>
            )}
          </EditableSection>

          <EditableSection>
            <SectionTitle>
              Projects
              <Icon icon={projectIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
              <AddProjectButton onClick={() => setModalContent({ field: 'projects', content: null })}>
                <Icon icon={addIcon} style={{ marginRight: '5px' }} /> Add Project
              </AddProjectButton>
            </SectionTitle>
            <OverlayTrigger placement="top" overlay={<Tooltip>Click to edit</Tooltip>}>
              <EditIcon
                className="edit-icon"
                icon={pencilIcon}
                onClick={() => setModalContent({ field: 'projects', content: null })}
              />
            </OverlayTrigger>
            <ProjectGallery>
              {Array.isArray(profileData.projects) && profileData.projects.length > 0 ? (
                profileData.projects.map((project, index) => (
                  <ProjectCard key={index}>
                    <ProjectImage src={project.thumbnail || 'https://via.placeholder.com/150'} alt={project.name} />
                    <ProjectInfo>
                      <ProjectTitle>{project.name}</ProjectTitle>
                      <ProjectLink href={project.link} target="_blank">
                        View Project
                      </ProjectLink>
                    </ProjectInfo>
                  </ProjectCard>
                ))
              ) : (
                <p>No projects listed.</p>
              )}
            </ProjectGallery>
          </EditableSection>
        </RightColumn>
      </ProfileContainer>
      {renderModal()}
      <Footer />
      <ScrollTop />
    </>
  );
}
