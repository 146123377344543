import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider, db } from '../firebase';
import Modal from 'react-modal';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaGoogle, FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

Modal.setAppElement('#root');

const extractUserName = (firebaseUser) => {
    return firebaseUser.displayName || 'User';
};

const ClientLoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPolicies, setShowPolicies] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [policiesAccepted, setPoliciesAccepted] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                const userData = {
                    uid: firebaseUser.uid,
                    email: firebaseUser.email,
                    displayName: extractUserName(firebaseUser),
                    photoURL: firebaseUser.photoURL,
                };
                setUser(userData);
                await checkUserAcceptanceAndSubscription(userData);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const checkUserAcceptanceAndSubscription = async (userData) => {
        const userRef = doc(db, 'users', userData.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.policiesAccepted && userData.termsAccepted) {
                await checkUserSubscription(userData);
            } else {
                setShowPolicies(true);
            }
        } else {
            setShowPolicies(true);
        }
    };

    const checkUserSubscription = async (userData) => {
        const subscriptionRef = doc(db, 'clientpricing', userData.uid);
        const subscriptionSnap = await getDoc(subscriptionRef);

        if (subscriptionSnap.exists() && subscriptionSnap.data().subscribed) {
            navigate('/clientdashboard', { state: { user: { ...userData, subscribed: true } } });
        } else {
            navigate('/pricing', { state: { user: userData } });
        }

        sendUserToZapier(userData);
    };

    const sendUserToZapier = async (userData) => {
        const userPayload = {
            fullName: userData.displayName || 'Unknown User',
            email: userData.email,
            subscribed: true,
            timestamp: new Date().toISOString(),
        };

        try {
            const response = await fetch("https://expertstoo.com/send-to-zapier", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(userPayload),
            });

            if (!response.ok) {
                console.error("Failed to send data to server:", response.statusText);
                return;
            }

            const responseData = await response.json();
            console.log("Response from server:", responseData);
        } catch (error) {
            console.error("Error sending data to server:", error);
        }
    };

    const handleLogin = async (loginMethod) => {
        if (!policiesAccepted || !termsAccepted) {
            setError('Please accept both the Privacy Policy and Terms & Conditions to continue.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            let userCredential;
            if (loginMethod === 'email') {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            } else if (loginMethod === 'google') {
                userCredential = await signInWithPopup(auth, googleAuthProvider);
            }

            const { user } = userCredential;
            const userData = {
                uid: user.uid,
                email: user.email,
                displayName: extractUserName(user),
                photoURL: user.photoURL,
            };

            await setDoc(doc(db, 'users', user.uid), {
                policiesAccepted: true,
                termsAccepted: true,
            }, { merge: true });

            await checkUserSubscription(userData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const togglePolicies = () => setShowPolicies(!showPolicies);
    const toggleTerms = () => setShowTerms(!showTerms);

    const handleAcceptPolicies = () => {
        setPoliciesAccepted(true);
        togglePolicies();
        if (termsAccepted) return;
        setShowTerms(true);
    };

    const handleAcceptTerms = () => {
        setTermsAccepted(true);
        toggleTerms();
    };

    return (
        <Container>
            <FormContainer>
               
                <Title>Welcome Back</Title>
                <Description>Log in to ExpertsToo and connect with top employers</Description>
                <InputGroup>
                    <InputIcon>
                        <FaEnvelope />
                    </InputIcon>
                    <StyledInput 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required
                    />
                </InputGroup>
                <InputGroup>
                    <InputIcon>
                        <FaLock />
                    </InputIcon>
                    <StyledInput 
                        type={showPassword ? "text" : "password"}
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required
                    />
                    <PasswordToggle onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </PasswordToggle>
                </InputGroup>
                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={policiesAccepted} 
                        onChange={() => setPoliciesAccepted(!policiesAccepted)} 
                        id="policies"
                    />
                    <label htmlFor="policies">I accept the <TermsLink onClick={togglePolicies}>Privacy Policy</TermsLink></label>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={termsAccepted} 
                        onChange={() => setTermsAccepted(!termsAccepted)} 
                        id="terms"
                    />
                    <label htmlFor="terms">I accept the <TermsLink onClick={toggleTerms}>Terms & Conditions</TermsLink></label>
                </CheckboxContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <StyledButton onClick={() => handleLogin('email')} disabled={loading}>
                    {loading ? 'Logging in...' : 'Log In'}
                </StyledButton>
                <OrDivider>
                    <span>OR</span>
                </OrDivider>
                <StyledButton onClick={() => handleLogin('google')} disabled={loading} style={{ backgroundColor: '#db4437' }}>
                    <FaGoogle style={{ marginRight: '10px' }} />
                    Continue with Google
                </StyledButton>
                <LinkText onClick={() => navigate('/reset-password')}>Forgot password?</LinkText>
                <LinkText onClick={() => navigate('/clientregister')}>Don't have an account? Sign up</LinkText>
            </FormContainer>
            
            <Modal
                isOpen={showPolicies}
                onRequestClose={togglePolicies}
                contentLabel="Privacy Policy"
                style={modalStyle}
            >
                <ModalContent>
                    <ModalTitle>Privacy Policy</ModalTitle>
                    <PolicyText dangerouslySetInnerHTML={{ __html: privacyPolicyText }} />
                    <ModalButtonContainer>
                        <StyledButton onClick={handleAcceptPolicies}>Accept</StyledButton>
                        <StyledButton onClick={togglePolicies} style={{ backgroundColor: '#6c757d' }}>Close</StyledButton>
                    </ModalButtonContainer>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={showTerms}
                onRequestClose={toggleTerms}
                contentLabel="Terms and Conditions"
                style={modalStyle}
            >
                <ModalContent>
                    <ModalTitle>Terms and Conditions</ModalTitle>
                    <PolicyText dangerouslySetInnerHTML={{ __html: termsAndConditionsText }} />
                    <ModalButtonContainer>
                        <StyledButton onClick={handleAcceptTerms}>Accept</StyledButton>
                        <StyledButton onClick={toggleTerms} style={{ backgroundColor: '#6c757d' }}>Close</StyledButton>
                    </ModalButtonContainer>
                </ModalContent>
            </Modal>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto 20px;
  max-width: 150px;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-size: 28px;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const InputIcon = styled.span`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 15px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    outline: none;
  }
`;

const PasswordToggle = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TermsLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }

  span {
    padding: 0 10px;
    color: #777;
    font-size: 14px;
  }
`;

const LinkText = styled.span`
  display: block;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const ModalTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const PolicyText = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 15px;

  h2 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h3 {
    color: #444;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  p, ul {
    color: #666;
    margin-bottom: 10px;
    line-height: 1.6;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '80vh',
        padding: '20px',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#f8f9fa',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    }
};

const privacyPolicyText = `
<h2>1. Introduction</h2>
<p>At Experts Too, we are committed to respecting and protecting your privacy rights. This Privacy Policy explains how we collect, use, and share your personal data when you use our platform.</p>

<h2>2. Data Collection</h2>
<p>We collect various types of data, including:</p>
<ul>
    <li>Account Data: Username, email, login credentials</li>
    <li>Personal Identifiers: Name, IP address, geolocation data (zip code, city), employment information</li>
    <li>Activity Data: Site usage data, including clicks, pages visited, and how long users interact with specific features</li>
    <li>Contact Information: Name, phone number, email address</li>
    <li>Payment Information: Payment details for services, including partial card numbers for security</li>
    <li>Employment Information: Resumes, job applications, job history, education data</li>
</ul>

<h2>3. How We Use Your Data</h2>
<p>We use your data for purposes such as:</p>
<ul>
    <li>Improving Services: Enhancing your job search experience by personalizing content and optimizing our services</li>
    <li>Fraud Prevention: Monitoring and investigating any malicious or fraudulent activities</li>
    <li>Communication: Contacting you about your account, job opportunities, or other services</li>
    <li>Advertising and Marketing: Showing personalized ads and job listings relevant to your interests</li>
    <li>Legal Requirements: Complying with applicable legal obligations</li>
</ul>

<h2>4. Data Sharing</h2>
<p>We may share your data with:</p>
<ul>
    <li>Employers: For the purpose of job applications and resume reviews</li>
    <li>Affiliates: To improve services and security for our users</li>
    <li>Third-party Service Providers: For advertising, payment processing, data analysis, and marketing purposes</li>
    <li>Government Authorities: When required by law, such as for legal proceedings or fraud investigations</li>
</ul>

<h2>5. Your Rights</h2>
<p>You have the right to:</p>
<ul>
    <li>Access your personal data</li>
    <li>Correct inaccuracies in your data</li>
    <li>Request deletion of your data</li>
    <li>Restrict processing of your data</li>
    <li>Opt-out of targeted advertising</li>
</ul>

<h2>6. Data Security</h2>
<p>We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

<h2>7. International Data Transfers</h2>
<p>Your information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction.</p>

<h2>8. Changes to This Privacy Policy</h2>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>

<h2>9. Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us:</p>
<p>By email: privacy@expertstoo.com</p>
<p>By visiting this page on our website: www.expertstoo.com/contact</p>
<p>By phone number: +1 (555) 123-4567</p>
<p>By mail: 123 Privacy Street, Data City, 12345, Country</p>
`;

const termsAndConditionsText = `
<h2>1. Acceptance of Terms</h2>
<p>By accessing or using the Experts Too platform ("the Service"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of the terms, then you may not access the Service.</p>

<h2>2. Changes to Terms</h2>
<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will try to provide at least 30 days notice prior to any new terms taking effect.</p>

<h2>3. Access to the Service</h2>
<p>Our service is available only to users who are at least 18 years old. By accessing or using our Service, you warrant and represent that you are at least 18 years of age.</p>

<h2>4. Intellectual Property</h2>
<p>The Service and its original content, features, and functionality are and will remain the exclusive property of Experts Too and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>

<h2>5. User Accounts</h2>
<p>When you create an account with us, you must provide information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>

<h2>6. Prohibited Uses</h2>
<p>You may use our Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:</p>
<ul>
    <li>In any way that violates any applicable national or international law or regulation.</li>
    <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
    <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
</ul>

<h2>7. Termination</h2>
<p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.</p>

<h2>8. Limitation of Liability</h2>
<p>In no event shall Experts Too, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>

<h2>9. Governing Law</h2>
<p>These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its conflict of law provisions.</p>

<h2>10. Changes</h2>
<p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

<h2>11. Contact Us</h2>
<p>If you have any questions about these Terms, please contact us:</p>
<p>By email: terms@expertstoo.com</p>
<p>By visiting this page on our website: www.expertstoo.com/contact</p>
<p>By phone number: +1 (555) 123-4567</p>
<p>By mail: 123 Terms Street, Legal City, 12345, Country</p>
`;

export default ClientLoginScreen;