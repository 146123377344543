import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBriefcase, faPlusCircle, faUsers, faTags, faBuilding, faUserTie, faHeart, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import styled from 'styled-components';

const ClientSidebar = () => {
  const [jobsCount, setJobsCount] = useState(0);
  const [applicantsCount, setApplicantsCount] = useState(0);
  const [expertsCount, setExpertsCount] = useState(0);
  const [favoritesCount, setFavoritesCount] = useState(0);
  const [favoriteExperts, setFavoriteExperts] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFavoritesPopupOpen, setIsFavoritesPopupOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    const fetchCounts = async (user) => {
      if (!user) return;

      // Fetch job counts
      const jobsQuery = query(collection(db, 'jobs'), where('userEmail', '==', user.email));
      const jobsSnapshot = await getDocs(jobsQuery);
      setJobsCount(jobsSnapshot.size);

      // Fetch applicant counts
      const jobIds = jobsSnapshot.docs.map(doc => doc.id);
      if (jobIds.length > 0) {
        const applicationsQuery = query(collection(db, 'jobApplications'), where('jobID', 'in', jobIds));
        const applicationsSnapshot = await getDocs(applicationsQuery);
        setApplicantsCount(applicationsSnapshot.size);
      } else {
        setApplicantsCount(0);
      }

      // Fetch expert profile counts
      const expertsQuery = collection(db, 'profiles');
      const expertsSnapshot = await getDocs(expertsQuery);
      setExpertsCount(expertsSnapshot.size);

      // Fetch favorite profile counts and details
      const favoritesQuery = query(collection(db, 'favorites'), where('userId', '==', user.uid));
      const favoritesSnapshot = await getDocs(favoritesQuery);
      setFavoritesCount(favoritesSnapshot.size);
      const favoriteIds = favoritesSnapshot.docs.map(doc => doc.data().candidateId);
      if (favoriteIds.length > 0) {
        const profilesQuery = query(collection(db, 'profiles'), where('__name__', 'in', favoriteIds));
        const profilesSnapshot = await getDocs(profilesQuery);
        setFavoriteExperts(profilesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, user => {
      if (user) {
        fetchCounts(user);
      }
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      unsubscribeAuth();
    };
  }, []);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const isMobile = windowWidth <= 768;

  const handleOpenFavoritesPopup = () => setIsFavoritesPopupOpen(true);
  const handleCloseFavoritesPopup = () => setIsFavoritesPopupOpen(false);

  const handleClearFavorites = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const favoritesQuery = query(collection(db, 'favorites'), where('userId', '==', user.uid));
    const favoritesSnapshot = await getDocs(favoritesQuery);
    const deletePromises = favoritesSnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    setFavoritesCount(0);
    setFavoriteExperts([]);
    setIsFavoritesPopupOpen(false);
  };

  const sidebarStyle = {
    position: isMobile ? 'fixed' : 'relative',
    top: 0,
    left: isMobile && !isSidebarOpen ? '-260px' : '0',
    width: '250px',
    height: 'auto',
    backgroundColor: '#fff',
    boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    padding: '20px 10px',
    transition: 'left 0.3s ease-in-out',
    zIndex: isMobile ? 1050 : 'auto',
    overflowY: 'auto',
    borderRadius: '20px'
  };

  const hamburgerStyle = {
    fontSize: '30px',
    display: isMobile ? 'block' : 'none',
    position: 'fixed',
    top: '20px',
    left: '20px',
    cursor: 'pointer',
    zIndex: 1051,
    color: '#007bff',
  };

  const linkStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    margin: '5px 0',
    textDecoration: 'none',
    color: 'black',
    borderRadius: '20px',
    transition: 'background-color 0.2s, transform 0.2s',
    backgroundColor: '#f8f9fa',
  };

  const activeLinkStyle = {
    backgroundColor: '#e7f1ff',
    transform: 'translateX(5px)',
  };

  const iconStyle = {
    marginRight: '15px',
    color: '#007bff',
  };

  const Badge = styled.span`
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 12px;
    margin-left: auto;
  `;

  const SectionTitle = styled.h5`
    margin: 20px 0 10px;
    color: #333;
    font-size: 1rem;
    padding-left: 10px;
  `;

  const FavoritesPopup = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    z-index: 1060;
    overflow-y: auto;
    padding: 20px;
  `;

  const CloseButton = styled.button`
    background: none;
    border: none;
    color: #333;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  `;

  const ClearButton = styled.button`
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
  `;

  const FavoriteExpertCard = styled.div`
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  `;

  const FavoriteExpertImage = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
  `;

  const FavoriteExpertInfo = styled.div`
    flex: 1;
  `;

  return (
    <>
      <FontAwesomeIcon icon={faBars} style={hamburgerStyle} onClick={toggleSidebar} />

      <div style={sidebarStyle}>
        <SectionTitle>Jobs</SectionTitle>
        <NavLink to="/alljobs" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faBriefcase} style={iconStyle} /> Manage Jobs
          {jobsCount > 0 && <Badge>{jobsCount}</Badge>}
        </NavLink>
        <NavLink to="/applicants" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faUsers} style={iconStyle} /> Applicants
          {applicantsCount > 0 && <Badge>{applicantsCount}</Badge>}
        </NavLink>
        <NavLink to="/job-post" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faPlusCircle} style={iconStyle} /> Create a Job
        </NavLink>

        <SectionTitle>Company</SectionTitle>
        <NavLink to="/pricing" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faTags} style={iconStyle} /> Pricing
        </NavLink>
        <NavLink to="/company" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faBuilding} style={iconStyle} /> Company Info
        </NavLink>

        <SectionTitle>Experts</SectionTitle>
        <NavLink to="/candidates" style={linkStyle} activeStyle={activeLinkStyle}>
          <FontAwesomeIcon icon={faUserTie} style={iconStyle} /> Expert Profiles
          {expertsCount > 0 && <Badge>{expertsCount}</Badge>}
        </NavLink>
        <NavLink to="#" style={linkStyle} activeStyle={activeLinkStyle} onClick={handleOpenFavoritesPopup}>
          <FontAwesomeIcon icon={faHeart} style={iconStyle} /> Favorite Experts
          {favoritesCount > 0 && <Badge>{favoritesCount}</Badge>}
        </NavLink>
      </div>

      {isFavoritesPopupOpen && (
        <FavoritesPopup>
          <CloseButton onClick={handleCloseFavoritesPopup}><FontAwesomeIcon icon={faTimes} /></CloseButton>
          <h2>Favorite Experts</h2>
          {favoriteExperts.map(expert => (
            <FavoriteExpertCard key={expert.id}>
              <FavoriteExpertImage src={expert.profileImage || 'https://via.placeholder.com/50'} alt={expert.name} />
              <FavoriteExpertInfo>
                <h5>{expert.name}</h5>
                <p>{expert.currentJobTitle}</p>
                <p>{expert.location}</p>
              </FavoriteExpertInfo>
            </FavoriteExpertCard>
          ))}
          <ClearButton onClick={handleClearFavorites}>
            <FontAwesomeIcon icon={faTrash} /> Clear Profiles
          </ClearButton>
        </FavoritesPopup>
      )}
    </>
  );
};

export default ClientSidebar;
