import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase';
import { signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';

const countriesList = ["United States", "United Kingdom", "Canada", "Australia"];

const RegistrationPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    country: '',
    companyName: '',
    companyWebsite: '',
    logo: null,
  });
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, type, files } = e.target;
    const value = type === 'file' ? files[0] : e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUpWithEmail = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignUpWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleAuthProvider);
      navigate('/');
    } catch (error) {
      setError(error.message);
    }
  };

  const styles = {
    container: {
      maxWidth: '400px',
      margin: 'auto',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      backgroundColor: '#fff',
    },
    button: {
      backgroundColor: 'rgb(10, 88, 202)', // Main theme color
      color: 'white',
      padding: '12px 20px',
      margin: '10px 0',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '600',
      width: '100%',
    },
    input: {
      width: '100%',
      padding: '12px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxSizing: 'border-box',
    },
    error: {
      color: 'red',
      fontSize: '0.875rem',
      margin: '10px 0',
    },
    link: {
      color: 'rgb(10, 88, 202)', // Main theme color
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    divider: {
      width: '100%',
      textAlign: 'center',
      borderBottom: '1px solid #ccc',
      lineHeight: '0.1em',
      margin: '20px 0',
      '&::before': {
        content: '"or"',
        background: '#fff',
        padding: '0 10px',
        fontSize: '0.875rem',
        color: '#bbb',
      }
    },
    title: {
      color: '#333',
      textAlign: 'center',
      fontWeight: '600',
      marginBottom: '20px',
    },
    fileInput: {
      padding: '10px',
      background: '#f1f1f1',
      border: '1px dashed #ccc',
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Sign up to find work you love</h2>
      <button onClick={handleSignUpWithGoogle} style={styles.button}>
        Continue with Google
      </button>
      <div style={styles.divider}>or</div>
      {error && <div style={styles.error}>{error}</div>}
      <form onSubmit={handleSignUpWithEmail}>
        <input
          type="text"
          name="firstName"
          placeholder="First name"
          value={formData.firstName}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="text"
          name="lastName"
          placeholder="Last name"
          value={formData.lastName}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password (8 or more characters)"
          value={formData.password}
          onChange={handleInputChange}
          style={styles.input}
          required
        />
        <select
          name="country"
          value={formData.country}
          onChange={handleInputChange}
          style={styles.input}
          required
        >
          <option value="">Select a country...</option>
          {countriesList.map(country => (
            <option key={country} value={country}>{country}</option>
          ))}
        </select>
        <input
          type="text"
          name="companyName"
          placeholder="Company name"
          value={formData.companyName}
          onChange={handleInputChange}
          style={styles.input}
        />
        <input
          type="text"
          name="companyWebsite"
          placeholder="Company website"
          value={formData.companyWebsite}
          onChange={handleInputChange}
          style={styles.input}
        />
        <input
          type="file"
          name="logo"
          onChange={handleInputChange}
          style={styles.fileInput}
        />
        <button type="submit" style={styles.button}>Create my account</button>
      </form>
      <div style={{ marginTop: '1rem' }}>
        Already have an account? <span style={styles.link} onClick={() => navigate('/')}>Log In</span>
      </div>
    </div>
  );
};

export default RegistrationPage;
