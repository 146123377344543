import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { collection, query, getDocs, doc, setDoc, where, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  FiClock, FiMapPin, FiHome, FiInfo, FiDollarSign, FiCalendar, FiBriefcase,
  FiFlag, FiLayers, FiUserCheck, FiLink, FiX, FiHeart, FiEye, FiEdit, FiArrowLeft, FiThumbsUp, FiList, FiVideo, FiChevronLeft, FiChevronRight
} from 'react-icons/fi';
import styled from 'styled-components';
import FormSelect from '../components/formSelect';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import bg1 from '../assets/images/hero/bg.jpg';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const ModalContent = styled.div`
  background: white;
  padding: 40px;
  border-radius: 12px;
  width: auto;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: transparent;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

const JobCard = styled.div`
  background: white;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const JobTitle = styled.h5`
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
`;

const JobInfo = styled.p`
  color: #6c757d;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  svg {
    margin-right: 5px;
  }
`;

const JobDescription = styled.p`
  color: #6c757d;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 10px;
`;

const JobActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
`;

const JobActionButton = styled.button`
  background: ${props => props.disabled ? '#ccc' : '#007bff'};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${props => props.disabled ? '#ccc' : '#0056b3'};
  }
  svg {
    margin-right: 5px;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const BackButton = styled.button`
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background 0.3s;
  &:hover {
    background: #0056b3;
  }
`;

const JobDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const JobDetailsHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const JobDetailsTitle = styled.h2`
  font-weight: 600;
  color: #333;
  margin-left: 20px;
`;

const JobDetailsInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
`;

const JobDetail = styled.div`
  display: flex;
  align-items: center;
  color: #6c757d;
  font-size: 14px;
  svg {
    margin-right: 5px;
  }
`;

const ExpiredText = styled.span`
  color: red;
  font-weight: bold;
  margin-left: 10px;
`;

const ToggleButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ToggleButton = styled.button`
  background: ${props => props.active ? '#007bff' : 'white'};
  color: ${props => props.active ? 'white' : '#007bff'};
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: ${props => props.active ? '#0056b3' : '#007bff'};
    color: white;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  background: ${props => props.disabled ? '#ccc' : '#007bff'};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: background 0.3s, color 0.3s;
  &:hover {
    background: ${props => props.disabled ? '#ccc' : '#0056b3'};
  }
  svg {
    margin-right: 5px;
  }
`;

const categories = [
  'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
  'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
  'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
  'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
];

const calculateJobScore = (job) => {
  const fields = [
    'companyName', 'location', 'minSalary', 'maxSalary', 'salaryType', 'industry',
    'description', 'experience', 'expiryDate', 'qualifications', 'skills', 'type', 'website', 'logo'
  ];
  return fields.reduce((score, field) => (job[field] ? score + 1 : score), 0);
};

export default function JobGridOne() {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [showRecommended, setShowRecommended] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(6);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const q = query(collection(db, "jobs"));
        const querySnapshot = await getDocs(q);
        const jobsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        jobsData.sort((a, b) => calculateJobScore(b) - calculateJobScore(a));
        setJobs(jobsData);
        setFilteredJobs(jobsData);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    const fetchRecommendedJobs = async () => {
      try {
        if (user) {
          const userDoc = await getDoc(doc(db, 'profiles', user.uid));
          const userProfile = userDoc.data();
          if (userProfile && userProfile.industry) {
            const similarIndustries = getSimilarIndustries(userProfile.industry);
            const recommendedJobsQuery = query(
              collection(db, 'jobs'),
              where('industry', 'in', similarIndustries)
            );
            const recommendedJobsSnapshot = await getDocs(recommendedJobsQuery);
            const recommendedJobsData = recommendedJobsSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setRecommendedJobs(recommendedJobsData);
          }
        }
      } catch (error) {
        console.error("Error fetching recommended jobs:", error);
      }
    };

    fetchJobs();
    fetchRecommendedJobs();
  }, [user]);

  useEffect(() => {
    applyFilters();
  }, [keyword, selectedLocation, selectedType, selectedIndustry, jobs, showRecommended, recommendedJobs]);

  const applyFilters = () => {
    const filtered = (showRecommended ? recommendedJobs : jobs).filter(job => {
      return (
        (!keyword || (job.title && job.title.toLowerCase().includes(keyword.toLowerCase())) || (job.description && job.description.toLowerCase().includes(keyword.toLowerCase()))) &&
        (!selectedLocation || (job.location && job.location.toLowerCase().includes(selectedLocation.value.toLowerCase()))) &&
        (!selectedType || job.type === selectedType.value) &&
        (!selectedIndustry || job.industry === selectedIndustry.value)
      );
    });
    setFilteredJobs(filtered);
  };

  const getSimilarIndustries = (industry) => {
    const industryMap = {
      "IT": ["IT", "Software Development", "Fullstack Development", "Web Development"],
      "Software Development": ["Software Development", "IT", "Fullstack Development", "Web Development"],
      "Fullstack Development": ["Fullstack Development", "IT", "Software Development", "Web Development"],
      "Marketing": ["Marketing", "Sales", "Advertising"],
      "Finance": ["Finance", "Accounting", "Banking"]
      // Add more mappings as necessary
    };
    return industryMap[industry] || [industry];
  };

  const isExpired = (expiryDate) => {
    return new Date(expiryDate) < new Date();
  };

  const viewJobDetails = (job) => {
    setSelectedJob(job);
  };

  const closeJobDetails = () => {
    setSelectedJob(null);
  };

  const saveJob = async (jobId) => {
    if (!user) {
      toast.info("Please log in to save jobs.", { position: 'top-center', autoClose: 5000 });
      return;
    }
    const userDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
    try {
      await setDoc(userDocRef, { jobId });
      toast.success("Job saved successfully.", { position: 'top-center', autoClose: 5000 });
    } catch (error) {
      console.error("Error saving job:", error);
      toast.error("Failed to save job.", { position: 'top-center', autoClose: 5000 });
    }
  };

  const applyForJob = (job) => {
    if (isExpired(job.expiryDate)) {
      toast.error("This job has expired and cannot be applied to.", { position: 'top-center', autoClose: 5000 });
      return;
    }
    navigate('/job-apply', { state: { jobDetails: job } });
  };

  const showVideoPopup = (videoURL) => {
    if (!videoURL) {
      toast.info("No video available for this job.", { position: 'top-center', autoClose: 5000 });
      return;
    }
    setVideoURL(videoURL);
    setShowVideoModal(true);
  };

  const closeVideoPopup = () => {
    setShowVideoModal(false);
    setVideoURL(null);
  };

  // Pagination logic
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const previousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredJobs.length / jobsPerPage)) setCurrentPage(currentPage + 1);
  };

  return (
    <>
      <ExpertNavbar />
      <section style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'top', padding: '120px 0', position: 'relative', zIndex: '1', backgroundSize: 'cover' }}>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12 text-center">
              <h5 style={{ fontWeight: '600', color: 'white' }}>Explore Job Opportunities</h5>
            </div>
          </div>
        </div>
      </section>
      <section style={{ padding: '60px 0' }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 mt-4">
              <FormSelect
                keyword={keyword}
                setKeyword={setKeyword}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={setSelectedIndustry}
              />
            </div>
          </div>
          <div className="container mt-5">
            <BackButton onClick={() => navigate('/expertdashboard')}>
              <FiArrowLeft /> Back to Expert Dashboard
            </BackButton>
            <ToggleButtons>
              <ToggleButton
                active={!showRecommended}
                onClick={() => setShowRecommended(false)}
              >
                <FiList /> Available Jobs
              </ToggleButton>
              <ToggleButton
                active={showRecommended}
                onClick={() => setShowRecommended(true)}
              >
                <FiThumbsUp /> Recommended Jobs
              </ToggleButton>
            </ToggleButtons>
            <div className="row g-4 mt-3">
              {currentJobs.map((job) => (
                <div className="col-lg-4 col-md-6 col-12" key={job.id}>
                  <JobCard>
                    <div>
                      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <img src={job.logo || 'https://via.placeholder.com/150'} alt={`${job.companyName} logo`} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                        <span style={{ background: '#EAEFF3', color: '#333', padding: '5px 10px', borderRadius: '15px' }}>{job.type}</span>
                      </div>
                      <JobTitle>{job.title}</JobTitle>
                      <JobInfo><FiHome /> {job.companyName}</JobInfo>
                      <JobInfo><FiMapPin /> {job.location}</JobInfo>
                      <JobInfo><FiDollarSign /> ${job.minSalary} - ${job.maxSalary} {job.salaryType}</JobInfo>
                      <JobInfo><FiBriefcase /> {job.industry}</JobInfo>
                      <JobInfo><FiCalendar /> Expires on: {job.expiryDate} {isExpired(job.expiryDate) && <ExpiredText>Expired</ExpiredText>}</JobInfo>
                      <JobDescription>{job.description.length > 100 ? `${job.description.substring(0, 100)}...` : job.description}</JobDescription>
                    </div>
                    <JobActions>
                      <JobActionButton onClick={() => viewJobDetails(job)}><FiEye /> View</JobActionButton>
                      <JobActionButton onClick={() => saveJob(job.id)}><FiHeart /> Save</JobActionButton>
                      <JobActionButton disabled={isExpired(job.expiryDate)} onClick={() => applyForJob(job)}><FiEdit /> Apply</JobActionButton>
                      <JobActionButton onClick={() => showVideoPopup(job.videoURL)}><FiVideo /> Video</JobActionButton>
                    </JobActions>
                  </JobCard>
                </div>
              ))}
            </div>
            <Pagination>
              <PaginationButton onClick={previousPage} disabled={currentPage === 1}>
                <FiChevronLeft /> Previous
              </PaginationButton>
              <span>Page {currentPage}</span>
              <PaginationButton onClick={nextPage} disabled={currentPage === Math.ceil(filteredJobs.length / jobsPerPage)}>
                Next <FiChevronRight />
              </PaginationButton>
            </Pagination>
          </div>
        </div>
      </section>
      {selectedJob && (
        <Modal>
          <ModalContent>
            <ModalCloseButton onClick={closeJobDetails}><FiX /></ModalCloseButton>
            <JobDetailsContainer>
              <JobDetailsHeader>
                <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
                <JobDetailsTitle>{selectedJob.title} - {selectedJob.companyName}</JobDetailsTitle>
              </JobDetailsHeader>
              <JobDetailsInfo>
                <JobDetail><FiHome /> {selectedJob.companyName}</JobDetail>
                <JobDetail><FiMapPin /> {selectedJob.location}</JobDetail>
                <JobDetail><FiDollarSign /> ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</JobDetail>
                <JobDetail><FiBriefcase /> {selectedJob.industry}</JobDetail>
                <JobDetail><FiClock /> Experience: {selectedJob.experience} years</JobDetail>
                <JobDetail><FiCalendar /> Expires on: {selectedJob.expiryDate} {isExpired(selectedJob.expiryDate) && <ExpiredText>Expired</ExpiredText>}</JobDetail>
                <JobDetail><FiUserCheck /> Qualifications: {selectedJob.qualifications}</JobDetail>
                <JobDetail><FiLayers /> Skills: {selectedJob.skills}</JobDetail>
                <JobDetail><FiFlag /> Type: {selectedJob.type}</JobDetail>
                <JobDetail><FiLink /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></JobDetail>
              </JobDetailsInfo>
              <p>{selectedJob.description}</p>
              <ModalActions>
                <JobActionButton disabled={isExpired(selectedJob.expiryDate)} onClick={() => applyForJob(selectedJob)}><FiEdit /> Apply Now</JobActionButton>
                <JobActionButton onClick={() => saveJob(selectedJob.id)}><FiHeart /> Save Job</JobActionButton>
              </ModalActions>
            </JobDetailsContainer>
          </ModalContent>
        </Modal>
      )}
      {showVideoModal && (
        <Modal>
          <ModalContent>
            <ModalCloseButton onClick={closeVideoPopup}><FiX /></ModalCloseButton>
            <video controls style={{ width: '100%' }}>
              <source src={videoURL} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </ModalContent>
        </Modal>
      )}
      <Footer />
      <ScrollTop />
      <ToastContainer />
    </>
  );
}
