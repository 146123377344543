import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { getFirestore, doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { FiArrowRightCircle, FiCreditCard, FiXCircle, FiCheckCircle, FiDollarSign, FiUsers, FiUserPlus, FiToggleLeft, FiToggleRight } from "react-icons/fi";
import { auth } from '../firebase';
import bg1 from "../assets/images/hero/bg.jpg";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";

const stripePromise = loadStripe("pk_test_51Pt9fEGhaGQiTjEQMhblOB4dCx7lMx1wIDwmNFeVJUA37fVF93rikuo8lEOzq4ZhK7rdgEr3ybqG7yzW21ooGrrX00vH5L6uoB");

const themeColors = {
  primary: '#007bff',
  secondary: '#6c757d',
  background: '#f8f9fa',
  text: '#343a40',
  lightText: '#6c757d',
  border: '#dee2e6',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
};

const styles = {
  container: {
    backgroundColor: themeColors.background,
    minHeight: '100vh',
    padding: '2rem',
    fontFamily: 'Arial, sans-serif',
  },
  formContainer: {
    maxWidth: '900px',
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '1rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  formContent: {
    padding: '2rem',
  },
  heading: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center',
    color: themeColors.primary,
  },
  subHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: themeColors.primary,
  },
  button: {
    padding: '0.75rem 1.5rem',
    backgroundColor: themeColors.primary,
    color: 'white',
    border: 'none',
    borderRadius: '0.25rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 0.2rem rgba(0, 123, 255, 0.5)`,
    },
  },
};

const ExpertsPaymentForm = ({ packageDetails, onClose, billingCycle }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const db = getFirestore();

    const [cardDetails, setCardDetails] = useState({
        number: '',
        name: '',
        expiry: '',
        cvc: '',
        focused: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCardDetails(prev => ({ ...prev, [name]: value }));
    };

    const handleInputFocus = (e) => {
        setCardDetails(prev => ({ ...prev, focused: e.target.name }));
    };

    const createPaymentIntent = async () => {
        const secretKey = "sk_test_51Pt9fEGhaGQiTjEQFaKNiahB6fkb0smPYvIkG0983nSP8hVCtoncuuaiL8mKazWkRNqQmVTpR35NfvsJxVwHiGdE003lBamUeq";
        const amount = packageDetails[billingCycle === 'monthly' ? 'monthlyPrice' : 'annualPrice'] * 100; // Convert price to cents

        try {
            const params = new URLSearchParams();
            params.append('amount', amount.toString());
            params.append('currency', 'usd');
            params.append('payment_method_types[]', 'card');

            const response = await fetch('https://api.stripe.com/v1/payment_intents', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${secretKey}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params.toString()
            });

            const data = await response.json();

            if (response.ok) {
                return data.client_secret;
            } else {
                throw new Error(data.error.message);
            }
        } catch (error) {
            console.error("Error creating payment intent:", error.message);
            throw error;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        setMessage('');
        const cardElement = elements.getElement(CardElement);

        try {
            const clientSecret = await createPaymentIntent();

            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                console.error(error);
                setMessage(error.message);
                setLoading(false);
            } else {
                console.log("Payment successful:", paymentIntent);
                setMessage("Payment successful!");

                const user = auth.currentUser;
                const userRef = doc(db, 'expertpricing', user.uid);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists()) {
                    await updateDoc(userRef, { 
                        plan: packageDetails.title, 
                        token: paymentIntent.id,
                        billingCycle: billingCycle
                    });
                } else {
                    await setDoc(userRef, {
                        uid: user.uid,
                        email: user.email,
                        displayName: user.displayName,
                        plan: packageDetails.title,
                        token: paymentIntent.id,
                        subscribed: true,
                        billingCycle: billingCycle
                    });
                }

                const userSubscriptionDetails = {
                    packageName: packageDetails.title,
                    userEmail: user.email,
                    userId: user.uid,
                    userName: user.displayName,
                    subscribed: true,
                    billingCycle: billingCycle
                };

                try {
                    const serverResponse = await fetch("https://expertstoo.com/send-to-zapier", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(userSubscriptionDetails),
                    });

                    if (serverResponse.ok) {
                        console.log("User data sent to Zapier via server successfully.");
                    } else {
                        console.error("Failed to send data to server:", serverResponse.statusText);
                    }
                } catch (serverError) {
                    console.error("Error sending data to server:", serverError);
                }

                setLoading(false);
                setTimeout(() => {
                    onClose();
                    navigate('/expertdashboard');
                }, 3000);
            }
        } catch (error) {
            console.error("Payment error:", error);
            setMessage("An unexpected error occurred.");
            setLoading(false);
        }
    };

    return (
        <div style={{
            position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000, animation: 'fadeIn 0.3s ease-in-out'
        }}>
            <div style={{
                backgroundColor: 'white', padding: '30px', borderRadius: '10px', boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)', maxWidth: '800px', width: '90%', animation: 'slideIn 0.3s ease-in-out', display: 'flex', flexDirection: 'row', textAlign: 'center', position: 'relative'
            }}>
                <FiXCircle onClick={onClose} style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer', color: themeColors.primary, fontSize: '24px' }} />
                <div style={{ flex: 1, marginRight: '20px' }}>
                    <h2>{packageDetails.title} Plan</h2>
                    <p>{packageDetails.description}</p>
                    <ul style={{ listStyleType: 'none', padding: '0', marginBottom: '20px', textAlign: 'left' }}>
                        {packageDetails.features.map((feature, idx) => (
                            <li key={idx} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', color: themeColors.text }}>
                                <FiCheckCircle style={{ marginRight: '10px', color: themeColors.success }} />
                                {feature}
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ flex: 1 }}>
                    <div style={{ marginBottom: '20px', animation: 'fadeIn 0.5s ease-in-out' }}>
                        <Cards
                            number={cardDetails.number}
                            name={cardDetails.name}
                            expiry={cardDetails.expiry}
                            cvc={cardDetails.cvc}
                            focused={cardDetails.focused}
                        />
                    </div>
                    <form onSubmit={handleSubmit} style={{ marginTop: '20px', animation: 'fadeIn 0.5s ease-in-out' }}>
                        <input
                            type="text"
                            name="number"
                            placeholder="Card Number"
                            value={cardDetails.number}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            style={styles.input}
                        />
                        <input
                            type="text"
                            name="name"
                            placeholder="Name on Card"
                            value={cardDetails.name}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            style={styles.input}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <input
                                type="text"
                                name="expiry"
                                placeholder="MM/YY"
                                value={cardDetails.expiry}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                style={{ ...styles.input, width: '48%' }}
                            />
                            <input
                                type="text"
                                name="cvc"
                                placeholder="CVC"
                                value={cardDetails.cvc}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                style={{ ...styles.input, width: '48%' }}
                            />
                        </div>
                        <div style={{ marginTop: '20px', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } }, invalid: { color: '#9e2146' } } }} />
                        </div>
                        {message && (
                            <div style={{
                                marginTop: '20px',
                                padding: '10px',
                                border: '1px solid',
                                borderColor: message === "Payment successful!" ? themeColors.success : themeColors.danger,
                                borderRadius: '5px',
                                backgroundColor: message === "Payment successful!" ? '#d4edda' : '#f8d7da',
                                color: message === "Payment successful!" ? '#155724' : '#721c24',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {message === "Payment successful!" ? <FiCheckCircle style={{ marginRight: '10px' }} /> : <FiXCircle style={{ marginRight: '10px' }} />}
                                {message}
                            </div>
                        )}
                        <button type="submit" disabled={!stripe || loading} style={{
                            ...styles.button,
                            width: '100%',
                            marginTop: '20px',
                            position: 'relative',
                            overflow: 'hidden'
                        }}>
                            {loading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="spinner" style={{
                                        width: '24px', height: '24px', border: '3px solid rgba(255, 255, 255, 0.3)', borderTop: '3px solid white', borderRadius: '50%', animation: 'spin 1s linear infinite'
                                    }}></div>
                                </div>
                            ) : (
                                `Pay $${billingCycle === 'monthly' ? packageDetails.monthlyPrice : packageDetails.annualPrice}`
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default function ExpertsPricing() {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [billingCycle, setBillingCycle] = useState('monthly');

    const packages = [
        {
            title: "Talent Starter",
            description: "Ideal for new talents looking to get noticed in the industry.",
            icon: <FiUsers size={24} />,
            monthlyPrice: 10,
            annualPrice: 100,
            features: [
                "Profile Boost",
                "Access to Basic Job Listings",
                "5 Job Applications per month",
                "Email Notifications",
                "Basic Analytics",
            ],
        },
        {
            title: "Pro Talent",
            description: "Designed for experienced professionals aiming for higher visibility.",
            icon: <FiUserPlus size={24} />,
            monthlyPrice: 30,
            annualPrice: 300,
            features: ["Premium Profile Placement",
                "Access to Advanced Job Listings",
                "Unlimited Job Applications",
                "Priority Email Support",
                "Advanced Analytics",
                "1-on-1 Profile Review",
            ],
        },
        {
            title: "Elite Talent",
            description: "For top-tier experts seeking premium job opportunities and services.",
            icon: <FiDollarSign size={24} />,
            monthlyPrice: 69,
            annualPrice: 690,
            features: [
                "Exclusive Profile Highlight",
                "Access to Elite Job Listings",
                "Unlimited Applications with Fast-Track Options",
                "Dedicated Account Manager",
                "Comprehensive Analytics & Reporting",
                "24/7 Priority Support",
                "Personal Branding Consultation",
            ],
        },
    ];

    return (
        <>
            <section style={{ 
                backgroundImage: `url(${bg1})`, 
                backgroundPosition: 'center', 
                backgroundSize: 'cover',
                padding: '170px 0', 
                position: 'relative' 
            }}>
                <div style={{ 
                    background: 'rgba(0, 0, 0, 0.6)', 
                    position: 'absolute', 
                    top: 0, 
                    left: 0, 
                    right: 0, 
                    bottom: 0 
                }}></div>
                <div style={{ position: 'relative', zIndex: 1 }}>
                    <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                        <h1 style={{ color: 'white', fontWeight: '600', marginBottom: '0', fontSize: '2.5rem' }}>Expert Pricing Plans</h1>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <nav aria-label="breadcrumb">
                            <ul style={{ display: 'flex', padding: '0', margin: '0', listStyleType: 'none' }}>
                                <li style={{ marginRight: '5px' }}><Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>ExpertsToo</Link></li>
                                <li style={{ color: '#fff' }}> / Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>

            <section style={{ padding: '60px 0', backgroundColor: themeColors.background }}>
                <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 20px' }}>
                    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <h2 style={{ fontSize: '32px', color: themeColors.primary, marginBottom: '20px' }}>Choose Your Plan</h2>
                        <div style={{ 
                            display: 'inline-flex', 
                            alignItems: 'center', 
                            backgroundColor: '#e9ecef', 
                            borderRadius: '30px', 
                            padding: '5px'
                        }}>
                            <span 
                                style={{ 
                                    padding: '10px 20px', 
                                    borderRadius: '25px', 
                                    cursor: 'pointer',
                                    backgroundColor: billingCycle === 'monthly' ? themeColors.primary : 'transparent',
                                    color: billingCycle === 'monthly' ? 'white' : themeColors.text,
                                    transition: 'all 0.3s ease'
                                }} 
                                onClick={() => setBillingCycle('monthly')}
                            >
                                Monthly
                            </span>
                            <span 
                                style={{ 
                                    padding: '10px 20px', 
                                    borderRadius: '25px', 
                                    cursor: 'pointer',
                                    backgroundColor: billingCycle === 'annual' ? themeColors.primary : 'transparent',
                                    color: billingCycle === 'annual' ? 'white' : themeColors.text,
                                    transition: 'all 0.3s ease'
                                }} 
                                onClick={() => setBillingCycle('annual')}
                            >
                                Annual
                            </span>
                        </div>
                        {billingCycle === 'annual' && (
                            <p style={{ marginTop: '10px', color: themeColors.success }}>Save up to 20% with annual billing!</p>
                        )}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                        {packages.map((pkg, index) => (
                            <div key={index} style={{ 
                                flex: '1 1 300px', 
                                maxWidth: '350px',
                                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', 
                                borderRadius: '10px', 
                                overflow: 'hidden', 
                                backgroundColor: 'white', 
                                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-10px)',
                                    boxShadow: '0 6px 30px rgba(0, 0, 0, 0.15)'
                                }
                            }}>
                                <div style={{ 
                                    backgroundColor: pkg.title === "Elite Talent" ? themeColors.primary : themeColors.text, 
                                    padding: '40px 20px', 
                                    color: 'white', 
                                    textAlign: 'center',
                                    transition: 'background-color 0.3s ease-in-out' 
                                }}>
                                    {pkg.icon}
                                    <h3 style={{ margin: '10px 0', fontSize: '24px' }}>{pkg.title}</h3>
                                    <p style={{ margin: '0', fontSize: '14px' }}>{pkg.description}</p>
                                </div>
                                <div style={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    backgroundColor: '#f8f9fa', 
                                    padding: '20px', 
                                    borderBottom: '1px solid #dee2e6' 
                                }}>
                                    <span style={{ fontSize: '16px' }}>$</span>
                                    <span style={{ fontSize: '36px', fontWeight: 'bold', marginLeft: '5px' }}>
                                        {billingCycle === 'monthly' ? pkg.monthlyPrice : pkg.annualPrice}
                                    </span>
                                    <span style={{ fontSize: '16px', alignSelf: 'flex-end', marginLeft: '5px' }}>
                                        /{billingCycle === 'monthly' ? 'mo' : 'yr'}
                                    </span>
                                </div>
                                <div style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
                                    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
                                        {pkg.features.map((feature, idx) => (
                                            <li key={idx} style={{ 
                                                marginBottom: '10px', 
                                                display: 'flex', 
                                                alignItems: 'center', 
                                                color: themeColors.text 
                                            }}>
                                                <FiCheckCircle style={{ marginRight: '10px', color: themeColors.success }} />
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <button 
                                            onClick={() => setSelectedPackage(pkg)} 
                                            style={{ 
                                                backgroundColor: themeColors.primary, 
                                                color: 'white', 
                                                border: 'none', 
                                                padding: '10px 20px', 
                                                borderRadius: '5px', 
                                                cursor: 'pointer', 
                                                transition: 'background-color 0.3s ease-in-out',
                                                '&:hover': {
                                                    backgroundColor: '#0056b3'
                                                }
                                            }}
                                        >
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {selectedPackage && (
                        <Elements stripe={stripePromise}>
                            <ExpertsPaymentForm 
                                packageDetails={selectedPackage} 
                                onClose={() => setSelectedPackage(null)} 
                                billingCycle={billingCycle}
                            />
                        </Elements>
                    )}
                </div>
            </section>

            <section style={{ padding: '60px 0', backgroundColor: 'white' }}>
                <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
                    <h2 style={{ fontSize: '32px', color: themeColors.primary, marginBottom: '20px' }}>Frequently Asked Questions</h2>
                    <div style={{ textAlign: 'left' }}>
                        {[
                            { 
                                q: "Can I switch plans later?", 
                                a: "Yes, you can upgrade or downgrade your plan at any time. The changes will be reflected in your next billing cycle." 
                            },
                            { 
                                q: "Is there a free trial available?", 
                                a: "We offer a 14-day free trial for our Talent Starter and Pro Talent plans. No credit card is required to start your trial." 
                            },
                            { 
                                q: "What payment methods do you accept?", 
                                a: "We accept all major credit cards, including Visa, MasterCard, American Express, and Discover." 
                            },
                            { 
                                q: "Is my data secure?", 
                                a: "Yes, we use industry-standard encryption and security measures to protect your data and transactions." 
                            }
                        ].map((faq, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <h3 style={{ color: themeColors.text, marginBottom: '10px' }}>{faq.q}</h3>
                                <p style={{ color: themeColors.lightText }}>{faq.a}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Footer />
            <ScrollTop />
        </>
    );
}