import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { collection, getDocs, addDoc, setDoc, serverTimestamp, doc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import ClientSidebar from './clientsidebar';
import ClientNavbar from './clientnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import styled from 'styled-components';
import { FaEnvelope, FaUserCircle, FaVideo, FaSearch, FaPhone, FaCalendarAlt, FaHeart, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

const themeColor = 'rgb(10, 88, 202)';
const secondaryColor = '#2c3e50';
const backgroundColor = '#f9fbfd';

// Data arrays
const industryCategories = [
  { value: 'marketing', label: 'Marketing' },
  { value: 'fullstack_engineer', label: 'Fullstack Engineer' },
  { value: 'data_science', label: 'Data Science' },
  { value: 'product_management', label: 'Product Management' },
  { value: 'graphic_design', label: 'Graphic Design' },
  { value: 'ui_ux_design', label: 'UI/UX Design' },
  { value: 'software_development', label: 'Software Development' },
  { value: 'project_management', label: 'Project Management' },
  { value: 'sales', label: 'Sales' },
  { value: 'customer_support', label: 'Customer Support' },
  { value: 'human_resources', label: 'Human Resources' },
  { value: 'finance', label: 'Finance' },
  { value: 'legal', label: 'Legal' },
  { value: 'education', label: 'Education' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'real_estate', label: 'Real Estate' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'other', label: 'Other' }
];

const skillsByCategory = {
  marketing: [
    { value: 'seo', label: 'SEO' },
    { value: 'digital_advertising', label: 'Digital Advertising' },
    { value: 'content_strategy', label: 'Content Strategy' },
    { value: 'social_media', label: 'Social Media' },
    { value: 'email_marketing', label: 'Email Marketing' },
    // ... other marketing skills
  ],
  fullstack_engineer: [
    { value: 'javascript', label: 'JavaScript' },
    { value: 'react', label: 'React' },
    { value: 'node_js', label: 'Node.js' },
    { value: 'express_js', label: 'Express.js' },
    { value: 'mongodb', label: 'MongoDB' },
    // ... other fullstack engineering skills
  ],
  data_science: [
    { value: 'python', label: 'Python' },
    { value: 'r', label: 'R' },
    { value: 'sql', label: 'SQL' },
    { value: 'machine_learning', label: 'Machine Learning' },
    { value: 'deep_learning', label: 'Deep Learning' },
    // ... other data science skills
  ],
  // ... other categories and their skills
};

const countries = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'uk', label: 'UK' },
  { value: 'australia', label: 'Australia' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'spain', label: 'Spain' },
  { value: 'italy', label: 'Italy' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'india', label: 'India' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'mexico', label: 'Mexico' }
];

// Data arrays (industryCategories, skillsByCategory, countries) remain the same

const ClientDashboard = () => {
  const navigate = useNavigate();
  const [candidatesData, setCandidatesData] = useState([]);
  const [showMessagingPopup, setShowMessagingPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [filter, setFilter] = useState({ text: '', industry: '', skill: '', country: '' });
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, currentUser => {
      if (!currentUser) {
        navigate('/clientlogin');
      } else {
        fetchCandidatesData();
      }
    });
    return () => unsubscribeAuth();
  }, [navigate]);

  const signOutHandler = async () => {
    await firebaseSignOut(auth);
    navigate('/clientlogin');
  };

  const handleOpenMessagePopup = (candidateEmail) => {
    const selectedCandidate = candidatesData.find(candidate => candidate.email === candidateEmail);
    setSelectedCandidate(selectedCandidate);
    setShowMessagingPopup(true);
  };

  const handleCloseMessagePopup = () => {
    setShowMessagingPopup(false);
    setSelectedCandidate(null);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter(prev => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const fetchCandidatesData = async () => {
    setIsLoading(true);
    try {
      const snapshot = await getDocs(collection(db, "profiles"));
      setCandidatesData(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching candidates data: ", error);
      setNotification("Failed to load candidates. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addFavorite = async (candidate) => {
    const user = auth.currentUser;
    try {
      await addDoc(collection(db, 'favorites'), {
        userId: user.uid,
        candidateId: candidate.id,
        timestamp: serverTimestamp()
      });
      await addDoc(collection(db, 'notifications'), {
        userId: user.uid,
        message: `You added ${candidate.name} as a favorite`,
        timestamp: serverTimestamp()
      });
      setNotification(`You added ${candidate.name} as a favorite`);
      setTimeout(() => setNotification(null), 3000);
    } catch (error) {
      console.error("Error adding favorite: ", error);
      setNotification("Failed to add favorite. Please try again.");
    }
  };

  const filteredCandidates = candidatesData.filter(candidate => {
    const matchesText = candidate.name.toLowerCase().includes(filter.text) || candidate.currentJobTitle.toLowerCase().includes(filter.text);
    const matchesIndustry = filter.industry === '' || candidate.industry?.toLowerCase() === filter.industry;
    const matchesSkill = filter.skill === '' || (candidate.skills && candidate.skills.map(skill => skill.toLowerCase()).includes(filter.skill));
    const matchesCountry = filter.country === '' || candidate.country?.toLowerCase() === filter.country;
    return matchesText && matchesIndustry && matchesSkill && matchesCountry;
  });

  return (
    <PageWrapper>
      <AnimatePresence>
        {notification && (
          <NotificationPopup
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
          >
            {notification}
          </NotificationPopup>
        )}
      </AnimatePresence>
      <ClientNavbarWrapper>
        <ClientNavbar signOutHandler={signOutHandler} />
      </ClientNavbarWrapper>
      <MainContainer>
        <Sidebar>
          <ClientSidebar />
        </Sidebar>
        <Content>
          <HeroSection>
            <HeroContent>
              <HeroTitle>Find the Best Candidates</HeroTitle>
              <HeroSubtitle>Discover talented professionals for your team</HeroSubtitle>
            </HeroContent>
          </HeroSection>
          <BookACallSection navigate={navigate} />
          <SearchContainer>
            <SearchInputGroup>
              <SearchIcon />
              <SearchInput
                type="text"
                placeholder="Search by keyword..."
                onChange={handleFilterChange}
                name="text"
              />
            </SearchInputGroup>
            <SearchButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              Search
            </SearchButton>
          </SearchContainer>
          <FilterContainer>
            <FilterGroup>
              <FilterLabel>Industry</FilterLabel>
              <FilterSelect name="industry" onChange={handleFilterChange}>
                <option value="">Select Industry</option>
                {industryCategories.map((industry) => (
                  <option key={industry.value} value={industry.value}>{industry.label}</option>
                ))}
              </FilterSelect>
            </FilterGroup>
            <FilterGroup>
              <FilterLabel>Skills</FilterLabel>
              <FilterSelect name="skill" onChange={handleFilterChange}>
                <option value="">Select Skill</option>
                {Object.keys(skillsByCategory).map((category) =>
                  skillsByCategory[category].map((skill) => (
                    <option key={skill.value} value={skill.value}>{skill.label}</option>
                  ))
                )}
              </FilterSelect>
            </FilterGroup>
            <FilterGroup>
              <FilterLabel>Country</FilterLabel>
              <FilterSelect name="country" onChange={handleFilterChange}>
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.value} value={country.value}>{country.label}</option>
                ))}
              </FilterSelect>
            </FilterGroup>
          </FilterContainer>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <CandidatesSection 
              candidates={filteredCandidates} 
              handleOpenMessagePopup={handleOpenMessagePopup} 
              addFavorite={addFavorite}
            />
          )}
        </Content>
      </MainContainer>
      <AnimatePresence>
        {showMessagingPopup && (
          <MessagingPopup
            onClose={handleCloseMessagePopup}
            selectedUser={selectedCandidate}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          />
        )}
      </AnimatePresence>
      <Footer />
      <ScrollTop />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HeroSection = styled.div`
background-color:white;
  background-size: cover;
  background-position: center;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000;
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
`;

const ClientNavbarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const MainContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 70px);
  background-color: ${backgroundColor};
  margin-top: 70px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  flex-shrink: 0;
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
    box-shadow: none;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const SearchInputGroup = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 30px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const SearchIcon = styled(FaSearch)`
  margin-right: 10px;
  color: ${themeColor};
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
`;

const SearchButton = styled(motion.button)`
  padding: 10px 20px;
  background-color: ${themeColor};
  color: #fff;
  border: none;
  border-radius: 30px;
  margin-left: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }

  &:hover {
    background-color: #084DC2;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterGroup = styled.div`
  flex: 1;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const FilterLabel = styled.label`
  font-size: 0.9rem;
  color: ${secondaryColor};
  margin-bottom: 5px;
  display: block;
`;

const FilterSelect = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 1rem;
`;

const NotificationPopup = styled(motion.div)`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 10000;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${themeColor};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

// BookACallSection component
const BookACallSection = ({ navigate }) => {
  return (
    <BookACallContainer
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <BookACallContent>
        <h2>Connect with Experts Too</h2>
        <p><FaPhone /> Let's discuss how we can tailor our expertise to your needs.</p>
        <BookACallButton
          onClick={() => navigate('/bookacall')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaCalendarAlt style={{ marginRight: '5px' }} />
          Schedule a Call
        </BookACallButton>
      </BookACallContent>
    </BookACallContainer>
  );
};

const BookACallContainer = styled(motion.div)`
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
 box-shadow: 0 4px 15px rgba(0,0,0,0.1);
`;

const BookACallContent = styled.div`
  padding: 30px;
  color: white;
  text-align: center;

  h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const BookACallButton = styled(motion.button)`
  padding: 10px 20px;
  background-color: white;
  color: #6e8efb;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

// CandidatesSection component
const CandidatesSection = ({ candidates, handleOpenMessagePopup, addFavorite }) => {
  const navigate = useNavigate();
  const [videoPopup, setVideoPopup] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const openProfilePage = (candidateEmail) => {
    navigate(`/candidate-profile/${candidateEmail}`);
  };

  const showVideoPopup = (videoUrl) => {
    setCurrentVideoUrl(videoUrl);
    setVideoPopup(true);
  };

  const closeVideoPopup = () => {
    setVideoPopup(false);
    setCurrentVideoUrl('');
  };

  return (
    <>
      <CandidatesContainer>
        {candidates.map((candidate) => (
          <CandidateCard key={candidate.id} whileHover={{ y: -5 }}>
            <ProfileImage src={candidate.profileImage || 'https://via.placeholder.com/120'} alt="Profile" />
            <CandidateInfo>
              <CandidateName>{candidate.name}</CandidateName>
              <CandidateDescription>{candidate.currentJobTitle}</CandidateDescription>
              <CandidateDescription>{candidate.country}</CandidateDescription>
              <Experience>Experience: {candidate.experienceYears} years</Experience>
              <BadgeContainer>
                {candidate.skills && candidate.skills.slice(0, 4).map((skill, index) => (
                  <Badge key={index}>{skill}</Badge>
                ))}
              </BadgeContainer>
            </CandidateInfo>
            <ActionButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => handleOpenMessagePopup(candidate.email)}>
              <FaEnvelope /> Message
            </ActionButton>
            <ActionButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => openProfilePage(candidate.email)}>
              <FaUserCircle /> View Profile
            </ActionButton>
            {candidate.videoIntroUrl && (
              <ActionButton whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => showVideoPopup(candidate.videoIntroUrl)}>
                <FaVideo /> Intro Video
              </ActionButton>
            )}
            <LoveButton 
              whileHover={{ scale: 1.1 }} 
              whileTap={{ scale: 0.9 }} 
              onClick={() => addFavorite(candidate)} 
              data-tooltip={`Add ${candidate.name} as a favorite`}
            >
              <FaHeart />
            </LoveButton>
          </CandidateCard>
        ))}
      </CandidatesContainer>
      <AnimatePresence>
        {videoPopup && (
          <VideoPopup
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            <iframe
              width="100%"
              height="360"
              src={currentVideoUrl}
              title="Candidate Intro Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <CloseButton onClick={closeVideoPopup}>
              <FaTimes />
            </CloseButton>
          </VideoPopup>
        )}
      </AnimatePresence>
    </>
  );
};

const CandidatesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px 0;
`;

const CandidateCard = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  border: 3px solid ${themeColor};
`;

const CandidateInfo = styled.div`
  text-align: center;
  color: ${secondaryColor};
`;

const CandidateName = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  margin: 5px 0;
`;

const CandidateDescription = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
`;

const Experience = styled.p`
  font-size: 0.9rem;
  margin: 5px 0;
  font-weight: bold;
`;

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const Badge = styled.span`
  background-color: ${themeColor};
  color: #fff;
  border-radius: 12px;
  padding: 3px 8px;
  margin: 2px;
  font-size: 0.7rem;
`;

const ActionButton = styled(motion.button)`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: ${themeColor};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    margin-right: 5px;
  }
`;

const LoveButton = styled(motion.button)`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  background-color: transparent;
  color: #e74c3c;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
`;

const VideoPopup = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1100;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

// MessagingPopup component
const MessagingPopup = ({ onClose, selectedUser }) => {
  const [messageText, setMessageText] = useState('');

  const handleMessageChange = e => setMessageText(e.target.value);

  const handleSendMessage = async () => {
    if (!messageText.trim()) {
      console.error("Cannot send an empty message.");
      return;
    }
    try {
      const user = auth.currentUser;
      const conversationId = [user.email, selectedUser.email].sort().join('_');
      const conversationRef = doc(db, `messages/${conversationId}`);
      await setDoc(conversationRef, {
        participants: [user.email, selectedUser.email]
      });
      await addDoc(collection(conversationRef, 'messages'), {
        senderId: user.uid,
        receiverId: selectedUser.id,
        senderEmail: user.email,
        receiverEmail: selectedUser.email,
        text: messageText,
        timestamp: serverTimestamp()
      });
      console.log(`Message sent to ${selectedUser?.name}: "${messageText}"`);
      onClose();
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  return (
    <ModalOverlay
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ModalContent
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -50, opacity: 0 }}
      >
        <ModalHeader>
          <h2>Message {selectedUser?.name}</h2>
          <CloseButton onClick={onClose}>
            <FaTimes />
          </CloseButton>
        </ModalHeader>
        <MessageTextarea
          value={messageText}
          onChange={handleMessageChange}
          placeholder="Type your message here..."
          rows="5"
        />
        <SendButton 
          onClick={handleSendMessage}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Send Message
        </SendButton>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
    color: ${secondaryColor};
  }
`;

const MessageTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;
  margin-bottom: 20px;
`;

const SendButton = styled(motion.button)`
  padding: 10px 20px;
  background-color: ${themeColor};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
`;

export default ClientDashboard;