import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'shards-react';
import { FiX } from 'react-icons/fi';
import { FaBriefcase, FaChartLine, FaCode, FaUserCog, FaIndustry, FaMedkit, FaUniversity, FaMoneyBill, FaBuilding, FaUsers, FaHeadset, FaLaptop, FaShippingFast, FaHotel, FaChalkboardTeacher } from 'react-icons/fa';
import styled from 'styled-components';

const EditJobPopup = ({ isOpen, onClose, job, onUpdate }) => {
    const [updatedJobDetails, setUpdatedJobDetails] = useState({
        ...job,
        skills: Array.isArray(job.skills) ? job.skills : [],
        questions: Array.isArray(job.questions) ? job.questions : []
    });
    const [logoPreview, setLogoPreview] = useState(job.logo || '');

    const categories = [
        'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
        'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
        'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
        'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
    ];

    const categoryIcons = {
        'Marketing': <FaChartLine />,
        'Fullstack Engineer': <FaCode />,
        'Data Science': <FaChartLine />,
        'Product Management': <FaUserCog />,
        'Graphic Design': <FaIndustry />,
        'UI/UX Design': <FaLaptop />,
        'Software Development': <FaCode />,
        'Project Management': <FaUsers />,
        'Sales': <FaMoneyBill />,
        'Customer Support': <FaHeadset />,
        'Human Resources': <FaUsers />,
        'Finance': <FaMoneyBill />,
        'Legal': <FaUniversity />,
        'Education': <FaChalkboardTeacher />,
        'Healthcare': <FaMedkit />,
        'Real Estate': <FaBuilding />,
        'Logistics': <FaShippingFast />,
        'Manufacturing': <FaIndustry />,
        'Hospitality': <FaHotel />,
        'Other': <FaBriefcase />
    };

    const skillsByCategory = {
        "Marketing": [
            { value: "SEO", label: "SEO" }, { value: "Digital Advertising", label: "Digital Advertising" }, { value: "Content Strategy", label: "Content Strategy" }, { value: "Social Media", label: "Social Media" }, { value: "Email Marketing", label: "Email Marketing" },
            { value: "Brand Management", label: "Brand Management" }, { value: "Analytics", label: "Analytics" }, { value: "PPC", label: "PPC" }, { value: "Content Creation", label: "Content Creation" }, { value: "Video Marketing", label: "Video Marketing" },
            { value: "Market Research", label: "Market Research" }, { value: "Consumer Behavior", label: "Consumer Behavior" }, { value: "Strategic Planning", label: "Strategic Planning" }, { value: "CRM", label: "CRM" }, { value: "Public Relations", label: "Public Relations" },
            { value: "Event Planning", label: "Event Planning" }, { value: "Copywriting", label: "Copywriting" }, { value: "Influencer Marketing", label: "Influencer Marketing" }, { value: "Affiliate Marketing", label: "Affiliate Marketing" }, { value: "Graphic Design", label: "Graphic Design" }
        ],
        "Fullstack Engineer": [
            { value: "JavaScript", label: "JavaScript" }, { value: "React", label: "React" }, { value: "Node.js", label: "Node.js" }, { value: "SQL", label: "SQL" }, { value: "NoSQL", label: "NoSQL" },
            { value: "CSS", label: "CSS" }, { value: "HTML", label: "HTML" }, { value: "Angular", label: "Angular" }, { value: "Vue.js", label: "Vue.js" }, { value: "Python", label: "Python" },
            { value: "PHP", label: "PHP" }, { value: "Ruby on Rails", label: "Ruby on Rails" }, { value: "Java", label: "Java" }, { value: "C#", label: "C#" }, { value: ".NET", label: ".NET" },
            { value: "TypeScript", label: "TypeScript" }, { value: "API Development", label: "API Development" }, { value: "Microservices", label: "Microservices" }, { value: "AWS", label: "AWS" }, { value: "Docker", label: "Docker" }
        ],
        "Data Science": [
            { value: "Python", label: "Python" }, { value: "R", label: "R" }, { value: "SQL", label: "SQL" }, { value: "Machine Learning", label: "Machine Learning" }, { value: "Deep Learning", label: "Deep Learning" },
            { value: "Data Visualization", label: "Data Visualization" }, { value: "Big Data", label: "Big Data" }, { value: "Statistics", label: "Statistics" }, { value: "Data Mining", label: "Data Mining" }, { value: "Data Wrangling", label: "Data Wrangling" },
            { value: "AI", label: "AI" }, { value: "Neural Networks", label: "Neural Networks" }, { value: "Predictive Modeling", label: "Predictive Modeling" }, { value: "NLP", label: "NLP" }, { value: "Computer Vision", label: "Computer Vision" },
            { value: "Bayesian Methods", label: "Bayesian Methods" }, { value: "Regression Analysis", label: "Regression Analysis" }, { value: "Decision Trees", label: "Decision Trees" }, { value: "Cluster Analysis", label: "Cluster Analysis" }, { value: "Data Integration", label: "Data Integration" }
        ],
        // Add other categories and their skills here
    };

    useEffect(() => {
        if (job) {
            setUpdatedJobDetails({
                ...job,
                skills: Array.isArray(job.skills) ? job.skills : [],
                questions: Array.isArray(job.questions) ? job.questions : []
            });
            setLogoPreview(job.logo || '');
        }
    }, [job]);

    const handleInputChange = (e, field) => {
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            [field]: e.target.value
        }));
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
                setUpdatedJobDetails(prevState => ({
                    ...prevState,
                    logo: reader.result
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            category: selectedCategory,
            skills: []
        }));
    };

    const handleSkillsSelect = (e) => {
        const skillValue = e.target.value;
        const skill = skillsByCategory[updatedJobDetails.category]?.find(skill => skill.value === skillValue);
        if (skill && !updatedJobDetails.skills.includes(skill)) {
            setUpdatedJobDetails(prevState => ({
                ...prevState,
                skills: [...prevState.skills, skill]
            }));
        }
    };

    const removeSkill = (skillToRemove) => {
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            skills: prevState.skills.filter(skill => skill.value !== skillToRemove)
        }));
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = [...updatedJobDetails.questions];
        updatedQuestions[index] = value;
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            questions: updatedQuestions
        }));
    };

    const addQuestion = () => {
        if (updatedJobDetails.questions.length < 10) {
            setUpdatedJobDetails(prevState => ({
                ...prevState,
                questions: [...prevState.questions, '']
            }));
        }
    };

    const removeQuestion = (index) => {
        const updatedQuestions = updatedJobDetails.questions.filter((_, i) => i !== index);
        setUpdatedJobDetails(prevState => ({
            ...prevState,
            questions: updatedQuestions
        }));
    };

    const handleUpdateJob = async () => {
        try {
            const jobRef = doc(db, 'jobs', job.id);
            await updateDoc(jobRef, updatedJobDetails);
            onUpdate(); // Update the job list
            onClose(); // Close the edit job window
        } catch (error) {
            console.error('Error updating job:', error);
        }
    };

    return (
        <StyledModal open={isOpen} toggle={onClose}>
            <StyledModalHeader>Edit Job</StyledModalHeader>
            <StyledModalBody>
                <form onSubmit={(e) => e.preventDefault()}>
                    <FormGroup>
                        <Label htmlFor="title">Title</Label>
                        <Input type="text" id="title" value={updatedJobDetails.title || ''} onChange={(e) => handleInputChange(e, 'title')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="address">Address</Label>
                        <Input type="text" id="address" value={updatedJobDetails.address || ''} onChange={(e) => handleInputChange(e, 'address')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="category">Category</Label>
                        <CategorySelect id="category" value={updatedJobDetails.category || ''} onChange={handleCategoryChange}>
                            <option value="" disabled>Select a category</option>
                            {categories.map(category => (
                                <option key={category} value={category}>
                                    {categoryIcons[category]} {category}
                                </option>
                            ))}
                        </CategorySelect>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="companyName">Company Name</Label>
                        <Input type="text" id="companyName" value={updatedJobDetails.companyName || ''} onChange={(e) => handleInputChange(e, 'companyName')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="country">Country</Label>
                        <Input type="text" id="country" value={updatedJobDetails.country || ''} onChange={(e) => handleInputChange(e, 'country')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="description">Description</Label>
                        <Textarea id="description" value={updatedJobDetails.description || ''} onChange={(e) => handleInputChange(e, 'description')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="experience">Experience</Label>
                        <Input type="text" id="experience" value={updatedJobDetails.experience || ''} onChange={(e) => handleInputChange(e, 'experience')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="expiryDate">Expiry Date</Label>
                        <Input type="date" id="expiryDate" value={updatedJobDetails.expiryDate || ''} onChange={(e) => handleInputChange(e, 'expiryDate')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="industry">Industry</Label>
                        <Input type="text" id="industry" value={updatedJobDetails.industry || ''} onChange={(e) => handleInputChange(e, 'industry')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="logo">Logo</Label>
                        <Input type="file" id="logo" onChange={handleLogoChange} />
                        {logoPreview && <LogoPreview src={logoPreview} alt="Logo Preview" />}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="maxSalary">Max Salary</Label>
                        <Input type="text" id="maxSalary" value={updatedJobDetails.maxSalary || ''} onChange={(e) => handleInputChange(e, 'maxSalary')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="minSalary">Min Salary</Label>
                        <Input type="text" id="minSalary" value={updatedJobDetails.minSalary || ''} onChange={(e) => handleInputChange(e, 'minSalary')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="qualifications">Qualifications</Label>
                        <Input type="text" id="qualifications" value={updatedJobDetails.qualifications || ''} onChange={(e) => handleInputChange(e, 'qualifications')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="questions">Questions</Label>
                        {updatedJobDetails.questions?.map((question, index) => (
                            <QuestionGroup key={index}>
                                <Input
                                    type="text"
                                    value={question}
                                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                                />
                                <RemoveButton onClick={() => removeQuestion(index)}>
                                    <FiX />
                                </RemoveButton>
                            </QuestionGroup>
                        ))}
                        {updatedJobDetails.questions?.length < 10 && (
                            <AddButton onClick={addQuestion}>Add Question</AddButton>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="salaryType">Salary Type</Label>
                        <Input type="text" id="salaryType" value={updatedJobDetails.salaryType || ''} onChange={(e) => handleInputChange(e, 'salaryType')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="skills">Skills</Label>
                        <Select value="" onChange={handleSkillsSelect}>
                            <option value="" disabled>Select a skill</option>
                            {skillsByCategory[updatedJobDetails.category]?.map(skill => (
                                <option key={skill.value} value={skill.value}>{skill.label}</option>
                            ))}
                        </Select>
                        <SkillsContainer>
                            {updatedJobDetails.skills?.map(skill => (
                                <SkillTag key={skill.value}>
                                    {skill.label}
                                    <RemoveSkillButton onClick={() => removeSkill(skill.value)}>
                                        <FiX />
                                    </RemoveSkillButton>
                                </SkillTag>
                            ))}
                        </SkillsContainer>
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="state">State</Label>
                        <Input type="text" id="state" value={updatedJobDetails.state || ''} onChange={(e) => handleInputChange(e, 'state')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="type">Type</Label>
                        <Input type="text" id="type" value={updatedJobDetails.type || ''} onChange={(e) => handleInputChange(e, 'type')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="userEmail">User Email</Label>
                        <Input type="text" id="userEmail" value={updatedJobDetails.userEmail || ''} onChange={(e) => handleInputChange(e, 'userEmail')} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="website">Website</Label>
                        <Input type="text" id="website" value={updatedJobDetails.website || ''} onChange={(e) => handleInputChange(e, 'website')} />
                    </FormGroup>
                </form>
            </StyledModalBody>
            <StyledModalFooter>
                <StyledButton onClick={handleUpdateJob}>Update Job</StyledButton>
                <CancelButton onClick={onClose}>Cancel</CancelButton>
            </StyledModalFooter>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
  max-width: 40%;
  width: 40%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const StyledModalHeader = styled(ModalHeader)`
  background-color: #0a58ca;
  color: white;
  padding: 15px;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const StyledModalBody = styled(ModalBody)`
  max-height: 50vh;
  overflow-y: auto;
  padding: 15px;
`;

const StyledModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
`;

const FormGroup = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
  font-size: 0.9rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0.9rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0.9rem;
`;

const CategorySelect = styled(Select)`
  display: flex;
  align-items: center;
  padding-left: 2rem; /* Adjust padding to account for icon space */
  background-repeat: no-repeat;
  background-position: 8px center;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 0.9rem;
`;

const LogoPreview = styled.img`
  margin-top: 10px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const QuestionGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const AddButton = styled(Button)`
  margin-top: 10px;
  background-color: #0a58ca;
  border: none;
  &:hover {
    background-color: #0846a0;
  }
`;

const RemoveButton = styled(Button)`
  margin-left: 10px;
  background-color: #dc3545;
  border: none;
  &:hover {
    background-color: #c82333;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const SkillTag = styled.span`
  display: inline-flex;
  align-items: center;
  background-color: #e9ecef;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
`;

const RemoveSkillButton = styled.button`
  background: none;
  border: none;
  margin-left: 5px;
  color: #dc3545;
  cursor: pointer;
  font-size: 1rem;
`;

const StyledButton = styled(Button)`
  padding: 8px 15px;
  background-color: #0a58ca;
  border: none;
  font-size: 0.9rem;
  &:hover {
    background-color: #0846a0;
  }
`;

const CancelButton = styled(Button)`
  padding: 8px 15px;
  background-color: #6c757d;
  border: none;
  font-size: 0.9rem;
  &:hover {
    background-color: #5a6268;
  }
`;

export default EditJobPopup;
