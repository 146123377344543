import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faDollarSign, faMapMarkerAlt, faGlobe, faArrowLeft, faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { auth, db } from '../firebase';
import ExpertNavbar from './expertnavbar';
import styled from 'styled-components';
import { setDoc } from 'firebase/firestore';
import heroImage from '../assets/images/company/2.jpg';

const HeroSection = styled.div`
  background-image: url(${heroImage});
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0,0,0,0.12);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: default;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 12px rgba(0,0,0,0.16);
  }
`;

const JobImage = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
  border-radius: 8px;
  object-fit: contain;
`;

const JobDetails = styled.div`
  flex: 1;
`;

const JobTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const JobDescription = styled.p`
  margin: 5px 0;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const JobInfo = styled.p`
  margin: 5px 0;
  color: #555;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const ExpiryDate = styled.p`
  margin: 5px 0;
  color: ${(props) => (props.isExpired ? 'red' : '#555')};
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  margin: 5px;
  background-color: ${(props) => (props.primary ? '#007bff' : 'gray')};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  &:hover {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#333')};
  }
`;

const BackButton = styled(Button)`
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin: 50px 0;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: red;
  font-size: 18px;
  margin: 50px 0;
`;

const JobInfoItem = ({ icon, text }) => (
  <JobInfo>
    <FontAwesomeIcon icon={icon} />
    {text}
  </JobInfo>
);

const JobExpiryDate = ({ date }) => {
  const isExpired = new Date(date) < new Date();
  return (
    <ExpiryDate isExpired={isExpired}>
      <FontAwesomeIcon icon={faCalendarTimes} />
      {isExpired ? 'Expired' : `Expires on: ${new Date(date).toLocaleDateString()}`}
    </ExpiryDate>
  );
};

function MyJobs() {
  const [user] = useAuthState(auth);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchApplications() {
      if (user) {
        try {
          const applicationsQuery = query(
            collection(db, 'jobApplications'),
            where('email', '==', user.email)
          );
          const querySnapshot = await getDocs(applicationsQuery);
          const fetchedApplications = await Promise.all(
            querySnapshot.docs.map(async (applicationDoc) => {
              const applicationData = applicationDoc.data();
              const jobDoc = await getDoc(doc(db, 'jobs', applicationData.jobID));
              return { id: applicationDoc.id, ...jobDoc.data() };
            })
          );
          setApplications(fetchedApplications);
        } catch (error) {
          setError('Failed to load job applications. Please try again later.');
          console.error("Error fetching applications:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }

    fetchApplications();
  }, [user]);

  const applyToJob = (job) => {
    navigate('/job-apply', { state: { jobDetails: job } });
  };

  const saveJob = async (jobId) => {
    if (!user) {
      alert("Please log in to save jobs.");
      return;
    }
    const userDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
    try {
      await setDoc(userDocRef, { jobId });
      alert("Job saved successfully.");
    } catch (error) {
      console.error("Error saving job:", error);
      alert("Failed to save job.");
    }
  };

  const backToDashboard = () => {
    navigate('/expertdashboard');
  };

  return (
    <>
      <ExpertNavbar />
      <HeroSection>
        <HeroTitle>My Applied Jobs</HeroTitle>
      </HeroSection>
      <Container>
        <BackButton onClick={backToDashboard}>
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
          Back to Expert Dashboard
        </BackButton>
        <h2 style={{ fontSize: '24px', textAlign: 'center', margin: '30px 0' }}>My Applied Jobs</h2>
        {loading && <LoadingMessage>Loading your job applications...</LoadingMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {!loading && !error && (
          <CardGrid>
            {applications.length > 0 ? (
              applications.map((job) => (
                <Card key={job.id}>
                  <JobImage src={job.logo || 'https://via.placeholder.com/150'} alt="Company Logo" />
                  <JobDetails>
                    <JobTitle>{job.title}</JobTitle>
                    <JobInfoItem icon={faClock} text={`${job.experience} years`} />
                    <JobInfoItem icon={faBriefcase} text={job.type} />
                    <JobInfoItem icon={faDollarSign} text={`${job.minSalary} - ${job.maxSalary} ${job.salaryType}`} />
                    <JobInfoItem icon={faMapMarkerAlt} text={`${job.address}, ${job.state}, ${job.country}`} />
                    <JobInfoItem icon={faGlobe} text={job.industry} />
                    <JobExpiryDate date={job.expiryDate} />
                    <JobDescription>{job.description}</JobDescription>
                  </JobDetails>
                  <ButtonContainer>
                    <Button primary onClick={(e) => { e.stopPropagation(); applyToJob(job); }}>Apply Now</Button>
                  </ButtonContainer>
                </Card>
              ))
            ) : (
              <div style={{ textAlign: 'center' }}>You haven't applied to any jobs yet.</div>
            )}
          </CardGrid>
        )}
      </Container>
    </>
  );
}

export default MyJobs;
