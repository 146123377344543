import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import ExpertNavbar from './expertnavbar';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
`;

const Header = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  margin: 10px 0;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
`;

const ProfileDetails = styled.div`
  line-height: 1.5;
`;

const MessageBox = styled.div`
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  color: white;
  background-color: ${props => props.type === 'error' ? '#dc3545' : '#28a745'};
`;


function AccountSettings() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const user = auth.currentUser;

  const [profile, setProfile] = useState({
    email: '',
    profileImage: '',
    title: ''
  });
  const [passwordInput, setPasswordInput] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    if (!user) {
      setMessageType('error');
      setMessage('No user logged in.');
      return;
    }

    const fetchUserProfile = async () => {
      const collections = ['profiles', 'members', 'users']; // Example collections to search for the profile
      let profileFound = false;

      for (const collectionName of collections) {
        if (profileFound) break; // If a profile is found, break out of the loop

        const colRef = collection(db, collectionName);
        const q = query(colRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              setProfile({
                email: doc.data().email,
                profileImage: doc.data().profileImage || 'https://via.placeholder.com/100',
                title: doc.data().title || 'Not provided'
              });
              profileFound = true;
            }
          });
        }
      }

      if (!profileFound) {
        setMessageType('error');
        setMessage('Profile not found in any collections.');
      }
    };

    fetchUserProfile();
  }, [user, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordInput(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (passwordInput.newPassword !== passwordInput.confirmNewPassword) {
      setMessageType('error');
      setMessage('New passwords do not match.');
      return;
    }
    const credential = EmailAuthProvider.credential(user.email, passwordInput.currentPassword);
    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwordInput.newPassword);
      setMessageType('success');
      setMessage('Password updated successfully!');
    } catch (error) {
      setMessageType('error');
      setMessage(error.message);
    }
  };

  const handleDeleteProfile = async () => {
    if (window.confirm('Are you sure you want to delete your profile? This cannot be undone.')) {
      // Placeholder for actual deletion logic
      setMessageType('success');
      setMessage('Profile deleted successfully!');
    }
  };

  return (
    <>
      <ExpertNavbar/>
      <Container>
        {message && <MessageBox type={messageType}>{message}</MessageBox>}
        <ProfileInfo>
          <ProfileImage src={profile.profileImage} alt="Profile" />
          <ProfileDetails>
            <div><strong>Name:</strong> {profile.name || 'Not provided'}</div>
            <div><strong>Email:</strong> {profile.email}</div>
            <div><strong>Title:</strong> {profile.title}</div>
          </ProfileDetails>
        </ProfileInfo>
        <Header>Account Settings</Header>
        <form onSubmit={handleUpdatePassword}>
          <FormGroup>
            <Label htmlFor="currentPassword">Current Password *</Label>
            <Input
              type="password"
              id="currentPassword"
              name="currentPassword"
              value={passwordInput.currentPassword}
              onChange={handleInputChange}
              required
            />
            <Label htmlFor="newPassword">New Password *</Label>
            <Input
              type="password"
              id="newPassword"
              name="newPassword"
              value={passwordInput.newPassword}
              onChange={handleInputChange}
              required
            />
            <Label htmlFor="confirmNewPassword">Confirm New Password *</Label>
            <Input
              type="password"
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={passwordInput.confirmNewPassword}
              onChange={handleInputChange}
              required
            />
            <Button type="submit">Update Password</Button>
          </FormGroup>
        </form>
        <Button onClick={handleDeleteProfile} style={{ backgroundColor: '#dc3545' }}>
          Delete My Profile
        </Button>
        <Button onClick={() => navigate('/expertdashboard')} style={{ backgroundColor: '#007bff', marginTop: '20px', borderRadius: '20px' }}>
          Back to Dashboard
        </Button>
      </Container>
    </>
  );
}

export default AccountSettings;
