import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs, onSnapshot, documentId, deleteDoc } from 'firebase/firestore';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faEye, faLayerGroup, faIndustry, faQuestionCircle,  faHeart, faMapMarkerAlt, faBuilding, faMoneyBill, faUserGraduate, faGlobe, faFlag, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import ExpertNavbar from './expertnavbar';

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const JobList = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const JobDetails = styled.div`
  flex: 2;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  display: ${props => props.show ? 'block' : 'none'};
`;

const Header = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2rem;
`;

const ClearButton = styled.button`
  padding: 8px 16px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #c0392b;
  }
`;

const JobCard = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,0.2);
  }
`;

const JobHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #f8f9fa;
`;

const JobImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: contain;
  margin-right: 20px;
  background: white;
`;

const JobTitleContainer = styled.div`
  flex: 1;
`;

const JobTitle = styled.h3`
  color: #333;
  font-size: 18px;
  margin: 0;
`;

const JobCompany = styled.p`
  color: #6c757d;
  margin: 5px 0 0;
  font-size: 14px;
`;

const JobInfo = styled.div`
  padding: 15px;
  text-align: left;
  color: #6c757d;
`;

const JobDetail = styled.p`
  margin: 5px 0;
  font-size: 14px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
    color: #007bff;
  }
`;

const JobDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #6c757d;
`;

const JobActions = styled.div`
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
`;

const JobAction = styled.button`
  background: white;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #007bff;
    color: white;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

function SavedJobs() {
  const [savedJobs, setSavedJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (!user) {
      setSavedJobs([]);
      return;
    }
    const savedJobsRef = collection(db, "users", user.uid, "savedJobs");
    const unsubscribe = onSnapshot(savedJobsRef, async (querySnapshot) => {
      const jobIds = querySnapshot.docs.map(doc => doc.id);
      if (jobIds.length > 0) {
        const jobsQuery = query(collection(db, "jobs"), where(documentId(), 'in', jobIds));
        const jobsSnapshot = await getDocs(jobsQuery);
        const jobs = jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSavedJobs(jobs);
        setSelectedJob(jobs[0]); // Automatically select the first job
      } else {
        setSavedJobs([]);
      }
    });
    return () => unsubscribe();
  }, [user]);

  const clearSavedJobs = async () => {
    if (user) {
      const savedJobsRef = collection(db, "users", user.uid, "savedJobs");
      const querySnapshot = await getDocs(savedJobsRef);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      setSavedJobs([]);
    }
  };

  const viewJobDetails = job => {
    setSelectedJob(job);
  };

  const applyForJob = job => {
    navigate('/job-apply', { state: { jobDetails: job } });
  };

  return (
    <>
      <ExpertNavbar />
      <Container>
        <MainContent>
          <JobList>
            <Header>Saved Jobs</Header>
            <ClearButton onClick={clearSavedJobs}>
              Clear Saved Jobs
            </ClearButton>
            {savedJobs.length > 0 ? savedJobs.map(job => (
              <JobCard key={job.id} onClick={() => viewJobDetails(job)}>
                <JobHeader>
                  <JobImage src={job.logo || 'https://via.placeholder.com/60'} alt="Company Logo" />
                  <JobTitleContainer>
                    <JobTitle>{job.title}</JobTitle>
                    <JobCompany>{job.companyName}</JobCompany>
                  </JobTitleContainer>
                </JobHeader>
                <JobInfo>
                  <JobDetail><FontAwesomeIcon icon={faClock} /> {job.experience} years</JobDetail>
                  <JobDetail><FontAwesomeIcon icon={faBriefcase} /> {job.type}</JobDetail>
                  <JobDetail><FontAwesomeIcon icon={faMapMarkerAlt} /> {job.address}</JobDetail>
                  <JobDetail><FontAwesomeIcon icon={faBuilding} /> {job.companyName}</JobDetail>
                  <JobDetail><FontAwesomeIcon icon={faGlobe} /> {job.country}</JobDetail>
                  <JobDescription>{job.description}</JobDescription>
                </JobInfo>
              </JobCard>
            )) : (
              <p>No saved jobs found.</p>
            )}
          </JobList>
          {selectedJob && (
            <JobDetails show={selectedJob !== null}>
              <img src={selectedJob.logo} alt="Company Logo" style={{ width: '100px', height: '100px', margin: '20px auto' }} />
              <h2 style={{ color: '#333', fontSize: '1.5rem' }}>{selectedJob.title} - {selectedJob.companyName}</h2>
              <p><FontAwesomeIcon icon={faBriefcase} /> Category: {selectedJob.category}</p>
              <p><FontAwesomeIcon icon={faBuilding} /> Company: {selectedJob.companyName}</p>
              <p><FontAwesomeIcon icon={faFlag} /> Country: {selectedJob.country}</p>
              <p><FontAwesomeIcon icon={faGlobe} /> Website: <a href={selectedJob.website} target="_blank" rel="noopener noreferrer">{selectedJob.website}</a></p>
              <p><FontAwesomeIcon icon={faIndustry} /> Industry: {selectedJob.industry}</p>
              <p><FontAwesomeIcon icon={faLayerGroup} /> Skills: {Array.isArray(selectedJob.skills) ? selectedJob.skills.join(', ') : selectedJob.skills || 'N/A'}</p>
              <p><FontAwesomeIcon icon={faUserGraduate} /> Qualifications: {Array.isArray(selectedJob.qualifications) ? selectedJob.qualifications.join(', ') : selectedJob.qualifications || 'N/A'}</p>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> Expiry Date: {selectedJob.expiryDate}</p>
              <p><FontAwesomeIcon icon={faClock} /> Experience: {selectedJob.experience} years</p>
              <p><FontAwesomeIcon icon={faMoneyBill} /> Salary Range: ${selectedJob.minSalary} - ${selectedJob.maxSalary} {selectedJob.salaryType}</p>
              <p><FontAwesomeIcon icon={faQuestionCircle} /> Questions: {selectedJob.questions || 'N/A'}</p>
              <p><FontAwesomeIcon icon={faMapMarkerAlt} /> State: {selectedJob.state}</p>
              <p><FontAwesomeIcon icon={faBriefcase} /> Type: {selectedJob.type}</p>
              <p>{selectedJob.description}</p>
              <ModalActions>
                <JobAction onClick={() => applyForJob(selectedJob)}>Apply Now</JobAction>
                <JobAction onClick={() => viewJobDetails(selectedJob)}>Save Job</JobAction>
              </ModalActions>
            </JobDetails>
          )}
        </MainContent>
      </Container>
    </>
  );
}

export default SavedJobs;
