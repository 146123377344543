import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import logoDark from "../assets/images/logo-dark.png";
import logoWhite from "../assets/images/logo-white.png";
import logoLight from "../assets/images/logo-light.png";

export default function Navbar({ navClass, navLight }) {
    const [isOpen, setMenu] = useState(false); // Menu is closed by default
    const [scroll, setScroll] = useState(false); // Scroll state for sticky navigation
    const [manu, setManu] = useState(''); // Manu state to manage active menu item based on route
    const location = useLocation();

    useEffect(() => {
        const current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
        setManu(current); // Setting the current menu based on route change
    }, [location.pathname]);

    useEffect(() => {
        const scrollHandler = () => {
            setScroll(window.scrollY > 50); // Set scroll state based on scroll position for sticky nav
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler); // Clean up the scroll event listener
        };
    }, []);

    const toggleMenu = () => {
        setMenu(!isOpen); // Toggle menu state
    };

    return (
        <header id="topnav" className={`${scroll ? 'nav-sticky' : ''} ${navClass}`}>
            <div className="container">
                <Link className="logo" to="/">
                    <span className="logo-light-mode" style={{ borderRadius: '50%', height: '40px' }}>
                        <img src={navLight ? logoDark : logoWhite} className="l-dark" alt="logo" style={{ borderRadius: '50%', height: '40px' }}/>
                        <img src={logoLight} className="l-light" alt="logo" />
                    </span>
                    <img src={navLight ? logoLight : logoWhite} className="logo-dark-mode" alt="logo" style={{ borderRadius: '50%', height: '40px' }} />
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <button className="navbar-toggle" id="isToggle" onClick={toggleMenu} style={{ background: 'none', border: 'none', padding: '0' }}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>

                <div id="navigation" style={{ display: isOpen ? 'block' : 'none' }}> {/* Conditional rendering of menu based on isOpen */}
                    <ul className="navigation-menu nav-right nav-light">
                        <li className={`${["", "index", "index-two", "index-three"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/index">Home</Link>
                        </li>
                        <li className={`${["job-categories", "vacancies", "job-grid-two", "job-grid-three", "job-grid-four", "job-list-one", "job-list-two", "job-detail-one", "job-detail-two", "job-detail-three", "job-apply", "job-post", "career"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/vacancies">Jobs</Link>
                        </li>
                        <li className={`${["aboutus", "services", "pricing", "helpcenter-overview", "helpcenter-faqs", "helpcenter-guides", 'helpcenter-support', "blogs", "blog-sidebar", "blog-detail", "login", "signup", "reset-password", "applicants", "terms", "privacy"].includes(manu) ? "active" : ""} has-submenu parent-menu-item`}>
                            <Link to="/aboutus">About</Link>
                        </li>
                        <li className={manu === "contactus" ? "active" : ""}>
                            <Link to="/contactus" className="sub-menu-item">Contact Us</Link>
                        </li>
                        <li className={manu === "login" ? "active" : ""}>
                            <Link to="/clientlogin" className="sub-menu-item">Login as a Client</Link>
                        </li>
                        <li>
                            <button className="btn btn-sm btn-icon btn-pills btn-primary" style={{ backgroundColor: 'white', width: 'auto', marginTop: '16px' }}>
                                <Link to="/expertlogin" className="text-decoration-none text-dark" style={{ padding: '8px 20px' }}>Login as an Expert</Link>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}
