import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebase';
import Navbar from '../components/navbar';
import Blog from '../components/blog';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { FiBookmark } from 'react-icons/fi';
import { 
  BsBriefcase, BsCalendar, BsGeoAlt, BsPeople, BsFillPersonFill, 
  BsSearch, BsBuilding, BsFileEarmarkText, BsGraphUp, BsMegaphone,
  BsClock, BsClipboardCheck, BsChatDots, BsLightning, BsStars,
  BsLinkedin, BsGithub, BsTwitter
} from 'react-icons/bs';

const mainColor = '#4F46E5';
const secondaryColor = '#8B5CF6';

const categories = [
  'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
  'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
  'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
  'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
];

const CareerPlatform = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [currentCandidateIndex, setCurrentCandidateIndex] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [salaryFilter, setSalaryFilter] = useState('');
  const [experienceFilter, setExperienceFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSection, setActiveSection] = useState('home');
  const jobsPerPage = 6;
  const [user] = useAuthState(auth);
  const db = getFirestore();

  useEffect(() => {
    const fetchJobs = async () => {
      const jobsCollection = collection(db, 'jobs');
      const jobSnapshot = await getDocs(jobsCollection);
      const jobList = jobSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(job => job.logo && Object.keys(job).length > 4)
        .sort((a, b) => Object.keys(b).length - Object.keys(a).length);
      setJobs(jobList);
      setFilteredJobs(jobList);
    };

    const fetchCandidates = async () => {
      const candidatesCollection = collection(db, 'profiles');
      const candidateSnapshot = await getDocs(candidatesCollection);
      const candidateList = candidateSnapshot.docs.map(doc => {
        const profile = doc.data();
        return {
          id: doc.id,
          name: profile.name,
          currentJobTitle: profile.currentJobTitle,
          imageUrl: profile.profileImage || user?.photoURL || 'path/to/default/profile.png',
          email: profile.email,
          workExperience: profile.workExperience,
          previousCompany: profile.previousCompany || 'Top Company',
        };
      });
      setCandidates(candidateList);
    };

    fetchJobs();
    fetchCandidates();
  }, [user]);

  useEffect(() => {
    if (candidates.length > 0) {
      const interval = setInterval(() => {
        setCurrentCandidateIndex(prevIndex => (prevIndex + 1) % candidates.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [candidates.length]);

  const applyForJob = job => {
    navigate('/vacancies');
  };

  const saveJob = jobId => {
    console.log(`Job ${jobId} saved`);
  };

  const truncateDescription = (description, maxLength) => {
    if (!description) return '';
    if (description.length <= maxLength) return description;
    return description.slice(0, maxLength) + '...';
  };

  const handleFilterChange = () => {
    let filtered = jobs;
    if (categoryFilter) filtered = filtered.filter(job => job.category === categoryFilter);
    if (locationFilter) filtered = filtered.filter(job => job.location === locationFilter);
    if (typeFilter) filtered = filtered.filter(job => job.type === typeFilter);
    if (salaryFilter) filtered = filtered.filter(job => job.salaryRange === salaryFilter);
    if (experienceFilter) filtered = filtered.filter(job => job.experienceRange === experienceFilter);
    setFilteredJobs(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [categoryFilter, locationFilter, typeFilter, salaryFilter, experienceFilter]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    setActiveSection(sectionId);
  };

  const handleCategoryClick = (category) => {
    setCategoryFilter(category);
    scrollToSection('jobs-section');
  };

  const Feature = ({ icon, title, description }) => (
    <div style={{
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      padding: '24px',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      }
    }}>
      <div style={{ fontSize: '48px', color: mainColor, marginBottom: '16px' }}>{icon}</div>
      <h3 style={{ fontSize: '22px', fontWeight: 'bold', marginBottom: '12px', color: '#1a202c' }}>{title}</h3>
      <p style={{ color: '#4a5568', lineHeight: '1.5' }}>{description}</p>
    </div>
  );

  const ExpertCard = ({ candidate }) => {
    if (!candidate) return null;

    return (
      <div style={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '20px',
        backdropFilter: 'blur(10px)',
        padding: '32px',
        color: 'white',
        textAlign: 'center',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-10px)',
          boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
        }
      }}>
        <img 
          src={candidate.imageUrl} 
          alt={candidate.name}
          style={{
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            border: '4px solid white',
            margin: '0 auto 20px',
            objectFit: 'cover',
          }}
        />
        <h3 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '8px' }}>{candidate.name}</h3>
        <p style={{ fontSize: '18px', color: 'rgba(255, 255, 255, 0.8)', marginBottom: '16px' }}>{candidate.currentJobTitle}</p>
        <p style={{ fontSize: '16px', color: 'rgba(255, 255, 255, 0.7)', marginBottom: '24px' }}>
          Previously at <strong>{candidate.previousCompany}</strong>
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginBottom: '24px' }}>
          <BsLinkedin size={24} />
          <BsGithub size={24} />
          <BsTwitter size={24} />
        </div>
        <button 
          onClick={() => navigate('/roleform')}
          style={{
            padding: '12px 24px',
            backgroundColor: 'white',
            color: mainColor,
            borderRadius: '9999px',
            fontWeight: 'bold',
            border: 'none',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: secondaryColor,
              color: 'white',
            }
          }}
        >
          View Profile
        </button>
      </div>
    );
  };

  const JobCard = ({ job }) => (
    <div style={{
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      }
    }}>
      <img src={job.logo} alt="Company Logo" style={{
        width: '64px',
        height: '64px',
        borderRadius: '50%',
        marginBottom: '16px',
        transition: 'transform 0.3s ease',
      }} />
      <h5 style={{ fontSize: '20px', fontWeight: 'bold', color: '#1a202c', marginBottom: '8px' }}>{job.title}</h5>
      <p style={{ color: '#4a5568', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <BsGeoAlt style={{ marginRight: '8px' }} /> {job.location}
      </p>
      <p style={{ color: '#4a5568', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <BsBriefcase style={{ marginRight: '8px' }} /> {job.type} - {job.experience}
      </p>
      <p style={{ color: '#4a5568', display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <BsCalendar style={{ marginRight: '8px' }} /> {job.postedDate}
      </p>
      <p style={{ color: '#2d3748', marginBottom: '16px' }}>{truncateDescription(job.description, 100)}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p style={{ fontSize: '20px', fontWeight: 'bold', color: mainColor }}>{job.salary}</p>
        <div style={{ display: 'flex', gap: '8px' }}>
          <button 
            onClick={() => applyForJob(job)}
            style={{
              padding: '8px 16px',
              backgroundColor: mainColor,
              color: 'white',
              borderRadius: '9999px',
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: secondaryColor,
              }
            }}
          >
            Apply Now
          </button>
          <button 
            onClick={() => saveJob(job.id)}
            style={{
              padding: '8px',
              backgroundColor: 'white',
              color: mainColor,
              borderRadius: '50%',
              border: `1px solid ${mainColor}`,
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: mainColor,
                color: 'white',
              }
            }}
          >
            <FiBookmark />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ backgroundColor: '#f7f7f7' }}>
      <Navbar navClass="defaultscroll sticky" navLight={true} />
      
      {/* Hero Section with Expert Showcase */}
      <section id="home" style={{
        background: `linear-gradient(135deg, ${mainColor}, ${secondaryColor})`,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '64px 32px',
        color: 'white',
        position: 'relative',
        overflow: 'hidden',
      }}>
        <div style={{ 
          maxWidth: '1200px', 
          margin: '0 auto',
          textAlign: 'center',
          zIndex: 1,
        }}>
          <h1 style={{
            fontSize: '48px',
            fontWeight: 'bold',
            marginBottom: '24px',
            lineHeight: '1.2',
          }}>
            Unlock Your Career Potential
          </h1>
          <p style={{
            fontSize: '20px',
            marginBottom: '32px',
            maxWidth: '600px',
            margin: '0 auto',
          }}>
            Find your next job, post openings, and build your dream career – all in one place.
          </p>
          <button 
            onClick={() => scrollToSection('find-job')}
            style={{
              padding: '16px 32px',
              backgroundColor: 'white',
              color: mainColor,
              borderRadius: '9999px',
              fontSize: '18px',
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              display: 'inline-flex',
              alignItems: 'center',
              marginBottom: '64px',
              '&:hover': {
                backgroundColor: secondaryColor,
                color: 'white',
              }
            }}
          >
            <BsPeople style={{ marginRight: '8px' }} />
            Get Started
          </button>

          {/* Expert Showcase */}
       
            <h2 style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '32px' }}>
            Meet Our Expert Professionals
          </h2>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '32px',
            flexWrap: 'wrap',
          }}>
            {candidates.slice(0, 3).map((candidate, index) => (
              <ExpertCard key={index} candidate={candidate} />
            ))}
          </div>
        </div>

        {/* Animated background elements */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: 0,
        }}>
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              style={{
                position: 'absolute',
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: `${Math.random() * 100 + 50}px`,
                height: `${Math.random() * 100 + 50}px`,
                borderRadius: '50%',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                animation: `float ${Math.random() * 10 + 5}s infinite ease-in-out`,
              }}
            />
          ))}
        </div>
      </section>

      {/* Find Your Next Job Opportunity Section */}
      <section id="find-job" style={{ padding: '80px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Find Your Next Job Opportunity
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Browse through thousands of job listings tailored to your skills and interests. Filter by location, industry, company size, and more.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsSearch />}
              title="Diverse Industries"
              description="From tech and finance to healthcare and education, explore a wide range of industries to find your ideal career path."
            />
            <Feature 
              icon={<BsBuilding />}
              title="Targeted Search"
              description="Refine your search with keywords, job titles, and company names to uncover hidden gems that match your expertise."
            />
            <Feature 
              icon={<BsFileEarmarkText />}
              title="Personalized Recommendations"
              description="Get recommendations based on your skills, experience, and career aspirations. Let us guide you towards opportunities that align with your goals."
            />
            <Feature 
              icon={<BsGraphUp />}
              title="Job Alerts"
              description="Stay informed and never miss an opportunity. Receive instant alerts whenever a new job listing matches your saved criteria."
            />
          </div>
        </div>
      </section>

      {/* Categories Section */}
      <section style={{ padding: '80px 0', backgroundColor: '#f0f4f8' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Explore by Categories
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Discover the perfect role by browsing through our categories. Find the job that aligns with your skills and aspirations.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '24px' }}>
            {categories.map((category, index) => (
              <div
                key={index}
                onClick={() => handleCategoryClick(category)}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  padding: '24px',
                  textAlign: 'center',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
                  }
                }}
              >
                <h5 style={{ fontSize: '18px', fontWeight: 'bold', color: '#1a202c', marginBottom: '8px' }}>{category}</h5>
                <p style={{ color: '#4a5568' }}>
                  {`Explore ${jobs.filter(job => job.category === category).length} jobs`}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Job Listings Section */}
      <section id="jobs-section" style={{ padding: '80px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Featured Opportunities
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Browse through exclusive job listings from top companies worldwide. Where will your skills take you next?
          </p>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '16px', marginBottom: '48px' }}>
            {['Category', 'Location', 'Type', 'Salary', 'Experience'].map((filterType) => (
              <select 
                key={filterType}
                onChange={(e) => {
                  switch(filterType) {
                    case 'Category': setCategoryFilter(e.target.value); break;
                    case 'Location': setLocationFilter(e.target.value); break;
                    case 'Type': setTypeFilter(e.target.value); break;
                    case 'Salary': setSalaryFilter(e.target.value); break;
                    case 'Experience': setExperienceFilter(e.target.value); break;
                  }
                }}
                style={{
                  padding: '12px 24px',
                  borderRadius: '9999px',
                  border: '1px solid #e2e8f0',
                  backgroundColor: 'white',
                  fontSize: '16px',
                  color: '#4a5568',
                  cursor: 'pointer',
                  outline: 'none',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    borderColor: mainColor,
                  },
                  '&:focus': {
                    borderColor: mainColor,
                    boxShadow: `0 0 0 3px rgba(79, 70, 229, 0.1)`,
                  }
                }}
              >
                <option value="">All {filterType}s</option>
                {/* Add relevant options for each filter type */}
              </select>
            ))}
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '32px' }}>
            {filteredJobs.slice((currentPage - 1) * jobsPerPage, currentPage * jobsPerPage).map(job => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
            {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                style={{
                  padding: '8px 16px',
                  margin: '0 4px',
                  borderRadius: '9999px',
                  border: 'none',
                  backgroundColor: currentPage === index + 1 ? mainColor : 'white',
                  color: currentPage === index + 1 ? 'white' : mainColor,
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: currentPage === index + 1 ? mainColor : '#e2e8f0',
                  }
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </section>

      {/* Post Your Job Openings Section */}
      <section id="post-job" style={{ padding: '80px 0', backgroundColor: '#f0f4f8' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Post Your Job Openings
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Reach a vast pool of talented candidates eager to make a positive impact. Create eye-catching job postings that highlight your company's unique culture and benefits.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsPeople />}
              title="Targeted Audience"
              description="Reach the specific audience you're looking for by filtering by skills, experience, and location."
            />
            <Feature 
              icon={<BsLightning />}
              title="Simplified Posting"
              description="Create engaging job descriptions with ease, using our intuitive interface and templates to quickly and effectively communicate your needs."
            />
            <Feature 
              icon={<BsClipboardCheck />}
              title="Streamlined Applicant Tracking"
              description="Manage applications efficiently with our integrated applicant tracking system, allowing you to screen, filter, and interview candidates effortlessly."
            />
          </div>
        </div>
      </section>

      {/* Resume Builder Section */}
      <section id="resume-builder" style={{ padding: '80px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Build and Showcase Your Resume
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Craft a compelling resume that highlights your skills, experience, and achievements. Our resume builder provides customizable templates and guidance to help you create a professional and impressive document.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsFileEarmarkText />}
              title="Choose a Template"
              description="Select from a range of professionally designed resume templates that cater to different career fields and levels."
            />
            <Feature 
              icon={<BsPeople />}
              title="Add Your Information"
              description="Input your contact details, work experience, education, skills, and any relevant accomplishments to create a comprehensive profile."
            />
            <Feature 
              icon={<BsBriefcase />}
              title="Customize and Optimize"
              description="Tailor your resume to specific job applications by highlighting relevant skills and experiences, while maintaining a consistent and professional tone."
            />
            <Feature 
              icon={<BsGraphUp />}
              title="Preview and Download"
              description="Review and edit your resume to ensure it is free of errors and formatted flawlessly. Download in various formats (PDF, Word, etc.) for easy sharing."
            />
          </div>
        </div>
      </section>

      {/* Career Assessment Section */}
      <section id="career-assessment" style={{ padding: '80px 0', backgroundColor: '#f0f4f8' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Discover Your Dream Career Path
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Take our comprehensive career assessment to identify your strengths, interests, and values. Get personalized recommendations for career paths that align with your personality and aspirations.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsSearch />}
              title="Career Assessment"
              description="Gain insights into your skills, interests, and values to pinpoint the best career paths for you."
            />
            <Feature 
              icon={<BsBuilding />}
              title="Explore Industries"
              description="Discover diverse industries and learn about different career paths within them."
            />
            <Feature 
              icon={<BsGraphUp />}
              title="Set Goals"
              description="Define your short-term and long-term career goals and create a roadmap for achieving them."
            />
            <Feature 
              icon={<BsBriefcase />}
              title="Skill Development"
              description="Identify skills needed for your desired career path and pursue relevant training or education."
            />
          </div>
        </div>
      </section>

      {/* Networking Section */}
      <section id="networking" style={{ padding: '80px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
        <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Connect with Top Talent in Your Industry
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Build your professional network and connect with like-minded individuals in your industry. Network with potential employers, colleagues, and mentors to expand your opportunities and learn from others' experiences.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsPeople />}
              title="Networking Events"
              description="Connect with professionals at industry events and conferences, both virtually and in person."
            />
            <Feature 
              icon={<BsChatDots />}
              title="Industry Groups"
              description="Join online communities and forums focused on your field to engage in discussions, share knowledge, and build connections."
            />
            <Feature 
              icon={<BsBriefcase />}
              title="Mentorship Programs"
              description="Seek guidance and advice from experienced professionals through mentorship programs, fostering valuable relationships and career growth."
            />
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" style={{ padding: '80px 0', backgroundColor: '#f0f4f8' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px', textAlign: 'center' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', color: '#1a202c', marginBottom: '16px' }}>
            Pricing Less Than a Coffee Per Day
          </h2>
          <p style={{ fontSize: '18px', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Our affordable pricing plans offer a range of features tailored to your needs. Enjoy access to our comprehensive platform and tools for a price less than a daily coffee, making it a cost-effective investment in your career.
          </p>
          <button style={{
            padding: '16px 32px',
            backgroundColor: mainColor,
            color: 'white',
            borderRadius: '9999px',
            fontSize: '18px',
            fontWeight: 'bold',
            border: 'none',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: secondaryColor,
            }
          }}>
            See our pricing plans
          </button>
          <p style={{ fontSize: '16px', color: '#4a5568', marginTop: '24px' }}>
            We offer flexible monthly and annual plans, with options to suit your budget and specific requirements. Explore our plans and choose the one that best aligns with your needs.
          </p>
        </div>
      </section>

      {/* Hiring Process Section */}
      <section id="hiring-process" style={{ padding: '80px 0', backgroundColor: 'white' }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '0 32px' }}>
          <h2 style={{ fontSize: '36px', fontWeight: 'bold', textAlign: 'center', color: '#1a202c', marginBottom: '16px' }}>
            Streamlined Hiring Process
          </h2>
          <p style={{ fontSize: '18px', textAlign: 'center', color: '#4a5568', marginBottom: '48px', maxWidth: '800px', margin: '0 auto 48px' }}>
            Simplify and expedite your hiring process with our intuitive tools and features. Reduce time-to-hire by efficiently managing applications, scheduling interviews, and communicating with candidates.
          </p>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '32px' }}>
            <Feature 
              icon={<BsFileEarmarkText />}
              title="1. Post Job"
              description="Create a compelling job posting to attract qualified candidates."
            />
            <Feature 
              icon={<BsSearch />}
              title="2. Screen Applications"
              description="Review applications and filter candidates based on your criteria."
            />
            <Feature 
              icon={<BsCalendar />}
              title="3. Schedule Interviews"
              description="Coordinate interviews with shortlisted candidates and manage interview schedules."
            />
            <Feature 
              icon={<BsClipboardCheck />}
              title="4. Make Offer"
              description="Extend an offer to your preferred candidate and negotiate terms."
            />
            <Feature 
              icon={<BsPeople />}
              title="5. Onboarding"
              description="Streamline the onboarding process and welcome your new employee."
            />
          </div>
        </div>
      </section>

      {/* Blog Section */}
      <Blog />

      {/* Scroll to Top Button */}
      <ScrollTop />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CareerPlatform;