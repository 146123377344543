import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faBriefcase, faBuilding } from '@fortawesome/free-solid-svg-icons';

const ViewJobContainer = styled.div`
  background-color: #fff;
  color: #333;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
`;

const JobHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const JobLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin-right: 20px;
`;

const JobTitle = styled.h1`
  font-size: 24px;
  color: ${props => props.themeColor};
`;

const JobDetails = styled.div`
  line-height: 1.6;
  margin-bottom: 20px;
`;

const DetailRow = styled.p`
  font-size: 16px;
  margin: 5px 0;
  i {
    margin-right: 10px;
    color: ${props => props.themeColor};
  }
`;

const JobDescription = styled.div`
  white-space: pre-line;
`;

function ViewJob({ themeColor = 'rgb(10, 102, 194)' }) {
  const { state } = useLocation();
  const job = state?.job;

  if (!job) {
    return <div>Job details not available. Please select a job to view.</div>;
  }

  return (
    <ViewJobContainer>
      <JobHeader>
        <JobLogo src={job.logo} alt="Company Logo" />
        <JobTitle themeColor={themeColor}>{job.title}</JobTitle>
      </JobHeader>
      <JobDetails>
        <DetailRow themeColor={themeColor}>
          <FontAwesomeIcon icon={faBuilding} /> {job.company}
        </DetailRow>
        <DetailRow themeColor={themeColor}>
          <FontAwesomeIcon icon={faClock} /> {job.experience} years required
        </DetailRow>
        <DetailRow themeColor={themeColor}>
          <FontAwesomeIcon icon={faBriefcase} /> {job.type}
        </DetailRow>
      </JobDetails>
      <JobDescription>
        <h2>Description:</h2>
        {job.description}
      </JobDescription>
    </ViewJobContainer>
  );
}

export default ViewJob;
