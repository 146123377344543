import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, doc, setDoc, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { FiEdit2, FiPlusCircle, FiTag, FiImage, FiUser, FiMail, FiPhone, FiGlobe, FiUsers, FiMapPin, FiStar, FiHeart } from 'react-icons/fi';
import ClientNavbar from './clientnavbar';
import styled from 'styled-components';
import 'react-modal-video/scss/modal-video.scss';
import { ModalBody } from 'shards-react';
const employeeSizes = ["1-10", "11-50", "51-200", "201-500", "501-1000", "1001-5000", "5001-10000", "10000+"];

const countryData = [
  { name: 'United States', states: ['California', 'Texas', 'Florida', 'New York', 'Illinois', 'Pennsylvania', 'Ohio', 'Georgia', 'North Carolina', 'Michigan'] },
  { name: 'Canada', states: ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'] },
  { name: 'Australia', states: ['Australian Capital Territory', 'New South Wales', 'Northern Territory', 'Queensland', 'South Australia', 'Tasmania', 'Victoria', 'Western Australia'] },
  { name: 'India', states: ['Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'] },
  { name: 'United Kingdom', states: ['England', 'Northern Ireland', 'Scotland', 'Wales'] },
];

const CompanyInfoPage = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [step, setStep] = useState(1);
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    streetAddress: '',
    address: '',
    country: '',
    state: '',
    industry: '',
    logo: '',
    website: '',
    employeeSize: '',
    contactEmail: '',
    contactPhone: '',
    officePhotos: [],
  });
  const [logoFile, setLogoFile] = useState(null);
  const [photoFiles, setPhotoFiles] = useState([]);
  const [showProfile, setShowProfile] = useState(false);
  const [reviews, setReviews] = useState({});
  const [reviewText, setReviewText] = useState('');
  const [reviewRating, setReviewRating] = useState(0);
  const [followers, setFollowers] = useState({});
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchCompanyInfo(user.email);
        await fetchFollowersAndReviews();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchCompanyInfo = async (userEmail) => {
    const q = query(collection(db, 'companies'), where('userEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);
    const companyList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCompanies(companyList);
  };

  const fetchFollowersAndReviews = async () => {
    const followersSnapshot = await getDocs(collection(db, 'followers'));
    const followersData = {};
    followersSnapshot.forEach(doc => {
      const data = doc.data();
      if (!followersData[data.companyId]) {
        followersData[data.companyId] = [];
      }
      followersData[data.companyId].push(data.userEmail);
    });
    setFollowers(followersData);

    const reviewsSnapshot = await getDocs(collection(db, 'reviews'));
    const reviewsData = {};
    reviewsSnapshot.forEach(doc => {
      const data = doc.data();
      if (!reviewsData[data.companyId]) {
        reviewsData[data.companyId] = [];
      }
      reviewsData[data.companyId].push({ id: doc.id, ...data });
    });
    setReviews(reviewsData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogoFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setCompanyDetails({ ...companyDetails, logo: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);
    setPhotoFiles(files);
    const readers = files.map(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader;
    });

    Promise.all(readers.map(reader => {
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    })).then(results => {
      setCompanyDetails({ ...companyDetails, officePhotos: results });
    });
  };

  const handleEdit = (company) => {
    setSelectedCompany(company);
    setCompanyDetails(company);
    setIsEditing(true);
    setStep(1);
  };

  const handleAddNew = () => {
    setCompanyDetails({
      companyName: '',
      streetAddress: '',
      address: '',
      country: '',
      state: '',
      industry: '',
      logo: '',
      website: '',
      employeeSize: '',
      contactEmail: '',
      contactPhone: '',
      officePhotos: [],
    });
    setIsAdding(true);
    setStep(1);
  };

  const handleSave = async () => {
    if (isEditing) {
      await setDoc(doc(db, 'companies', selectedCompany.id), { ...companyDetails, userEmail: auth.currentUser.email });
      sendNotification('Company information updated successfully.');
    } else if (isAdding) {
      await addDoc(collection(db, 'companies'), { ...companyDetails, userEmail: auth.currentUser.email });
      sendNotification('New company added successfully.');
    }
    await fetchCompanyInfo(auth.currentUser.email);
    setIsEditing(false);
    setIsAdding(false);
    setSelectedCompany(null);
  };

  const sendNotification = async (message) => {
    await addDoc(collection(db, 'notifications'), {
      email: auth.currentUser.email,
      message: message,
      read: false,
      timestamp: serverTimestamp(),
      userId: auth.currentUser.uid
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsAdding(false);
    setSelectedCompany(null);
    setStep(1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const renderStates = () => {
    const selectedCountry = countryData.find(country => country.name === companyDetails.country);
    if (selectedCountry) {
      return selectedCountry.states.map(state => (
        <option key={state} value={state}>{state}</option>
      ));
    }
    return [];
  };

  const handleViewProfile = async (company) => {
    setSelectedCompany(company);
    await fetchCompanyReviews(company.id);
    setShowProfile(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
    setSelectedCompany(null);
  };

  const fetchCompanyReviews = async (companyId) => {
    const q = query(collection(db, 'reviews'), where('companyId', '==', companyId));
    const querySnapshot = await getDocs(q);
    const reviewsList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setReviews(prev => ({ ...prev, [companyId]: reviewsList }));
  };

  const handleReviewSubmit = async () => {
    if (!reviewText.trim() || !reviewRating) {
      console.error("Review text and rating are required.");
      return;
    }

    try {
      await addDoc(collection(db, 'reviews'), {
        companyId: selectedCompany.id,
        userEmail: auth.currentUser.email,
        reviewText,
        rating: reviewRating,
        timestamp: serverTimestamp(),
      });
      setReviewText('');
      setReviewRating(0);
      await fetchCompanyReviews(selectedCompany.id);
      sendNotification('Review submitted successfully.');
    } catch (error) {
      console.error("Error submitting review: ", error);
    }
  };

  const getAverageRating = (companyId) => {
    const companyReviews = reviews[companyId] || [];
    if (companyReviews.length === 0) return 'No Ratings';
    const averageRating = (companyReviews.reduce((acc, review) => acc + (review.rating || 0), 0) / companyReviews.length).toFixed(1);
    return `${averageRating} Star Ratings`;
  };

  return (
    <>
      <ClientNavbar />
      {selectedCompany && showProfile && (
        <ModalBackdrop>
          <ModalContent>
            <ModalHeader>
              <h5 className="modal-title">{selectedCompany.companyName}</h5>
              <CloseButton onClick={handleCloseProfile}>&times;</CloseButton>
            </ModalHeader>
            <ScrollableModalBody>
              <LogoWrapper>
                <img src={selectedCompany.logo} alt="Company Logo" className="img-fluid rounded-circle shadow-sm" style={{ width: '100px', height: '100px' }} />
              </LogoWrapper>
              <InfoRow className="row text-center mb-4">
                <InfoColumn className="col-md-6">
                  <FiHeart className="text-danger me-2" />
                  <span className="badge bg-primary">{(followers[selectedCompany.id]?.length || 0)} Followers</span>
                </InfoColumn>
                <InfoColumn className="col-md-6">
                  <FiStar className="text-warning me-2" />
                  <span className="badge bg-success">{getAverageRating(selectedCompany.id)}</span>
                </InfoColumn>
              </InfoRow>
              <DetailsRow className="row">
                <DetailsColumn className="col-md-6">
                  <h6><FiMapPin className="me-2" style={{ color: '#0d6efd' }} />Street Address</h6>
                  <p>{selectedCompany.streetAddress}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiGlobe className="me-2" style={{ color: '#0d6efd' }} />Address</h6>
                  <p>{selectedCompany.address}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiGlobe className="me-2" style={{ color: '#0d6efd' }} />Country</h6>
                  <p>{selectedCompany.country}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiGlobe className="me-2" style={{ color: '#0d6efd' }} />State</h6>
                  <p>{selectedCompany.state}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiTag className="me-2" style={{ color: '#0d6efd' }} />Industry</h6>
                  <p>{selectedCompany.industry}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiUsers className="me-2" style={{ color: '#0d6efd' }} />Employee Size</h6>
                  <p>{selectedCompany.employeeSize}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiMail className="me-2" style={{ color: '#0d6efd' }} />Contact Email</h6>
                  <p>{selectedCompany.contactEmail}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiPhone className="me-2" style={{ color: '#0d6efd' }} />Contact Phone</h6>
                  <p>{selectedCompany.contactPhone}</p>
                </DetailsColumn>
                <DetailsColumn className="col-md-6">
                  <h6><FiGlobe className="me-2" style={{ color: '#0d6efd' }} />Website</h6>
                  <a href={selectedCompany.website} target="_blank" rel="noopener noreferrer">{selectedCompany.website}</a>
                </DetailsColumn>
                <DetailsColumn className="col-md-12 mt-4">
                  <h6><FiImage className="me-2" style={{ color: '#0d6efd' }} />Office Photos</h6>
                  <OfficePhotosRow className="row g-2">
                    {selectedCompany.officePhotos?.map((photo, index) => (
                      <OfficePhotoColumn className="col-md-3" key={index}>
                        <OfficePhoto
                          src={photo}
                          alt={`Office ${index + 1}`}
                          className="img-fluid rounded shadow-sm"
                        />
                      </OfficePhotoColumn>
                    ))}
                  </OfficePhotosRow>
                </DetailsColumn>
              </DetailsRow>
              <ReviewSection className="mt-4">
                <h5>Reviews</h5>
                <ReviewInputWrapper className="mb-3">
                  <textarea
                    className="form-control"
                    rows="3"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    placeholder="Write your review here..."
                  />
                  <ReviewSubmitRow className="d-flex justify-content-between align-items-center mt-2">
                    <StarRating>
                      {Array.from({ length: 5 }, (_, index) => (
                        <FiStar
                          key={index}
                          onClick={() => setReviewRating(index + 1)}
                          color={index < reviewRating ? '#ffc107' : '#e4e5e9'}
                          style={{ cursor: 'pointer', marginRight: '5px' }}
                        />
                      ))}
                    </StarRating>
                    <SubmitReviewButton className="btn btn-primary" onClick={handleReviewSubmit}>Submit Review</SubmitReviewButton>
                  </ReviewSubmitRow>
                </ReviewInputWrapper>
                <ReviewList className="list-group">
                  {reviews[selectedCompany.id]?.length === 0 ? (
                    <p>No Reviews</p>
                  ) : (
                    reviews[selectedCompany.id]?.map(review => (
                      <ReviewListItem key={review.id} className="list-group-item list-group-item-action">
                        <p className="mb-1">{review.reviewText}</p>
                        <ReviewMeta className="d-flex justify-content-between align-items-center">
                          <small className="text-muted">{review.userEmail}</small>
                          <StarRating>
                            {Array.from({ length: 5 }, (_, index) => (
                              <FiStar
                                key={index}
                                color={index < review.rating ? '#ffc107' : '#e4e5e9'}
                              />
                            ))}
                          </StarRating>
                        </ReviewMeta>
                      </ReviewListItem>
                    ))
                  )}
                </ReviewList>
              </ReviewSection>
            </ScrollableModalBody>
            <ModalFooter className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseProfile}>Close</button>
            </ModalFooter>
          </ModalContent>
        </ModalBackdrop>
      )}
      <Container className="container my-5">
        <HeaderRow className="row mb-4">
          <HeaderColumn className="col-12 d-flex justify-content-between align-items-center">
            <h2 className="fw-bold">My Companies</h2>
            <button className="btn btn-primary" onClick={handleAddNew}><FiPlusCircle className="me-2" />Add New Company</button>
            <button className="btn btn-secondary" onClick={() => navigate('/clientdashboard')}>Back to Client Dashboard</button>
          </HeaderColumn>
        </HeaderRow>
        {companies.length === 0 ? (
          <EmptyState className="text-center bg-light p-5 rounded shadow-sm">
            <p className="mb-4">You have no registered companies.</p>
            <button className="btn btn-primary" onClick={handleAddNew}><FiPlusCircle className="me-2" />Add Your First Company</button>
          </EmptyState>
        ) : (
          <CompanyGrid className="row g-4">
            {companies.map(company => (
              <CompanyColumn className="col-md-4" key={company.id}>
                <CompanyCard
                  className="card shadow-sm rounded-3"
                >
                  <CardBody className="card-body text-center">
                    <Logo src={company.logo || 'default_logo_placeholder.png'} alt="Company Logo" className="img-fluid rounded-circle mb-3" />
                    <h5 className="card-title">{company.companyName}</h5>
                    <p className="card-text">{company.industry}</p>
                    <p className="card-text">{company.country}</p>
                    <BadgeRow className="d-flex justify-content-center mb-2">
                      <FiHeart className="text-danger me-2" />
                      <span className="badge bg-primary">{(followers[company.id]?.length || 0)} Followers</span>
                      <FiStar className="text-warning ms-3 me-2" />
                      <span className="badge bg-success">{getAverageRating(company.id)}</span>
                    </BadgeRow>
                    <ButtonRow>
                      <ActionButton className="btn btn-outline-primary mt-2 me-2" onClick={() => handleEdit(company)}><FiEdit2 className="me-2" />Edit</ActionButton>
                      <ActionButton className="btn btn-outline-secondary mt-2" onClick={() => handleViewProfile(company)}>View Profile</ActionButton>
                    </ButtonRow>
                  </CardBody>
                </CompanyCard>
              </CompanyColumn>
            ))}
          </CompanyGrid>
        )}
        {(isEditing || isAdding) && (
          <ModalBackdrop>
            <ModalContent>
              <ModalHeader>
                <h5 className="modal-title">{isEditing ? 'Edit Company' : 'Add New Company'}</h5>
                <CloseButton onClick={handleCancel}>&times;</CloseButton>
              </ModalHeader>
              <ModalBody>
                <form onSubmit={(e) => e.preventDefault()}>
                  <ProgressBar className="progress mb-4">
                    <ProgressIndicator className="progress-bar" role="progressbar" style={{ width: `${step * 33.33}%` }}></ProgressIndicator>
                  </ProgressBar>
                  {step === 1 && (
                    <FormRow className="row g-3">
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiUser className="me-2" />Company Name</label>
                        <input type="text" className="form-control" name="companyName" value={companyDetails.companyName} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiMapPin className="me-2" />Street Address</label>
                        <input type="text" className="form-control" name="streetAddress" value={companyDetails.streetAddress} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-12">
                        <label className="form-label"><FiGlobe className="me-2" />Address</label>
                        <input type="text" className="form-control" name="address" value={companyDetails.address} onChange={handleInputChange} required />
                      </FormColumn>
                    </FormRow>
                  )}
                  {step === 2 && (
                    <FormRow className="row g-3">
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiGlobe className="me-2" />Country</label>
                        <select className="form-select" name="country" value={companyDetails.country} onChange={handleInputChange} required>
                          <option value="">Select country...</option>
                          {countryData.map(country => (
                            <option key={country.name} value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiGlobe className="me-2" />State</label>
                        <select className="form-select" name="state" value={companyDetails.state} onChange={handleInputChange} required>
                          <option value="">Select state...</option>
                          {renderStates()}
                        </select>
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiTag className="me-2" />Industry</label>
                        <input type="text" className="form-control" name="industry" value={companyDetails.industry} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiUsers className="me-2" />Employee Size</label>
                        <select className="form-select" name="employeeSize" value={companyDetails.employeeSize} onChange={handleInputChange} required>
                          <option value="">Select size...</option>
                          {employeeSizes.map(size => (
                            <option key={size} value={size}>{size}</option>
                          ))}
                        </select>
                      </FormColumn>
                    </FormRow>
                  )}
                  {step === 3 && (
                    <FormRow className="row g-3">
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiMail className="me-2" />Contact Email</label>
                        <input type="email" className="form-control" name="contactEmail" value={companyDetails.contactEmail} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiPhone className="me-2" />Contact Phone</label>
                        <input type="tel" className="form-control" name="contactPhone" value={companyDetails.contactPhone} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiGlobe className="me-2" />Website</label>
                        <input type="text" className="form-control" name="website" value={companyDetails.website} onChange={handleInputChange} required />
                      </FormColumn>
                      <FormColumn className="col-md-6">
                        <label className="form-label"><FiImage className="me-2" />Logo</label>
                        <input type="file" className="form-control" onChange={handleLogoChange} />
                        {companyDetails.logo && <img src={companyDetails.logo} alt="Logo Preview" className="img-fluid rounded-circle mt-3" style={{ width: '80px', height: '80px' }} />}
                      </FormColumn>
                      <FormColumn className="col-md-12">
                        <label className="form-label"><FiImage className="me-2" />Office Photos</label>
                        <input type="file" className="form-control" multiple onChange={handlePhotoChange} />
                        <OfficePhotosRow className="row g-2 mt-3">
                          {companyDetails.officePhotos.map((photo, index) => (
                            <OfficePhotoColumn className="col-md-3" key={index}>
                              <OfficePhoto
                                src={photo}
                                alt={`Office ${index + 1}`}
                                className="img-fluid rounded shadow-sm"
                              />
                            </OfficePhotoColumn>
                          ))}
                        </OfficePhotosRow>
                      </FormColumn>
                    </FormRow>
                  )}
                  <FormActions className="d-flex justify-content-between mt-4">
                    {step > 1 && <button type="button" className="btn btn-secondary" onClick={prevStep}>Back</button>}
                    {step < 3 && <button type="button" className="btn btn-primary" onClick={nextStep}>Next</button>}
                    {step === 3 && <button type="button" className="btn btn-success" onClick={handleSave}>{isEditing ? 'Save Changes' : 'Add Company'}</button>}
                    <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancel</button>
                  </FormActions>
                </form>
              </ModalBody>
            </ModalContent>
          </ModalBackdrop>
        )}
      </Container>
    </>
  );
};

export default CompanyInfoPage;

const Container = styled.div`
  padding: 20px;
  font-family: 'Helvetica Neue', Arial, sans-serif;
`;

const HeaderRow = styled.div`
  margin-bottom: 20px;
`;

const HeaderColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyState = styled.div`
  text-align: center;
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const CompanyGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const CompanyColumn = styled.div`
  flex: 1 1 30%;
`;

const CompanyCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
`;

const CardBody = styled.div`
  text-align: center;
  padding: 20px;
`;

const Logo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const BadgeRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
`;

const ModalBackdrop = styled.div`
  display: block;
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 80%;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
`;

const CloseButton = styled.span`
  font-size: 24px;
  cursor: pointer;
`;

const ScrollableModalBody = styled.div`
  overflow-y: auto;
  margin-top: 20px;
  flex-grow: 1;
`;

const ModalFooter = styled.div`
  margin-top: 20px;
  text-align: right;
  flex-shrink: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const InfoColumn = styled.div`
  flex: 1;
  text-align: center;
`;

const DetailsRow = styled.div`
  margin-bottom: 20px;
`;

const DetailsColumn = styled.div`
  margin-bottom: 10px;
`;

const OfficePhotosRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const OfficePhotoColumn = styled.div`
  flex: 1 1 25%;
`;

const OfficePhoto = styled.img`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  }
`;

const ReviewSection = styled.div`
  margin-top: 20px;
`;

const ReviewInputWrapper = styled.div`
  margin-bottom: 20px;
`;

const ReviewSubmitRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StarRating = styled.div`
  display: flex;
`;

const SubmitReviewButton = styled.button`
  background-color: #0d6efd;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #0056b3;
  }
`;

const ReviewList = styled.div`
  margin-top: 20px;
`;

const ReviewListItem = styled.div`
  padding: 10px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ReviewMeta = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormRow = styled.div`
  margin-bottom: 20px;
`;

const FormColumn = styled.div`
  margin-bottom: 10px;
`;

const ProgressBar = styled.div`
  margin-bottom: 20px;
`;

const ProgressIndicator = styled.div`
  width: 100%;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
