import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import NavbarDark from '../components/navbarDark';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this import is correctly configured
import hiringImage from '../assets/images/4153553.jpg';

const CompanySizeForm = () => {
    const [companySize, setCompanySize] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            if (companySize) {
                const firestore = getFirestore();
                await addDoc(collection(firestore, 'submissions'), {
                    companySize: companySize,
                    timestamp: new Date()
                });
                console.log('Company size submitted successfully:', companySize);
                navigate('/bookacall');
            } else {
                console.log('Please select company size');
            }
        } catch (error) {
            console.error('Error submitting company size:', error.message);
            alert('An error occurred. Please check the console for details and try again');
        }
    };

    return (
        <>
            <NavbarDark />

            <div className='text-top' style={{  width: '50vh', marginLeft: '20vh', marginTop: '17vh', textAlign: 'center' }}>
             
            </div>

            <div className="container">
                <div className="row">
               
                    <div className="col-md-6" style={{marginTop: '17vh'}} >
                    <span style={{backgroundColor: 'lightgray', marginTop: '5vh'}}>How many people are employed at your company?</span>
                        <div className="mb-3">
                            <input type="radio" id="lessThan10" name="companySize" value="Less than 10" onChange={() => setCompanySize('Less than 10')} />
                            <label htmlFor="lessThan10">Less than 10</label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="11-50" name="companySize" value="11 - 50" onChange={() => setCompanySize('11 - 50')} />
                            <label htmlFor="11-50">11 - 50</label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="51-100" name="companySize" value="51 - 100" onChange={() => setCompanySize('51 - 100')} />
                            <label htmlFor="51-100">51 - 100</label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="101-500" name="companySize" value="101 - 500" onChange={() => setCompanySize('101 - 500')} />
                            <label htmlFor="101-500">101 - 500</label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="501-1000" name="companySize" value="501 - 1000" onChange={() => setCompanySize('501 - 1000')} />
                            <label htmlFor="501-1000">501 - 1000</label>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="1000plus" name="companySize" value="1000+" onChange={() => setCompanySize('1000+')} />
                            <label htmlFor="1000plus">1000+</label>
                        </div>
                        <div className="mb-3">
                            <button type="button" className="btn btn-primary" style={{ backgroundColor: '#0a2351' }} onClick={handleSubmit}>Next</button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={hiringImage} alt="Your image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompanySizeForm;
