
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import NavbarDark from '../components/navbarDark';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure this import is correctly configured
import bookingImage from '../assets/images/4153553.jpg';

const BookACall = () => {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            if (email && companyName && contactName && contactNo) {
                const firestore = getFirestore();
                await addDoc(collection(firestore, 'submissions'), { // Change collection name to 'submissions'
                    email: email,
                    companyName: companyName,
                    contactName: contactName,
                    contactNo: contactNo,
                    timestamp: new Date()
                });
                console.log('Booking details submitted successfully');
                // Redirect or display success message
                navigate('/');
            } else {
                console.log('Please fill in all fields');
            }
        } catch (error) {
            console.error('Error submitting booking details:', error.message);
            alert('An error occurred. Please check the console for details and try again');
        }
    };
    
    return (
        <>
            <NavbarDark />

            <div className='text-top' style={{ backgroundColor: '#0a2351', color: 'white', width: '50vh', marginLeft: '20vh', marginTop: '17vh', textAlign: 'center', padding: '20px', borderRadius: '10px' }}>
                <h3>Grow your team with the best Experts.</h3>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <form>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="email" id="email" name="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="companyName" className="form-label">Company Name</label>
                                <input type="text" id="companyName" name="companyName" className="form-control" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contactName" className="form-label">Contact Name</label>
                                <input type="text" id="contactName" name="contactName" className="form-control" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contactNo" className="form-label">Contact No</label>
                                <input type="tel" id="contactNo" name="contactNo" className="form-control" value={contactNo} onChange={(e) => setContactNo(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <button type="button" className="btn btn-primary" style={{ backgroundColor: '#0a2351' }} onClick={handleSubmit}>Submit. We'll get back to you soon</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <img src={bookingImage} alt="Your image" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookACall;
