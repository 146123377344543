import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, setDoc, getDoc, getDocs, collection, query, where, limit, startAfter, updateDoc } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock, faBriefcase, faHeart, faBrain, faMapMarkedAlt, faDollarSign,
  faCalendarAlt, faGraduationCap, faGlobe, faIndustry, faQuestionCircle,
  faLayerGroup, faBuilding, faFlag, faTimes, faThumbsUp, faList,
  faSearch, faFilter, faChevronDown, faChevronUp, faStar, faUser,
  faChartBar, faLightbulb, faRocket
} from '@fortawesome/free-solid-svg-icons';
import ExpertSidebar from './expertsidebar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import ExpertNavbar from './expertnavbar';
import { auth, db } from '../firebase';
import toast from 'react-hot-toast';
import {
  ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar
} from 'recharts';

const themeColor = '#007bff';
const secondaryColor = '#6c757d';
const successColor = '#28a745';
const warningColor = '#ffc107';
const dangerColor = '#dc3545';

const styles = {
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#f8f9fa',
  },
  content: {
    flex: 1,
    padding: '2rem',
    overflowY: 'auto',
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: themeColor,
    marginBottom: '1.5rem',
    textAlign: 'center',
    textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
  },
  statsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '1.5rem',
    marginBottom: '2rem',
  },
  statCard: {
    backgroundColor: 'white',
    borderRadius: '1rem',
    padding: '1.5rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
  },
  statIcon: {
    fontSize: '2.5rem',
    marginRight: '1rem',
    color: themeColor,
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: themeColor,
  },
  statLabel: {
    fontSize: '1rem',
    color: secondaryColor,
    marginTop: '0.5rem',
  },
  sectionContainer: {
    backgroundColor: 'white',
    borderRadius: '1rem',
    padding: '2rem',
    marginBottom: '2rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  sectionTitle: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    color: themeColor,
    marginBottom: '1.5rem',
    borderBottom: `2px solid ${themeColor}`,
    paddingBottom: '0.5rem',
  },
  jobListingsContainer: {
    backgroundColor: 'white',
    borderRadius: '1rem',
    padding: '2rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1.5rem',
    flexWrap: 'wrap',
  },
  searchInput: {
    flex: 1,
    padding: '0.75rem',
    borderRadius: '0.5rem',
    border: '1px solid #e2e8f0',
    marginRight: '1rem',
    fontSize: '1rem',
    transition: 'border-color 0.3s ease-in-out',
  },
  select: {
    padding: '0.75rem',
    borderRadius: '0.5rem',
    border: '1px solid #e2e8f0',
    fontSize: '1rem',
    backgroundColor: 'white',
    transition: 'border-color 0.3s ease-in-out',
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: '1.5rem',
    backgroundColor: '#e2e8f0',
    borderRadius: '0.5rem',
    padding: '0.25rem',
  },
  button: {
    padding: '0.75rem 1rem',
    borderRadius: '0.25rem',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
    flex: 1,
    textAlign: 'center',
    cursor: 'pointer',
  },
  activeButton: {
    backgroundColor: themeColor,
    color: 'white',
  },
  inactiveButton: {
    backgroundColor: 'transparent',
    color: secondaryColor,
  },
  jobsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '1.5rem',
  },
  loadMoreButton: {
    backgroundColor: themeColor,
    color: 'white',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginTop: '1.5rem',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '1rem',
    padding: '2rem',
    maxWidth: '600px',
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
};

const ExpertDashboard = () => {
  const [user, loading] = useAuthState(auth);
  const [jobs, setJobs] = useState([]);
  const [recommendedJobs, setRecommendedJobs] = useState([]);
  const [aiRecommendedJobs, setAIRecommendedJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterIndustry, setFilterIndustry] = useState('');
  const [showRecommended, setShowRecommended] = useState(false);
  const [showAIRecommended, setShowAIRecommended] = useState(false);
  const [personalityReport, setPersonalityReport] = useState(null);
  const [personalityType, setPersonalityType] = useState('');
  const [personalityScores, setPersonalityScores] = useState(null);
  const [jobStats, setJobStats] = useState({ available: 0, recommended: 0, aiRecommended: 0 });
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserProfile(user.uid);
    }
  }, [user]);

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [jobs, recommendedJobs, aiRecommendedJobs, searchTerm, filterIndustry, showRecommended, showAIRecommended]);

  const fetchUserProfile = async (uid) => {
    try {
      const userProfileDoc = await getDoc(doc(db, 'profiles', uid));
      if (userProfileDoc.exists()) {
        const userData = userProfileDoc.data();
        if (userData?.personalityReport) {
          setPersonalityReport(userData.personalityReport);
          setPersonalityType(userData.personalityType);
        }
        if (userData?.personalityScores) {
          setPersonalityScores(userData.personalityScores);
        }
        if (userData?.industry) {
          fetchRecommendedJobs(userData.industry);
          fetchAIRecommendedJobs(userData);
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsQuery = query(collection(db, "jobs"), limit(10));
      const querySnapshot = await getDocs(jobsQuery);
      const jobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setJobs(jobsData);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      if (jobsData.length > 0) {
        setSelectedJob(jobsData[0]);
      }
      setJobStats(prevStats => ({ ...prevStats, available: jobsData.length }));
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const fetchMoreJobs = async () => {
    if (lastVisible && !loading) {
      try {
        const moreJobsQuery = query(collection(db, "jobs"), startAfter(lastVisible), limit(10));
        const querySnapshot = await getDocs(moreJobsQuery);
        const moreJobsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setJobs(prevJobs => [...prevJobs, ...moreJobsData]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } catch (error) {
        console.error("Error fetching more jobs:", error);
      }
    }
  };

  const fetchRecommendedJobs = async (industry) => {
    try {
      const similarIndustries = getSimilarIndustries(industry);
      const recommendedJobsQuery = query(collection(db, 'jobs'), where('industry', 'in', similarIndustries), limit(10));
      const recommendedJobsSnapshot = await getDocs(recommendedJobsQuery);
      const recommendedJobsData = recommendedJobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRecommendedJobs(recommendedJobsData);
      setJobStats(prevStats => ({ ...prevStats, recommended: recommendedJobsData.length }));
    } catch (error) {
      console.error("Error fetching recommended jobs:", error);
    }
  };


  const getSimilarIndustries = (industry) => {
    const industryMap = {
      "IT": ["IT", "Software Development", "Fullstack Development", "Web Development"],
      "Software Development": ["Software Development", "IT", "Fullstack Development", "Web Development"],
      "Fullstack Development": ["Fullstack Development", "IT", "Software Development", "Web Development"],
      "Marketing": ["Marketing", "Sales", "Advertising"],
      "Finance": ["Finance", "Accounting", "Banking"]
    };
    return industryMap[industry] || [industry];
  };

  const applyFilters = () => {
    const selectedJobs = showAIRecommended
      ? aiRecommendedJobs
      : showRecommended
      ? recommendedJobs
      : jobs;

    const filtered = selectedJobs.filter((job) => {
      const jobTitle = job.title ? job.title.toLowerCase() : '';
      const industry = job.industry ? job.industry.toLowerCase() : '';
      return (
        jobTitle.includes(searchTerm.toLowerCase()) &&
        (!filterIndustry || industry === filterIndustry.toLowerCase())
      );
    });

    setFilteredJobs(filtered);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleIndustryChange = (e) => {
    setFilterIndustry(e.target.value);
  };

  const saveJob = async (jobId) => {
    if (user) {
      try {
        const jobDocRef = doc(db, "users", user.uid, "savedJobs", jobId);
        await setDoc(jobDocRef, { jobId });
        toast.success("Job saved successfully!");
      } catch (error) {
        console.error("Error saving job:", error);
        toast.error("Failed to save job. Please try again.");
      }
    }
  };

  const applyForJob = async (job) => {
    try {
      await updateDoc(doc(db, "jobs", job.id), {
        applicants: job.applicants ? job.applicants + 1 : 1
      });
      navigate('/job-apply', { state: { jobDetails: job } });
    } catch (error) {
      console.error("Error applying for job:", error);
      toast.error("Failed to apply for job. Please try again.");
    }
  };

  // OpenAI API key placeholder
  const OPENAI_API_KEY = 'sk-None-cKKUvLMKNBQp0IlnfCr2T3BlbkFJ3VME98HSdkFPkoSMkDy7';

  const fetchAIRecommendedJobs = async (userProfile) => {
    try {
      const allJobsSnapshot = await getDocs(collection(db, 'jobs'));
      const jobsData = allJobsSnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        description: doc.data().description,
        industry: doc.data().industry
      }));

      const prompt = `
        Based on the user profile and available jobs, recommend the top 5 most suitable jobs.
        Consider the user's skills, experience, industry preferences, and personality type (${personalityType}).

        User Profile:
        ${JSON.stringify(userProfile)}

        Available Jobs:
        ${JSON.stringify(jobsData)}

        Provide the recommendations in the following format:
        1. [Job ID]: [Reason for recommendation]
        2. [Job ID]: [Reason for recommendation]
        ...
      `;

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are an AI assistant specialized in job recommendations."
            },
            {
              role: "user",
              content: prompt
            }
          ],
          max_tokens: 500,
          n: 1,
          stop: null,
          temperature: 0.7,
        }),
      });

      const data = await response.json();
      const result = data.choices[0].message.content.trim();
      const recommendedJobIds = result.split('\n').map(line => {
        const match = line.match(/^\d+\.\s*(\w+):/);
        return match ? match[1] : null;
      }).filter(id => id !== null);

      const aiRecommendedJobsData = jobsData.filter(job => recommendedJobIds.includes(job.id));
      setAIRecommendedJobs(aiRecommendedJobsData);
      setJobStats(prevStats => ({ ...prevStats, aiRecommended: aiRecommendedJobsData.length }));
    } catch (error) {
      console.error("Error fetching AI recommended jobs:", error);
      setAIRecommendedJobs([]);
    }
  };

  const JobCard = ({ job }) => (
    <motion.div
      layoutId={job.id}
      onClick={() => setSelectedJob(job)}
      style={{
        backgroundColor: 'white',
        borderRadius: '1rem',
        padding: '1.5rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      }}
      whileHover={{
        boxShadow: '0 10px 15px rgba(0, 0, 0, 0.2)',
        transform: 'translateY(-5px)',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <img src={job.logo || 'https://via.placeholder.com/50'} alt={job.companyName} style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '1rem', objectFit: 'cover' }} />
        <div>
          <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', color: themeColor, marginBottom: '0.25rem' }}>{job.title}</h3>
          <p style={{ fontSize: '0.875rem', color: secondaryColor }}>{job.companyName}</p>
        </div>
      </div>
      <div style={{ fontSize: '0.875rem', color: '#4a5568', marginBottom: '0.5rem' }}>
        <p><FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '0.5rem', color: successColor }} />{job.type}</p>
        <p><FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '0.5rem', color: warningColor }} />{job.location}</p>
        <p><FontAwesomeIcon icon={faDollarSign} style={{ marginRight: '0.5rem', color: dangerColor }} />{job.salary}</p>
      </div>
      <p style={{ fontSize: '0.875rem', color: secondaryColor, display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{job.description}</p>
    </motion.div>
  );

  const JobDetails = ({ job, onClose }) => (
    <div style={styles.modal}>
      <motion.div
        layoutId={job.id}
        style={styles.modalContent}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
          <h2 style={{ fontSize: '1.75rem', fontWeight: 'bold', color: themeColor }}>{job.title}</h2>
          <button onClick={onClose} style={{ background: 'none', border: 'none', fontSize: '1.5rem', cursor: 'pointer' }}>×</button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
          <img src={job.logo || 'https://via.placeholder.com/60'} alt={job.companyName} style={{ width: '60px', height: '60px', borderRadius: '50%', marginRight: '1.5rem', objectFit: 'cover' }} />
          <div>
            <p style={{ fontSize: '1.25rem', color: secondaryColor }}>{job.companyName}</p>
            <p style={{ fontSize: '1rem', color: '#4a5568' }}>{job.industry}</p>
          </div>
        </div>
        <div style={{ marginBottom: '1.5rem', fontSize: '1rem', color: '#4a5568' }}>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '0.5rem', color: successColor }} />{job.type}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faMapMarkedAlt} style={{ marginRight: '0.5rem', color: warningColor }} />{job.location}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faDollarSign} style={{ marginRight: '0.5rem', color: dangerColor }} />{job.salary}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem', color: themeColor }} />Posted: {job.postedDate}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faGraduationCap} style={{ marginRight: '0.5rem', color: successColor }} />Qualifications: {job.qualifications}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faGlobe} style={{ marginRight: '0.5rem', color: warningColor }} />Website: {job.website}</p>
          <p style={{ marginBottom: '0.5rem' }}><FontAwesomeIcon icon={faUser} style={{ marginRight: '0.5rem', color: dangerColor }} />Applicants: {job.applicants}</p>
        </div>
        <p style={{ marginBottom: '2rem', fontSize: '1rem', color: '#4a5568', lineHeight: 1.6 }}>{job.description}</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => saveJob(job.id)}
            style={{
              backgroundColor: '#e2e8f0',
              color: themeColor,
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          >
            <FontAwesomeIcon icon={faHeart} style={{ marginRight: '0.5rem' }} />Save Job
          </button>
          <button
            onClick={() => applyForJob(job)}
            style={{
              backgroundColor: themeColor,
              color: 'white',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontWeight: 'bold',
              border: 'none',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
            }}
          >
            <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: '0.5rem' }} />Apply Now
          </button>
        </div>
      </motion.div>
    </div>
  );

  const PersonalitySection = () => (
    <div style={styles.sectionContainer}>
      <h2 style={styles.sectionTitle}>Your Personality Profile</h2>
      <div style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row' }}>
        <div style={{ flex: 1, marginBottom: isSmallScreen ? '1.5rem' : 0, marginRight: isSmallScreen ? 0 : '1.5rem' }}>
          <h3 style={{ fontSize: '1.5rem', fontWeight: 'semibold', marginBottom: '1rem', color: successColor }}>{user?.displayName}, your personality type is: {personalityType}</h3>
          <p style={{ fontSize: '1.1rem', color: '#4a5568', lineHeight: 1.6 }}>{personalityReport}</p>
        </div>
        <div style={{ flex: 1 }}>
          {personalityScores && (
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart outerRadius="80%" data={personalityScores}>
                <PolarGrid stroke={secondaryColor} />
                <PolarAngleAxis dataKey="trait" tick={{ fill: secondaryColor }} />
                <PolarRadiusAxis angle={30} domain={[0, 100]} tick={{ fill: secondaryColor }} />
                <Radar name="Personality Traits" dataKey="score" stroke={themeColor} fill={themeColor} fillOpacity={0.6} />
                <Tooltip />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );

  const JobStatsSection = () => {
    const data = [
      { name: 'Available', value: jobStats.available, color: themeColor },
      { name: 'Recommended', value: jobStats.recommended, color: successColor },
      { name: 'AI Recommended', value: jobStats.aiRecommended, color: warningColor },
    ];

    return (
      <div style={styles.sectionContainer}>
        <h2 style={styles.sectionTitle}>Job Statistics</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke={secondaryColor} />
            <XAxis dataKey="name" tick={{ fill: secondaryColor }} />
            <YAxis tick={{ fill: secondaryColor }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill={themeColor}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  return (
    <>
      <ExpertNavbar />
      <div style={styles.container}>
        <ExpertSidebar />
        <div style={styles.content}>
          <h1 style={styles.header}>Welcome back, {user?.displayName || 'Expert'}!</h1>
          
          <div style={styles.statsContainer}>
            <div style={styles.statCard}>
              <FontAwesomeIcon icon={faBriefcase} style={styles.statIcon} />
              <div>
                <p style={styles.statValue}>{jobStats.available}</p>
                <p style={styles.statLabel}>Available Jobs</p>
              </div>
            </div>
            <div style={styles.statCard}>
              <FontAwesomeIcon icon={faThumbsUp} style={styles.statIcon} />
              <div>
                <p style={styles.statValue}>{jobStats.recommended}</p>
                <p style={styles.statLabel}>Recommended Jobs</p>
              </div>
            </div>
            <div style={styles.statCard}>
              <FontAwesomeIcon icon={faBrain} style={styles.statIcon} />
              <div>
                <p style={styles.statValue}>{jobStats.aiRecommended}</p>
                <p style={styles.statLabel}>AI Recommended Jobs</p>
              </div>
            </div>
          </div>

          {personalityReport && <PersonalitySection />}
          <JobStatsSection />

          <div style={styles.jobListingsContainer}>
            <div style={styles.searchContainer}>
              <h2 style={styles.sectionTitle}>Job Listings</h2>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="text"
                  placeholder="Search jobs..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={styles.searchInput}
                />
                <select
                  value={filterIndustry}
                  onChange={handleIndustryChange}
                  style={styles.select}
                >
                  <option value="">All Industries</option>
                  <option value="IT">IT</option>
                  <option value="Marketing">Marketing</option>
                  <option value="Finance">Finance</option>
                  {/* Add more industry options as needed */}
                </select>
              </div>
            </div>

            <div style={styles.buttonGroup}>
              <button
                onClick={() => { setShowRecommended(false); setShowAIRecommended(false); }}
                style={{
                  ...styles.button,
                  ...((!showRecommended && !showAIRecommended) ? styles.activeButton : styles.inactiveButton)
                }}
              >
                All Jobs
              </button>
              <button
                onClick={() => { setShowRecommended(true); setShowAIRecommended(false); }}
                style={{
                  ...styles.button,
                  ...(showRecommended ? styles.activeButton : styles.inactiveButton)
                }}
              >
                Recommended Jobs
              </button>
              <button
                onClick={() => { setShowAIRecommended(true); setShowRecommended(false); }}
                style={{
                  ...styles.button,
                  ...(showAIRecommended ? styles.activeButton : styles.inactiveButton)
                }}
              >
                AI Recommended Jobs
              </button>
            </div>

            <div style={styles.jobsGrid}>
              <AnimatePresence>
                {filteredJobs.map(job => (
                  <JobCard key={job.id} job={job} />
                ))}
              </AnimatePresence>
            </div>
            {filteredJobs.length >= 10 && (
              <button
                onClick={fetchMoreJobs}
                style={styles.loadMoreButton}
              >
                Load More Jobs
              </button>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {selectedJob && (
          <JobDetails job={selectedJob} onClose={() => setSelectedJob(null)} />
        )}
      </AnimatePresence>
      <ScrollTop />
      <Footer />
    </>
  );
};

export default ExpertDashboard;