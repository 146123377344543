import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth, googleAuthProvider, db } from '../firebase';
import Modal from 'react-modal';
import styled from 'styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { FaGoogle, FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

Modal.setAppElement('#root');

const extractUserName = (firebaseUser) => {
    return firebaseUser.displayName ? firebaseUser.displayName : 'User';
};

const ExpertLoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPolicies, setShowPolicies] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [policiesAccepted, setPoliciesAccepted] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                const userData = {
                    uid: firebaseUser.uid,
                    email: firebaseUser.email,
                    displayName: extractUserName(firebaseUser),
                    photoURL: firebaseUser.photoURL,
                };
                setUser(userData);
                await checkUserAcceptanceAndSubscription(userData);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const checkUserAcceptanceAndSubscription = async (userData) => {
        const userRef = doc(db, 'users', userData.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            if (userData.policiesAccepted && userData.termsAccepted) {
                await checkUserSubscription(userData);
            } else {
                setShowPolicies(true);
            }
        } else {
            setShowPolicies(true);
        }
    };

    const checkUserSubscription = async (userData) => {
        try {
            const userRef = doc(db, 'expertpricing', userData.uid);
            const docSnap = await getDoc(userRef);

            if (docSnap.exists() && docSnap.data().token) {
                console.log('User has an existing subscription token.');
                navigate('/expertdashboard', { state: { user: { ...userData, subscribed: true } } });
            } else {
                console.log('No existing subscription token found. Redirecting to pricing.');
                navigate('/expertpricing', { state: { user: userData } });
            }
        } catch (err) {
            setError('Error checking subscription: ' + err.message);
        }
    };

    const handleLogin = async (loginMethod) => {
        if (!policiesAccepted || !termsAccepted) {
            setError('Please accept both the Privacy Policy and Terms & Conditions to continue.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            let userCredential;
            if (loginMethod === 'email') {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            } else if (loginMethod === 'google') {
                userCredential = await signInWithPopup(auth, googleAuthProvider);
            }

            const { user } = userCredential;
            const userData = {
                uid: user.uid,
                email: user.email,
                displayName: extractUserName(user),
                photoURL: user.photoURL,
            };

            await setDoc(doc(db, 'users', user.uid), {
                policiesAccepted: true,
                termsAccepted: true,
            }, { merge: true });

            await checkUserSubscription(userData);
        } catch (error) {
            setError(`Failed to log in: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const togglePolicies = () => setShowPolicies(!showPolicies);
    const toggleTerms = () => setShowTerms(!showTerms);

    const handleAcceptPolicies = () => {
        setPoliciesAccepted(true);
        togglePolicies();
        if (termsAccepted) return;
        setShowTerms(true);
    };

    const handleAcceptTerms = () => {
        setTermsAccepted(true);
        toggleTerms();
    };

    return (
        <Container>
            <FormContainer>
     
                <Title>Welcome Back, Expert!</Title>
                <Description>Log in to ExpertsToo and start sharing your expertise</Description>
                <InputGroup>
                    <InputIcon>
                        <FaEnvelope />
                    </InputIcon>
                    <StyledInput 
                        type="email" 
                        placeholder="Email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required
                    />
                </InputGroup>
                <InputGroup>
                    <InputIcon>
                        <FaLock />
                    </InputIcon>
                    <StyledInput 
                        type={showPassword ? "text" : "password"}
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required
                    />
                    <PasswordToggle onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </PasswordToggle>
                </InputGroup>
                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={policiesAccepted} 
                        onChange={() => setPoliciesAccepted(!policiesAccepted)} 
                        id="policies"
                    />
                    <label htmlFor="policies">I accept the <TermsLink onClick={togglePolicies}>Privacy Policy</TermsLink></label>
                </CheckboxContainer>
                <CheckboxContainer>
                    <Checkbox 
                        type="checkbox" 
                        checked={termsAccepted} 
                        onChange={() => setTermsAccepted(!termsAccepted)} 
                        id="terms"
                    />
                    <label htmlFor="terms">I accept the <TermsLink onClick={toggleTerms}>Terms & Conditions</TermsLink></label>
                </CheckboxContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <StyledButton onClick={() => handleLogin('email')} disabled={loading}>
                    {loading ? 'Logging in...' : 'Log In'}
                </StyledButton>
                <OrDivider>
                    <span>OR</span>
                </OrDivider>
                <StyledButton onClick={() => handleLogin('google')} disabled={loading} style={{ backgroundColor: '#db4437' }}>
                    <FaGoogle style={{ marginRight: '10px' }} />
                    Continue with Google
                </StyledButton>
                <LinkText onClick={() => navigate('/reset-password')}>Forgot password?</LinkText>
                <LinkText onClick={() => navigate('/expertsregister')}>Don't have an account? Sign up</LinkText>
            </FormContainer>
            
            <Modal
                isOpen={showPolicies}
                onRequestClose={togglePolicies}
                contentLabel="Privacy Policy"
                style={modalStyle}
            >
                <ModalContent>
                    <ModalTitle>Privacy Policy</ModalTitle>
                    <PolicyText dangerouslySetInnerHTML={{ __html: privacyPolicyText }} />
                    <ModalButtonContainer>
                        <StyledButton onClick={handleAcceptPolicies}>Accept</StyledButton>
                        <StyledButton onClick={togglePolicies} style={{ backgroundColor: '#6c757d' }}>Close</StyledButton>
                    </ModalButtonContainer>
                </ModalContent>
            </Modal>

            <Modal
                isOpen={showTerms}
                onRequestClose={toggleTerms}
                contentLabel="Terms and Conditions"
                style={modalStyle}
            >
                <ModalContent>
                    <ModalTitle>Terms and Conditions</ModalTitle>
                    <PolicyText dangerouslySetInnerHTML={{ __html: termsAndConditionsText }} />
                    <ModalButtonContainer>
                        <StyledButton onClick={handleAcceptTerms}>Accept</StyledButton>
                        <StyledButton onClick={toggleTerms} style={{ backgroundColor: '#6c757d' }}>Close</StyledButton>
                    </ModalButtonContainer>
                </ModalContent>
            </Modal>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1.5s ease-in-out;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto 20px;
  max-width: 150px;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  font-size: 28px;
  font-family: 'Open Sans', sans-serif;
  animation: slideInFromLeft 1s ease-in-out;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const InputIcon = styled.span`
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px 15px 12px 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
    outline: none;
  }
`;

const PasswordToggle = styled.span`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const TermsLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px 0;
  margin-bottom: 15px;
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
  }

  span {
    padding: 0 10px;
    color: #777;
    font-size: 14px;
  }
`;

const LinkText = styled.span`
  display: block;
  text-align: center;
  color: #007bff;
  cursor: pointer;
  margin-top: 10px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
`;

const ModalContent = styled.div`
  padding: 20px;
`;

const ModalTitle = styled.h2`
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const PolicyText = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 15px;

  h2 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h3 {
    color: #444;
    margin-top: 15px;
    margin-bottom: 8px;
  }

  p, ul {
    color: #666;
    margin-bottom: 10px;
    line-height: 1.6;
  }

  ul {
    padding-left: 20px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '80vh',
        padding: '20px',
        borderRadius: '10px',
        border: 'none',
        boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#f8f9fa',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    }
};

const privacyPolicyText = `
<h2>1. Introduction</h2>
<p>At ExpertsToo, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our platform as an expert.</p>

<h2>2. Information We Collect</h2>
<p>We collect various types of information, including:</p>
<ul>
    <li>Personal Information: Name, email address, phone number, professional qualifications</li>
    <li>Account Information: Login credentials, profile details</li>
    <li>Professional Information: Expertise areas, work history, educational background</li>
    <li>Usage Data: Interaction with our platform, services used, content created</li>
    <li>Financial Information: Payment details for receiving compensation</li>
</ul>

<h2>3. How We Use Your Information</h2>
<p>We use the collected information for purposes such as:</p>
<ul>
    <li>Facilitating your role as an expert on our platform</li>
    <li>Matching you with appropriate clients and projects</li>
    <li>Processing payments and managing your account</li>
    <li>Improving our services and user experience</li>
    <li>Communicating with you about our services and updates</li>
    <li>Ensuring compliance with legal and regulatory requirements</li>
</ul>

<h2>4. Information Sharing and Disclosure</h2>
<p>We may share your information with:</p>
<ul>
    <li>Clients seeking expert services, with your consent</li>
    <li>Service providers who assist in our operations</li>
    <li>Legal authorities when required by law</li>
    <li>Business partners for joint offerings, with your permission</li>
</ul>

<h2>5. Data Security</h2>
<p>We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

<h2>6. Your Rights and Choices</h2>
<p>You have the right to:</p>
<ul>
    <li>Access and update your personal information</li>
    <li>Request deletion of your data</li>
    <li>Opt-out of certain data collection and use</li>
    <li>Withdraw consent where processing is based on consent</li>
</ul>

<h2>7. Changes to This Policy</h2>
<p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

<h2>8. Contact Us</h2>
<p>If you have any questions about this Privacy Policy, please contact us at:</p>
<p>Email: privacy@expertstoo.com</p>
<p>Address: ExpertsToo Privacy Office, 123 Expert Street, Knowledge City, 54321, USA</p>
`;

const termsAndConditionsText = `
<h2>1. Acceptance of Terms</h2>
<p>By accessing or using the ExpertsToo platform as an expert, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access or use our services.</p>

<h2>2. Expert Eligibility and Registration</h2>
<p>To use our platform as an expert, you must:</p>
<ul>
    <li>Be at least 18 years old</li>
    <li>Possess verifiable expertise in your claimed field(s)</li>
    <li>Provide accurate and truthful information during registration</li>
    <li>Maintain the confidentiality of your account credentials</li>
</ul>

<h2>3. Expert Services and Conduct</h2>
<p>As an expert on our platform, you agree to:</p>
<ul>
    <li>Provide high-quality, professional services to clients</li>
    <li>Respond promptly to client inquiries and project requests</li>
    <li>Maintain confidentiality of client information</li>
    <li>Comply with all applicable laws and regulations</li>
    <li>Refrain from engaging in any fraudulent or deceptive practices</li>
</ul>

<h2>4. Intellectual Property</h2>
<p>You retain ownership of the content you create. However, by posting content on our platform, you grant ExpertsToo a non-exclusive, worldwide, royalty-free license to use, modify, and distribute your content for the purpose of providing our services.</p>

<h2>5. Compensation and Payments</h2>
<p>ExpertsToo will compensate you for your services according to the agreed-upon terms. We reserve the right to withhold payment in cases of suspected fraud or violation of these terms.</p>

<h2>6. Termination of Services</h2>
<p>We reserve the right to suspend or terminate your expert account if you violate these terms, engage in fraudulent activity, or receive consistent negative feedback from clients.</p>

<h2>7. Limitation of Liability</h2>
<p>ExpertsToo is not liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our platform or provision of expert services.</p>

<h2>8. Dispute Resolution</h2>
<p>Any disputes arising from these terms or your use of our platform will be resolved through binding arbitration, conducted in accordance with the rules of the American Arbitration Association.</p>

<h2>9. Changes to Terms</h2>
<p>We may modify these terms at any time. Continued use of our platform after any changes indicates your acceptance of the new terms.</p>

<h2>10. Contact Information</h2>
<p>For any questions regarding these terms, please contact us at:</p>
<p>Email: legal@expertstoo.com</p>
<p>Address: ExpertsToo Legal Department, 456 Terms Avenue, Compliance City, 98765, USA</p>
`;

export default ExpertLoginScreen;