import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { collection, query, where, orderBy, onSnapshot, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faCommentDots, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { auth, db } from '../firebase';
import logoDark from '../assets/images/logo-dark.png';
import styled, { keyframes, css } from 'styled-components';

function ClientNavbar() {
  const [user, setUser] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsDropdownVisible, setNotificationsDropdownVisible] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const navigate = useNavigate();

  const fetchNotifications = (userEmail) => {
    const notificationsRef = query(collection(db, 'notifications'), where('email', '==', userEmail), orderBy('timestamp', 'desc'));
    return onSnapshot(notificationsRef, (snapshot) => {
      const fetchedNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp.toDate(),
        read: doc.data().read || false
      }));
      setNotifications(fetchedNotifications);
    }, (error) => {
      console.error("Error fetching notifications: ", error);
    });
  };

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        setUser({
          uid: firebaseUser.uid,
          displayName: firebaseUser.displayName ? firebaseUser.displayName.split(' ')[0] : 'User',
          photoURL: firebaseUser.photoURL,
          email: firebaseUser.email,
        });
        const userRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setIsPremium(userDoc.data().plan === 'Premium');
        }
        fetchNotifications(firebaseUser.email);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (!user) {
      setNotifications([]);
      return;
    }
    const unsubscribeNotifications = fetchNotifications(user.email);
    return () => unsubscribeNotifications();
  }, [user]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNotifications((currentNotifications) => currentNotifications.filter(notification => {
        const isRead = notification.read;
        const isOlderThan14Days = new Date() - notification.timestamp > 14 * 24 * 60 * 60 * 1000;
        if (isRead && isOlderThan14Days) {
          deleteDoc(doc(db, 'notifications', notification.id));
        }
        return !(isRead && isOlderThan14Days);
      }));
    }, 24 * 60 * 60 * 1000); // Check once a day
    return () => clearInterval(intervalId);
  }, [notifications]);

  const signOut = async () => {
    await firebaseSignOut(auth);
    navigate('/clientlogin');
  };

  const toggleDropdown = () => setDropdownVisible(!dropdownVisible);
  const toggleNotificationsDropdown = () => {
    setNotificationsDropdownVisible(!notificationsDropdownVisible);
    markAllNotificationsAsRead();
  };

  const hasUnreadNotifications = notifications.some(notification => !notification.read);

  const markNotificationAsRead = async (notificationId) => {
    const notificationRef = doc(db, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });
  };

  const markAllNotificationsAsRead = async () => {
    notifications.forEach(async notification => {
      if (!notification.read) {
        await markNotificationAsRead(notification.id);
      }
    });
  };

  return (
    <nav style={navStyle}>
      <div style={logoStyle}>
        <img src={logoDark} alt="Logo" style={logoImgStyle} />
        <span style={logoTextStyle}>Hire Like a Pro with Experts Too</span>
      </div>
      <div style={navIconsStyle}>
        <FontAwesomeIcon icon={faSearch} style={iconStyle} />
        <NotificationIconContainer hasUnread={hasUnreadNotifications.toString()} onClick={toggleNotificationsDropdown}>
          <FontAwesomeIcon icon={faBell} style={iconStyle} />
          {hasUnreadNotifications && (
            <div style={notificationCountStyle}>
              {notifications.filter(notification => !notification.read).length}
            </div>
          )}
        </NotificationIconContainer>
        {notificationsDropdownVisible && (
          <div style={notificationsStyle}>
            {notifications.map(notification => (
              <div
                key={notification.id}
                style={notificationItemStyle}
                onClick={() => markNotificationAsRead(notification.id)}
              >
                <p style={notificationMessageStyle}>
                  <a href={notification.url} target="_blank" rel="noopener noreferrer" style={notificationLinkStyle}>
                    {notification.message}
                  </a>
                </p>
                <small style={notificationTimestampStyle}>{notification.timestamp.toLocaleDateString()}</small>
              </div>
            ))}
          </div>
        )}
        <FontAwesomeIcon icon={faCommentDots} style={iconStyle} onClick={() => navigate('/clientdashboard')} />
        {user ? (
          <>
            <span style={userGreetingStyle}>Hello, {user.displayName}</span>
            <span style={{ color: isPremium ? 'gold' : 'white', marginRight: '10px', fontWeight: isPremium ? 'bold' : 'normal' }}>
              {isPremium ? 'Premium User' : 'Click to be a Premium User'}
            </span>
            <img
              src={user.photoURL || '/path-to-default-profile-image.png'}
              alt="Profile"
              style={profileImgStyle}
              onClick={toggleDropdown}
            />
            {dropdownVisible && (
              <div style={profileDropdownStyle}>
                <ul style={dropdownListStyle}>
                  <li style={dropdownItemStyle} onClick={signOut}>
                    <FontAwesomeIcon icon={faSignOutAlt} style={dropdownIconStyle} /> Log Out
                  </li>
                </ul>
              </div>
            )}
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faUserCircle} style={iconStyle} onClick={() => navigate('/login')} />
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate('/login')}>Login</span>
          </>
        )}
      </div>
    </nav>
  );
}

const navStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#0a58ca',
  color: 'white',
  padding: '0.5rem 2rem',
  marginBottom: '0px',
};

const logoStyle = {
  display: 'flex',
  alignItems: 'center'
};

const logoImgStyle = {
  height: '50px',
  borderRadius: '50%'
};

const logoTextStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginLeft: '10px',
  letterSpacing: '1px',
  fontFamily: 'Arial, sans-serif'
};

const navIconsStyle = {
  display: 'flex',
  alignItems: 'center'
};

const iconStyle = {
  color: 'white',
  margin: '0 10px',
  fontSize: '16px',
  cursor: 'pointer'
};

const notificationCountStyle = {
  position: 'absolute',
  top: '-5px',
  right: '-10px',
  backgroundColor: 'red',
  color: 'white',
  borderRadius: '50%',
  padding: '2px 6px',
  fontSize: '10px',
  fontWeight: 'bold'
};

const userGreetingStyle = {
  color: 'white',
  marginRight: '10px'
};

const profileImgStyle = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  cursor: 'pointer',
  objectFit: 'cover',
  marginLeft: '10px'
};

const profileDropdownStyle = {
  position: 'absolute',
  backgroundColor: 'white',
  padding: '10px',
  borderRadius: '5px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  right: '10px',
  top: '60px',
  zIndex: 1001
};

const dropdownListStyle = {
  listStyleType: 'none',
  padding: 0,
  margin: 0
};

const dropdownItemStyle = {
  padding: '10px',
  cursor: 'pointer',
  color: 'black'
};

const dropdownIconStyle = {
  marginRight: '5px',
  color: 'black'
};

const notificationsStyle = {
  position: 'absolute',
  top: '60px',
  right: '10px',
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '5px',
  width: '300px',
  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  overflow: 'auto',
  maxHeight: '400px',
  zIndex: 1000
};

const notificationItemStyle = {
  padding: '10px',
  borderBottom: '1px solid #eee',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  cursor: 'pointer'
};

const notificationMessageStyle = {
  fontSize: '12px'
};

const notificationTimestampStyle = {
  color: '#aaa',
  fontSize: '10px'
};

const notificationLinkStyle = {
  color: '#0a58ca',
  textDecoration: 'none'
};

const shakeEvery4Seconds = keyframes`
  0%, 4% {
    transform: translate3d(0, 0, 0);
  }
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
  96%, 100% {
    transform: translate3d(0, 0, 0);
  }
`;

const NotificationIconContainer = styled.div`
  position: relative;
  animation: ${props => props.hasUnread === 'true' ? css`${shakeEvery4Seconds} 4s infinite` : 'none'};
`;

export default ClientNavbar;
