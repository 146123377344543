import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBcLIV3hHIi92-Ei94cmax3qOS_6n7OxJY",
  authDomain: "experts-blooms.firebaseapp.com",
  databaseURL: "https://experts-blooms-default-rtdb.firebaseio.com",
  projectId: "experts-blooms",
  storageBucket: "experts-blooms.appspot.com",
  messagingSenderId: "670677559995",
  appId: "1:670669559995:web:a5597db1b72d014f49b19e",
  measurementId: "G-7V3B316W6L"
};

// Initialize Firebase/
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

const googleAuthProvider = new GoogleAuthProvider();

export { auth, db, storage, googleAuthProvider };