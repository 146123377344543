import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import NavbarDark from '../components/navbarDark';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import roleImage from '../assets/images/21207.jpg'
const RoleForm = () => {
    const [selectedRole, setSelectedRole] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            if (selectedRole) {
                await addDoc(collection(db, 'submissions'), {
                    selectedRole: selectedRole,
                    timestamp: new Date()
                });
                console.log('Role submitted successfully:', selectedRole);
                navigate('/companysizeform');
            } else {
                console.log('Please select a role');
            }
        } catch (error) {
            console.error('Error submitting role:', error.message);
        }
    };

    return (
        <>
            <NavbarDark />
            <div className='text-top' style={{ backgroundColor: 'lightgray', width: '50vh', marginLeft: '20vh', marginTop: '17vh', textAlign: 'center' }}>
                <span><p></p>Thanks for your interest in hiring through Experts Blooms! Before we get started, we'd like to ask a few questions to better understand your business needs.</span>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3">
                            <h2 style={{ color: '#0a2351' }}>What role would you like to hire?</h2>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="developers" name="role" value="developers" onChange={() => setSelectedRole('developers')} />
                            <label htmlFor="developers">Developers</label>
                            <p className="text-muted small">Software Developers, Data Scientists, DevOps, and QA</p>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="productManagers" name="role" value="productManagers" onChange={() => setSelectedRole('productManagers')} />
                            <label htmlFor="productManagers">Product Managers</label>
                            <p className="text-muted small">Digital Product Managers, Product Owners, and Business Analysts</p>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="virtualAssistant" name="role" value="virtualAssistant" onChange={() => setSelectedRole('virtualAssistant')} />
                            <label htmlFor="virtualAssistant">Virtual Assistant</label>
                            <p className="text-muted small">Administrative tasks, Social Media Management, Customer Service</p>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="businessDevelopment" name="role" value="businessDevelopment" onChange={() => setSelectedRole('businessDevelopment')} />
                            <label htmlFor="businessDevelopment">Business Development</label>
                            <p className="text-muted small">Sales, Partnerships, Market Research</p>
                        </div>
                        <div className="mb-3">
                            <input type="radio" id="productDesigner" name="role" value="productDesigner" onChange={() => setSelectedRole('productDesigner')} />
                            <label htmlFor="productDesigner">Product Designer</label>
                            <p className="text-muted small">UI/UX Design, Prototyping, User Testing</p>
                        </div>
                        <div className="mb-3">
                            <input type="text" id="otherRole" name="otherRole" placeholder="Enter other role" onChange={(e) => setSelectedRole(e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <button type="button" className="btn btn-primary" style={{ backgroundColor: '#0a2351' }} onClick={handleSubmit}>Next</button>
                        </div>
                    </div>
               
                        <div className="col-md-6">
                            <img src={roleImage} className="img-fluid" alt="Role Image" />
                        </div>
                </div>
            </div>
        </>
    );
};

export default RoleForm;
