import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs } from 'firebase/firestore';
import styled, { keyframes } from "styled-components";
import Footer from "../components/footer";
import ScrollTop from '../components/scrollTop';
import CustomNavbar from "./customnavbar";
import { db } from "../firebase";
import { Icon } from '@iconify/react';
import userIcon from '@iconify-icons/mdi/account-circle';
import briefcaseIcon from '@iconify-icons/mdi/briefcase';
import certificateIcon from '@iconify-icons/mdi/certificate';
import resumeIcon from '@iconify-icons/mdi/file-document';
import videoIcon from '@iconify-icons/mdi/video';
import linkedinIcon from '@iconify-icons/mdi/linkedin';
import projectIcon from '@iconify-icons/mdi/format-list-bulleted';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: #f4f7fc;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const LeftColumn = styled.div`
  flex: 1;
  max-width: 300px;
  margin-right: 20px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const RightColumn = styled.div`
  flex: 2;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const ProfileImage = styled.img`
  width: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
`;

const SectionTitle = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #333;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 10px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const SkillTag = styled.span`
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  margin: 5px;
  font-size: 14px;
`;

const ProjectGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ProjectCard = styled.div`
  width: calc(33.333% - 20px);
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s;
  &:hover {
    transform: translateY(-10px);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 15px;
`;

const ProjectTitle = styled.h5`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
`;

const ProjectLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export default function CandidateProfile() {
  const { email } = useParams();
  const [profileData, setProfileData] = useState({
    name: '',
    email: '',
    gender: '',
    country: '',
    timezone: '',
    currentJobTitle: '',
    workExperience: [],
    certifications: [],
    cvUrl: '',
    videoIntroUrl: '',
    profileImage: '',
    linkedin: '',
    primarySkills: []
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      const docRef = collection(db, "profiles");
      const snapshot = await getDocs(docRef);
      const data = snapshot.docs.map(doc => doc.data()).find(profile => profile.email === email);
      if (data) {
        setProfileData({
          ...profileData,
          ...data,
          workExperience: Array.isArray(data.workExperience) ? data.workExperience : [],
          certifications: Array.isArray(data.certifications) ? data.certifications : [],
          primarySkills: Array.isArray(data.primarySkills) ? data.primarySkills : []
        });
      }
    };

    fetchProfileData();
  }, [email]);

  return (
    <>
      <CustomNavbar />
      <ProfileContainer>
        <LeftColumn>
          <ProfileImage
            src={profileData.profileImage || 'https://via.placeholder.com/150'}
            alt="Profile"
          />
          <h3 style={{ marginTop: '20px', textAlign: 'center' }}>{profileData.name || 'Unavailable'}</h3>
          <p style={{ color: '#666', textAlign: 'center', marginBottom: '20px' }}>
            {profileData.currentJobTitle || 'No Job Title'}
          </p>
          {profileData.primarySkills.length > 0 && (
            <div style={{ marginBottom: '20px' }}>
              <h5>Skills</h5>
              <div>
                {profileData.primarySkills.map((skill, index) => (
                  <SkillTag key={index}>{skill}</SkillTag>
                ))}
              </div>
            </div>
          )}
        </LeftColumn>

        <RightColumn>
          <SectionTitle>
            Personal Details
            <Icon icon={userIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          <p><strong>Gender:</strong> {profileData.gender || 'Not Specified'}</p>
          <p><strong>Country:</strong> {profileData.country || 'Unknown'}</p>
          <p><strong>Timezone:</strong> {profileData.timezone || 'Not Set'}</p>

          <SectionTitle>
            Work Experience
            <Icon icon={briefcaseIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          {Array.isArray(profileData.workExperience) && profileData.workExperience.length > 0 ? (
            profileData.workExperience.map((item, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            ))
          ) : (
            <p>No work experience listed.</p>
          )}

          <SectionTitle>
            Certifications
            <Icon icon={certificateIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          {Array.isArray(profileData.certifications) && profileData.certifications.length > 0 ? (
            profileData.certifications.map((cert, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <h5>{cert.title} from {cert.issuer}</h5>
                <p>{cert.country}, {new Date(cert.date.seconds * 1000).toLocaleDateString()}</p>
              </div>
            ))
          ) : (
            <p>No certifications listed.</p>
          )}

          <SectionTitle>
            Resume
            <Icon icon={resumeIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          {profileData.cvUrl ? (
            <div>
              <iframe
                src={profileData.cvUrl}
                style={{ width: '100%', height: '300px' }}
                title="Resume Preview"
                frameBorder="0"
                allowFullScreen
              />
              <a
                href={profileData.cvUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: '#0d6efd',
                  marginTop: '10px',
                }}
              >
                Download Resume
              </a>
            </div>
          ) : (
            <p>No resume available.</p>
          )}

          <SectionTitle>
            Video Introduction
            <Icon icon={videoIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          {profileData.videoIntroUrl ? (
            <iframe
              src={profileData.videoIntroUrl}
              style={{ width: '100%', height: '300px' }}
              title="Video Introduction"
              frameBorder="0"
              allowFullScreen
            />
          ) : (
            <p>No video introduction available.</p>
          )}

          <SectionTitle>
            LinkedIn Profile
            <Icon icon={linkedinIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          {profileData.linkedin ? (
            <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: '#0d6efd' }}>
              View LinkedIn Profile
            </a>
          ) : (
            <p>No LinkedIn profile available.</p>
          )}

          <SectionTitle>
            Projects
            <Icon icon={projectIcon} style={{ marginLeft: '10px', fontSize: '24px', color: '#007bff' }} />
          </SectionTitle>
          <ProjectGallery>
            {Array.isArray(profileData.projects) && profileData.projects.length > 0 ? (
              profileData.projects.map((project, index) => (
                <ProjectCard key={index}>
                  <ProjectImage src={project.thumbnail || 'https://via.placeholder.com/150'} alt={project.name} />
                  <ProjectInfo>
                    <ProjectTitle>{project.name}</ProjectTitle>
                    <ProjectLink href={project.link} target="_blank">
                      View Project
                    </ProjectLink>
                  </ProjectInfo>
                </ProjectCard>
              ))
            ) : (
              <p>No projects listed.</p>
            )}
          </ProjectGallery>
        </RightColumn>
      </ProfileContainer>
      <Footer />
      <ScrollTop />
    </>
  );
}
