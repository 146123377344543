import React, { useState, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, query, orderBy, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const MessagingComponent = ({ onClose, selectedUser }) => {
  const [user, loading, error] = useAuthState(auth);
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (!user || loading || error || !selectedUser) {
      return;
    }

    const conversationId = [user.email, selectedUser.email].sort().join('_');
    const messagesRef = collection(db, `messages/${conversationId}/messages`);
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [user, selectedUser, loading, error]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      console.error("Cannot send an empty message.");
      return;
    }

    const conversationId = [user.email, selectedUser.email].sort().join('_');
    const conversationRef = doc(db, `messages/${conversationId}`);
    await setDoc(conversationRef, {
      participants: [user.email, selectedUser.email]
    });
    await addDoc(collection(conversationRef, 'messages'), {
      text: newMessage,
      senderId: user.uid,
      senderEmail: user.email,
      receiverId: selectedUser.id,
      receiverEmail: selectedUser.email,
      timestamp: serverTimestamp()
    });
    setNewMessage('');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!selectedUser) return <div>Please select a user to message.</div>;

  return (
    <div style={{ position: 'fixed', bottom: 10, right: 10, width: '300px', minHeight: '400px', border: '1px solid gray', padding: '10px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0,0,0,0.1)', zIndex: 100 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h3>Chat with {selectedUser.name}</h3>
        <button onClick={onClose} style={{ border: 'none', background: 'red', color: 'white', borderRadius: '50%', padding: '5px 8px', cursor: 'pointer' }}>X</button>
      </div>
      <div style={{ height: '300px', overflowY: 'auto', marginBottom: '10px' }}>
        {messages.map(message => (
          <div key={message.id} style={{ textAlign: message.senderId === user.uid ? 'right' : 'left', margin: '10px 0' }}>
            <span style={{ background: '#f0f0f0', borderRadius: '10px', padding: '5px 10px', display: 'inline-block', maxWidth: '70%' }}>{message.text}</span>
          </div>
        ))}
      </div>
      <textarea value={newMessage} onChange={e => setNewMessage(e.target.value)} style={{ width: '100%', height: '50px', padding: '5px', border: '1px solid #ccc', borderRadius: '4px', resize: 'none' }} placeholder="Type your message..."></textarea>
      <button onClick={handleSendMessage} style={{ width: '100%', padding: '10px 0', background: 'blue', color: 'white', border: 'none', borderRadius: '4px', marginTop: '10px', cursor: 'pointer' }}>Send</button>
    </div>
  );
};

export default MessagingComponent;
