import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import ClientNavbar from "./clientnavbar";
import Footer from "../components/footer";
import ScrollTop from "../components/scrollTop";
import { FiMessageCircle } from "../assets/icons/vander";
import { FaUserTie, FaLaptopCode, FaChartLine, FaIndustry, FaCogs, FaDollarSign, FaSearch } from "react-icons/fa";
import bg1 from "../assets/images/hero/bg.jpg";

const defaultAvatar = "https://via.placeholder.com/100?text=No+Image"; // Use a placeholder image URL

export default function Candidates() {
  const [candidates, setCandidates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    industry: '',
    experience: '',
    skills: '',
    hourlyRate: '',
    certifications: '',
    location: '',
  });
  const cardsPerPage = 8;
  const db = getFirestore();

  useEffect(() => {
    const fetchCandidates = async () => {
      const candidatesCollection = collection(db, 'profiles');
      const candidatesSnapshot = await getDocs(candidatesCollection);
      const candidatesList = candidatesSnapshot.docs
        .map(doc => doc.data())
        .filter(candidate => calculateProfileCompleteness(candidate) >= 60)
        .sort((a, b) => calculateProfileCompleteness(b) - calculateProfileCompleteness(a)); // Sort by profile completeness

      setCandidates(candidatesList);
    };

    fetchCandidates();
  }, [db]);

  const calculateProfileCompleteness = (candidate) => {
    const fields = [
      candidate.name,
      candidate.email,
      candidate.profileImage,
      candidate.currentJobTitle,
      candidate.primarySkills,
      candidate.certifications,
      candidate.workExperience,
      candidate.hourlyRate,
    ];

    const filledFields = fields.filter(field => field && field.length > 0);
    return (filledFields.length / fields.length) * 100;
  };

  const capExperience = (experience) => {
    return experience > 50 ? 50 : experience;
  };

  const applyFilters = (candidatesList) => {
    return candidatesList.filter(candidate => {
      const matchesIndustry = filters.industry ? candidate.industryCategory === filters.industry : true;
      const matchesExperience = filters.experience ? candidate.workExperience?.length === parseInt(filters.experience) : true;
      const matchesSkills = filters.skills ? candidate.primarySkills.includes(filters.skills) : true;
      const matchesHourlyRate = filters.hourlyRate ? candidate.hourlyRate <= parseInt(filters.hourlyRate) : true;
      const matchesCertifications = filters.certifications ? candidate.certifications.some(cert => cert.title.includes(filters.certifications)) : true;
      const matchesLocation = filters.location ? candidate.location === filters.location : true;
      return matchesIndustry && matchesExperience && matchesSkills && matchesHourlyRate && matchesCertifications && matchesLocation;
    });
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filteredCandidates = applyFilters(candidates);
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCandidates = filteredCandidates.slice(indexOfFirstCard, indexOfFirstCard + cardsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
<ClientNavbar />
<section className="hero-section" style={styles.heroSection}>
        <div className="overlay" style={styles.overlay}></div>
        <div className="container" style={styles.heroContainer}>
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-8">
              <div style={styles.heroContent}>
                <h1 className="display-4 fw-bold" style={styles.heroTitle}>Meet Our Top Talent</h1>
                <p style={styles.heroSubtitle}>Connect with industry leaders, innovators, and experts who are ready to take on your next big challenge.</p>
                <div className="hero-icons mt-4" style={styles.heroIcons}>
                  <div style={styles.iconContainer}>
                    <FaUserTie size={40} style={styles.icon} />
                    <p style={styles.iconText}>Business Experts</p>
                  </div>
                  <div style={styles.iconContainer}>
                    <FaLaptopCode size={40} style={styles.icon} />
                    <p style={styles.iconText}>Tech Gurus</p>
                  </div>
                  <div style={styles.iconContainer}>
                    <FaChartLine size={40} style={styles.icon} />
                    <p style={styles.iconText}>Marketing Pros</p>
                  </div>
                </div>
                <Link to="#candidates" className="btn btn-primary mt-4" style={styles.heroButton}>Discover Talent</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-bottom" style={styles.shapeBottom}>
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </section>

      <section className="section" id="candidates" style={styles.section}>
        <div className="container">
          <div className="row">
            {/* Filters Section */}
            <div className="col-lg-3 col-md-4 mb-4">
              <div className="filter-box p-4 mb-4 shadow-sm" style={styles.filterBox}>
                <h5 className="mb-3">Filter Experts</h5>
                <div className="mb-3">
                  <label htmlFor="industry" className="form-label"><FaIndustry style={styles.filterIcon} /> Industry</label>
                  <select id="industry" name="industry" className="form-select" value={filters.industry} onChange={handleFilterChange}>
                    <option value="">All Industries</option>
                    <option value="Product Management">Product Management</option>
                    <option value="Software Development">Software Development</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Finance">Finance</option>
                    {/* Add more options as per your database */}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="experience" className="form-label"><FaCogs style={styles.filterIcon} /> Experience (years)</label>
                  <input type="number" id="experience" name="experience" className="form-control" value={filters.experience} onChange={handleFilterChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="skills" className="form-label"><FaSearch style={styles.filterIcon} /> Primary Skill</label>
                  <input type="text" id="skills" name="skills" className="form-control" value={filters.skills} onChange={handleFilterChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="hourlyRate" className="form-label"><FaDollarSign style={styles.filterIcon} /> Max Hourly Rate ($)</label>
                  <input type="number" id="hourlyRate" name="hourlyRate" className="form-control" value={filters.hourlyRate} onChange={handleFilterChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="certifications" className="form-label"><FaSearch style={styles.filterIcon} /> Certifications</label>
                  <input type="text" id="certifications" name="certifications" className="form-control" value={filters.certifications} onChange={handleFilterChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="location" className="form-label"><FaSearch style={styles.filterIcon} /> Location</label>
                  <input type="text" id="location" name="location" className="form-control" value={filters.location} onChange={handleFilterChange} />
                </div>
                <button className="btn btn-primary w-100" style={styles.filterButton} onClick={() => setCurrentPage(1)}>Apply Filters</button>
              </div>
            </div>

            {/* Candidates Section */}
            <div className="col-lg-9 col-md-8">
              <div className="row g-4">
                {currentCandidates.map((candidate, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12" key={index} style={styles.cardContainer}>
                    <div className="candidate-card position-relative overflow-hidden text-center shadow rounded p-4" style={styles.candidateCard}>
                      {candidate.certifications && candidate.certifications.length > 0 && (
                        <div className="ribbon ribbon-left overflow-hidden">
                          <span className="text-center d-block bg-warning shadow small h6">
                            <i className="mdi mdi-star"></i>
                          </span>
                        </div>
                      )}
                      <div className="content">
                        <img src={candidate.profileImage || defaultAvatar} className="avatar avatar-md-md rounded-pill shadow-md" alt={candidate.name} style={styles.avatar} />

                        <div className="mt-3">
                          <Link to={`/candidate-profile/${encodeURIComponent(candidate.email)}`} className="title h5 text-dark" style={styles.title}>{candidate.name}</Link>
                          <p className="text-muted mt-1">{candidate.currentJobTitle}</p>

                          <div style={styles.skillsContainer}>
                            {Array.isArray(candidate.primarySkills) && candidate.primarySkills.slice(0, 4).map((skill, skillIndex) => (
                              <span key={skillIndex} className="badge bg-soft-primary rounded-pill" style={styles.skillBadge}>{skill}</span>
                            ))}
                          </div>
                        </div>

                        <div className="mt-2 d-flex align-items-center justify-content-between" style={styles.infoContainer}>
                          <div className="text-center">
                            <p className="text-muted fw-medium mb-0">Hourly Rate:</p>
                            <p className="mb-0 fw-medium">${candidate.hourlyRate}/hr</p>
                          </div>

                          <div className="text-center">
                            <p className="text-muted fw-medium mb-0">Experience:</p>
                            <p className="mb-0 fw-medium">{capExperience(candidate.workExperience?.length || 0)} years</p>
                          </div>
                        </div>

                        <div className="mt-3">
                          <Link to={`/candidate-profile/${encodeURIComponent(candidate.email)}`} className="btn btn-sm btn-primary me-1" style={styles.viewProfileButton}>View Profile</Link>
                          <Link to="/contactus" className="btn btn-sm btn-icon btn-soft-primary" style={styles.contactButton}><FiMessageCircle className="icons" /></Link>
                        </div>

                        <Link to="#" className="like"><i className="mdi mdi-heart align-middle fs-4"></i></Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination */}
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <ul className="pagination justify-content-center mb-0">
                    {Array.from({ length: Math.ceil(filteredCandidates.length / cardsPerPage) }, (_, index) => (
                      <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
                        <Link className="page-link" to="#" onClick={() => paginate(index + 1)}>
                          {index + 1}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer top={true} />
      <ScrollTop />
    </>
  );
}

const styles = {
  heroSection: {
    position: 'relative',
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#fff',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background covering the entire section
    zIndex: 1,
  },
  heroContainer: {
    position: 'relative',
    zIndex: 2,
    maxWidth: '900px',
    margin: '0 auto',
    textAlign: 'center',
  },
  heroContent: {
    padding: '30px',
    borderRadius: '10px',
  },
  heroTitle: {
    fontSize: '2.5rem',
    marginBottom: '20px',
  },
  heroSubtitle: {
    fontSize: '1.2rem',
    marginBottom: '30px',
  },
  heroIcons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '30px',
  },
  iconContainer: {
    textAlign: 'center',
  },
  icon: {
    color: '#ffc107',
    marginBottom: '10px',
  },
  iconText: {
    fontSize: '1rem',
  },
  heroButton: {
    backgroundColor: '#ffc107',
    borderColor: '#ffc107',
    color: '#000',
    padding: '10px 30px',
    fontSize: '1.2rem',
    borderRadius: '50px',
  },
  shapeBottom: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    overflow: 'hidden',
    lineHeight: '0',
  },
  section: {
    padding: '60px 0',
    backgroundColor: '#f8f9fa',
  },
  filterBox: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
  },
  filterButton: {
    marginTop: '20px',
  },
  filterIcon: {
    marginRight: '10px',
    color: '#495057',
  },
  cardContainer: {
    padding: '10px',
  },
  candidateCard: {
    minHeight: '400px',
    maxHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s, box-shadow 0.3s',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
    margin: '15px 0',
  },
  avatar: {
    width: '100px',
    height: '100px',
    marginBottom: '15px',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#333',
    textDecoration: 'none',
    transition: 'color 0.3s',
  },
  skillsContainer: {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '5px',
  },
  skillBadge: {
    fontSize: '0.8rem',
    padding: '5px 10px',
  },
  infoContainer: {
    marginTop: '15px',
  },
  viewProfileButton: {
    padding: '8px 15px',
    backgroundColor: 'rgb(10, 88, 202)',
    color: '#fff',
    borderRadius: '25px',
    textDecoration: 'none',
    transition: 'background-color 0.3s',
  },
  contactButton: {
    backgroundColor: '#fff',
    color: 'rgb(10, 88, 202)',
    border: '1px solid rgb(10, 88, 202)',
    borderRadius: '25px',
    padding: '8px 15px',
    transition: 'transform 0.3s, color 0.3s, background-color 0.3s',
  },
};
