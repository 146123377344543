import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, googleAuthProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'; // Firestore operations
import SignImg from '../assets/images/signup.jpg'; // Image for the left side
import logo from '../assets/images/logo-dark.png'; // Your logo image

export default function ClientSignup() {
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;

      // Reference to the user's document in Firestore
      const userRef = doc(db, 'users', user.uid);

      // Check if the user already exists with a different role
      const docSnap = await getDoc(userRef);
      if (docSnap.exists() && docSnap.data().role !== 'client') {
        alert('You are already registered with a different role. Please log in using your existing role.');
        return;
      }

      // Set or update the user's role to 'client'
      await setDoc(userRef, {
        email: user.email,
        role: 'client', // Assign 'client' role
      }, { merge: true });

      navigate('/clientdashboard');
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  const handleLogin = () => navigate('/login');
  const handleSignUp = () => navigate('/chooserole');

  // Responsive styling with mobile breakpoint
  const mobileBreakpoint = '@media (max-width: 768px)';

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      fontFamily: "'Poppins', sans-serif",
      color: '#333',

      [mobileBreakpoint]: {
        flexDirection: 'column',
      },
    },
    imageSide: {
      flex: 1,
      display: 'none', 
      justifyContent: 'center',
      alignItems: 'center',
      [mobileBreakpoint]: {
        display: 'flex',
        width: '100%',
        order: 2,
      },
    },
    imageStyle: {
      width: '80%',
      maxHeight: '500px',
      objectFit: 'cover',
      borderRadius: '5px',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
      [mobileBreakpoint]: {
        width: '100%',
        maxHeight: '200px',
      },
    },
    formSide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      maxWidth: '500px',
      [mobileBreakpoint]: {
        width: '100%',
        padding: '40px 20px',
      },
    },
    box: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      padding: '40px',
      [mobileBreakpoint]: {
        padding: '20px',
      },
    },
    logo: {
      marginBottom: '20px',
      width: '100px',
    },
    button: {
      width: '100%',
      padding: '12px 0',
      marginBottom: '15px',
      border: 'none',
      borderRadius: '4px',
      fontSize: '16px',
      fontWeight: '600',
      cursor: 'pointer',
      transition: 'background-color 0.3s, transform 0.2s',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [mobileBreakpoint]: {
        padding: '10px 0',
      },
    },
    googleButton: {
      backgroundColor: '#ea4335',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#d93025',
      },
    },
    emailButton: {
      backgroundColor: '#1a73e8',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1669c1',
      },
    },
    link: {
      color: '#1a73e8',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.imageSide}>
        <img src={SignImg} alt="Signup" style={styles.imageStyle} />
      </div>
      <div style={styles.formSide}>
        <div style={styles.box}>
          <img src={logo} alt="Client Blooms Logo" style={styles.logo} />
          <h3 style={{ marginBottom: '20px', fontSize: '24px', fontWeight: '700' }}>Join Our Client Network</h3>
          <button style={{ ...styles.button, ...styles.googleButton }} onClick={handleGoogleSignIn}>
            Continue with Google
          </button>
          <button style={{ ...styles.button, ...styles.emailButton }} onClick={handleLogin}>
            Continue with Email
          </button>
          <p style={{ margin: '20px 0', color: 'darkgray', fontSize: '14px' }}>
            Already have an account? <Link to="/login" style={styles.link}>Log In</Link>
          </p>
          <p>Need an account? <button style={{ ...styles.button, ...styles.emailButton }} onClick={handleSignUp}>Sign Up Now</button></p>
        </div>
      </div>
    </div>
  );
}
