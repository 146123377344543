import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, updateDoc, doc, addDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import ClientNavbar from './clientnavbar';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faEdit,
  faCalendar,
  faUser,
  faEnvelope,
  faPhone,
  faBriefcase,
  faDollarSign,
  faBuilding,
  faInfoCircle,
  faClock,
  faDownload,
  faSignature,
  faVideo,
  faStar,
  faSearch,
  faFilter,
  faChevronDown,
  faChevronUp,
  faFileContract,
  faHandshake,
  faExclamationTriangle,
  faUpload,
  faArrowRight,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import SignatureCanvas from 'react-signature-canvas';
import { Chart, registerables } from 'chart.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Chart.register(...registerables);

const stripePromise = loadStripe('pk_test_51PJcw62L5LTeMJgucbFgthFQmBUE1Hm7ZCKck5lwp6RJESyxOviyluvNMdboSnQFvRBa7mTFMkW90r171HlubENP00OuF9qi9Q');

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Header = styled.header`
  background: linear-gradient(135deg, rgb(10, 88, 202), rgb(8, 70, 161));
  color: white;
  padding: 40px 20px;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;
  animation: ${slideIn} 0.5s ease-out;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  opacity: 0.8;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 30px;
`;

const Card = styled(motion.div)`
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const CardTitle = styled.h2`
  font-size: 1.4rem;
  color: #333;
  margin: 0;
`;

const CardContent = styled.div`
  color: #666;
`;

const Button = styled.button`
  background-color: rgb(10, 88, 202);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  font-weight: bold;

  &:hover {
    background-color: rgb(8, 70, 161);
    transform: translateY(-2px);
  }

  ${props => props.secondary && css`
    background-color: #2196F3;
    &:hover {
      background-color: #1e88e5;
    }
  `}

  ${props => props.danger && css`
    background-color: #f44336;
    &:hover {
      background-color: #e53935;
    }
  `}

  svg {
    margin-right: 5px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: rgb(10, 88, 202);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: rgb(10, 88, 202);
  }
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: rgb(10, 88, 202);
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border-radius: 25px;
  padding: 5px 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;
  margin-right: 15px;

  input {
    border: none;
    outline: none;
    padding: 8px;
    width: 100%;
    font-size: 1rem;
  }

  svg {
    color: #888;
    margin-right: 10px;
  }
`;

const FilterButton = styled.button`
  background: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  font-weight: bold;

  &:hover {
    background: #f0f0f0;
    transform: translateY(-2px);
  }

  svg {
    margin-left: 5px;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  background: white;
  border-radius: 25px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Tab = styled.button`
  background: ${props => props.active ? 'rgb(10, 88, 202)' : 'transparent'};
  color: ${props => props.active ? 'white' : '#333'};
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  &:hover {
    background: ${props => props.active ? 'rgb(8, 70, 161)' : '#f0f0f0'};
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const PageButton = styled.button`
  background: ${props => props.active ? 'rgb(10, 88, 202)' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;

  &:hover {
    background: ${props => props.active ? 'rgb(8, 70, 161)' : '#f0f0f0'};
    transform: translateY(-2px);
  }
`;

const PriorityTag = styled.span`
  background-color: #FFD700;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 10px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`;

const InterviewSection = styled.div`
  margin-top: 30px;
  background: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const InterviewTitle = styled.h2`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const InterviewCard = styled.div`
  background: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const InterviewInfo = styled.p`
  margin: 10px 0;
  font-size: 1rem;
  color: #555;
`;

const ContractForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  min-height: 100px;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: rgb(10, 88, 202);
  }
`;

const SignatureBox = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  overflow: hidden;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${props => props.active ? 'rgb(10, 88, 202)' : '#ddd'};
  color: ${props => props.active ? 'white' : '#333'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  font-weight: bold;
  transition: all 0.3s ease;
`;

const StepContent = styled.div`
  margin-bottom: 20px;
`;

const StepButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ApplicantsPage = () => {
  const [applicants, setApplicants] = useState([]);
  const [recentApplicants, setRecentApplicants] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [applicantsPerPage] = useState(9);
  const [activeTab, setActiveTab] = useState('all');
  const [totalJobs, setTotalJobs] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [interviewDate, setInterviewDate] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [scheduledMeetups, setScheduledMeetups] = useState([]);
  const [rescheduleId, setRescheduleId] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [contractDetails, setContractDetails] = useState({ title: '', terms: '', signature: '' });
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [uploadedContract, setUploadedContract] = useState(null);
  const [contractStep, setContractStep] = useState(1);
  const navigate = useNavigate();
  const auth = getAuth();
  const signatureCanvasRef = useRef(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setAccessToken(tokenResponse.access_token);
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: 'https://www.googleapis.com/auth/calendar.events',
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserEmail(user.email);
        await fetchApplicants(user.email);
        await fetchTotalJobs(user.email);
        await fetchScheduledMeetups(user.email);
        await fetchRecentApplicants(user.email);
      } else {
        navigate('/clientlogin');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchApplicants = useCallback(async (userEmail) => {
    const jobQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobQuery);
    const jobIds = jobsSnapshot.docs.map((doc) => doc.id);

    setTotalJobs(jobsSnapshot.size);

    if (jobIds.length > 0) {
      const applicantsQuery = query(collection(db, 'jobApplications'), where('jobID', 'in', jobIds));
      const applicantsSnapshot = await getDocs(applicantsQuery);
      const applicantsData = await Promise.all(
        applicantsSnapshot.docs.map(async (doc) => {
          const applicantData = doc.data();
          const profileQuery = query(collection(db, 'profiles'), where('email', '==', applicantData.email));
          const profileSnapshot = await getDocs(profileQuery);
          const profileImageUrl = profileSnapshot.docs.length > 0 ? profileSnapshot.docs[0].data().profileImage : null;
          const jobDetails = jobsSnapshot.docs.find((jobDoc) => jobDoc.id === doc.data().jobID)?.data() || {};
          
          return {
            id: doc.id,
            ...applicantData,
            jobTitle: jobDetails.title || 'Unknown Job Title',
            jobDetails,
            profileImageUrl,
            priority: applicantData.priority || false,
            stage: applicantData.stage || 'new',
          };
        })
      );

      setApplicants(applicantsData);
    } else {
      setApplicants([]);
    }
  }, []);

  const fetchTotalJobs = async (userEmail) => {
    const jobsQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobsQuery);
    setTotalJobs(jobsSnapshot.size);
  };

  const fetchScheduledMeetups = async (userEmail) => {
    const interviewsQuery = query(collection(db, 'interviews'), where('userEmail', '==', userEmail));
    const interviewsSnapshot = await getDocs(interviewsQuery);
    const interviewsData = interviewsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      interviewDate: doc.data().interviewDate.toDate(),
    }));

    setScheduledMeetups(interviewsData);
  };

  const fetchRecentApplicants = async (userEmail) => {
    const jobQuery = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const jobsSnapshot = await getDocs(jobQuery);
    const jobIds = jobsSnapshot.docs.map((doc) => doc.id);

    if (jobIds.length > 0) {
      const recentApplicantsQuery = query(
        collection(db, 'jobApplications'),
        where('jobID', 'in', jobIds),
        where('createdAt', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
      );
      const recentApplicantsSnapshot = await getDocs(recentApplicantsQuery);
      const recentApplicantsData = await Promise.all(
        recentApplicantsSnapshot.docs.map(async (doc) => {
          const applicantData = doc.data();
          const profileQuery = query(collection(db, 'profiles'), where('email', '==', applicantData.email));
          const profileSnapshot = await getDocs(profileQuery);
          const profileImageUrl = profileSnapshot.docs.length > 0 ? profileSnapshot.docs[0].data().profileImage : null;
          const jobDetails = jobsSnapshot.docs.find((jobDoc) => jobDoc.id === doc.data().jobID)?.data() || {};
          
          return {
            id: doc.id,
            ...applicantData,
            jobTitle: jobDetails.title || 'Unknown Job Title',
            jobDetails,
            profileImageUrl,
            priority: applicantData.priority || false,
          };
        })
      );

      setRecentApplicants(recentApplicantsData);
    } else {
      setRecentApplicants([]);
    }
  };

  const handleStageChange = async (applicantId, newStage) => {
    await updateApplicantStage(applicantId, newStage);
    setApplicants(applicants.map((app) => 
      app.id === applicantId ? { ...app, stage: newStage } : app
    ));
    toast.success(`Applicant moved to ${newStage} stage successfully!`);
  };

  const updateApplicantStage = async (applicantId, newStage) => {
    const applicantRef = doc(db, 'jobApplications', applicantId);
    await updateDoc(applicantRef, { stage: newStage });
  };

  const handleMarkPriority = async (applicantId) => {
    const applicantRef = doc(db, 'jobApplications', applicantId);
    const updatedApplicants = applicants.map((app) => {
      if (app.id === applicantId) {
        const newPriority = !app.priority;
        updateDoc(applicantRef, { priority: newPriority });
        return { ...app, priority: newPriority };
      }
      return app;
    });
    setApplicants(updatedApplicants);
    toast.success('Priority status updated successfully!');
  };

  const handleScheduleInterview = async (applicantId) => {
    const applicant = applicants.find(app => app.id === applicantId);
    if (!applicant) return;

    setSelectedApplicant(applicant);
    setModalContent(
      <div>
        <h2>Schedule Interview for {applicant.name}</h2>
        <DatePicker
          selected={interviewDate}
          onChange={(date) => setInterviewDate(date)}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date()}
          inline
        />
        <Button onClick={() => confirmScheduleInterview(applicant)}>
          <FontAwesomeIcon icon={faCalendar} /> Confirm Interview
        </Button>
      </div>
    );
    setShowModal(true);
  };

  const confirmScheduleInterview = async (applicant) => {
    if (!interviewDate) {
      toast.error('Please select an interview date.');
      return;
    }

    if (!accessToken) {
      login();
      return;
    }

    try {
      const response = await createGoogleMeetEvent(interviewDate, accessToken);
      const meetingLink = response.data.hangoutLink;

      const interviewRef = await addDoc(collection(db, 'interviews'), {
        applicantId: applicant.id,
        applicantEmail: applicant.email,
        applicantName: applicant.name,
        jobTitle: applicant.jobTitle,
        interviewDate,
        meetingLink,
        userEmail,
      });

      await addNotification(applicant.email, `Your interview is scheduled on ${interviewDate.toLocaleString()}. Meeting link: ${meetingLink}`);
      await addClientNotification(
        userEmail,
        `Interview with ${applicant.name} (${applicant.email}) is scheduled on ${interviewDate.toLocaleString()}. Meeting link: ${meetingLink}`
      );

      setScheduledMeetups([
        ...scheduledMeetups,
        { id: interviewRef.id, ...applicant, interviewDate, meetingLink },
      ]);
      setInterviewDate(null);
      setShowModal(false);
      toast.success('Interview scheduled successfully!');
    } catch (error) {
      console.error('Error creating Google Meet event:', error);
      toast.error('There was an error scheduling the interview. Please try again.');
    }
  };

  const createGoogleMeetEvent = (date, token) => {
    const event = {
      summary: 'Interview',
      start: { dateTime: date.toISOString(), timeZone: 'UTC' },
      end: {
        dateTime: new Date(date.getTime() + 60 * 60 * 1000).toISOString(),
        timeZone: 'UTC',
      },
      conferenceData: {
        createRequest: {
          requestId: 'sample123',
          conferenceSolutionKey: {
            type: 'hangoutsMeet',
          },
        },
      },
    };

    return axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events?conferenceDataVersion=1', event, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  };

  const handleCreateContract = (applicantId) => {
    const applicant = applicants.find(app => app.id === applicantId);
    if (!applicant) return;

    setSelectedApplicant(applicant);
    setContractStep(1);
    setModalContent(
      <ContractForm onSubmit={handleContractSubmit}>
        <h2>Create Contract for {applicant.name}</h2>
        <StepIndicator>
          <Step active={contractStep === 1}>1</Step>
          <Step active={contractStep === 2}>2</Step>
          <Step active={contractStep === 3}>3</Step>
        </StepIndicator>
        {contractStep === 1 && (
          <StepContent>
            <Input
              placeholder="Contract Title"
              value={contractDetails.title}
              onChange={(e) => setContractDetails({ ...contractDetails, title: e.target.value })}
            />
            <TextArea
              placeholder="Contract Terms"
              value={contractDetails.terms}
              onChange={(e) => setContractDetails({ ...contractDetails, terms: e.target.value })}
            />
            <StepButtons>
              <Button onClick={() => setContractStep(2)}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </StepButtons>
          </StepContent>
        )}
        {contractStep === 2 && (
          <StepContent>
            <SignatureBox>
              <SignatureCanvas
                ref={signatureCanvasRef}
                penColor='black'
                canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                onEnd={() => setContractDetails({ ...contractDetails, signature: signatureCanvasRef.current.toDataURL() })}
              />
            </SignatureBox>
            <Button onClick={() => signatureCanvasRef.current.clear()}>Clear Signature</Button>
            <StepButtons>
              <Button onClick={() => setContractStep(1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Previous
              </Button>
              <Button onClick={() => setContractStep(3)}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </StepButtons>
          </StepContent>
        )}
        {contractStep === 3 && (
          <StepContent>
            <h3>Review Contract</h3>
            <p><strong>Title:</strong> {contractDetails.title}</p>
            <p><strong>Terms:</strong> {contractDetails.terms}</p>
            <img src={contractDetails.signature} alt="Signature" style={{maxWidth: '100%', marginTop: '10px'}} />
            <StepButtons>
              <Button onClick={() => setContractStep(2)}>
                <FontAwesomeIcon icon={faArrowLeft} /> Previous
              </Button>
              <Button type="submit">
                <FontAwesomeIcon icon={faHandshake} /> Create Contract
              </Button>
            </StepButtons>
          </StepContent>
        )}
      </ContractForm>
    );
    setShowModal(true);
  };

  const handleContractSubmit = async (e) => {
    e.preventDefault();
    if (!contractDetails.title || !contractDetails.terms || !contractDetails.signature) {
      toast.error('Please fill in all contract details and sign the contract.');
      return;
    }

    try {
      await addDoc(collection(db, 'contracts'), {
        applicantId: selectedApplicant.id,
        applicantName: selectedApplicant.name,
        applicantEmail: selectedApplicant.email,
        clientEmail: userEmail,
        ...contractDetails,
        createdAt: serverTimestamp(),
      });

      await handleStageChange(selectedApplicant.id, 'hired');
      setShowModal(false);
      toast.success('Contract created and applicant hired successfully!');
    } catch (error) {
      console.error('Error creating contract:', error);
      toast.error('There was an error creating the contract. Please try again.');
    }
  };

  const addNotification = async (email, message) => {
    await addDoc(collection(db, 'notifications'), {
      email,
      message,
      timestamp: new Date(),
      userId: auth.currentUser.uid,
    });
  };

  const addClientNotification = async (email, message) => {
    await addDoc(collection(db, 'notifications'), {
      email,
      message,
      timestamp: new Date(),
      userId: auth.currentUser.uid,
    });
  };

  const handleRescheduleInterview = (interviewId) => {
    const interview = scheduledMeetups.find((meetup) => meetup.id === interviewId);
    if (!interview) return;

    setSelectedApplicant(interview);
    setModalContent(
      <div>
        <h2>Reschedule Interview for {interview.applicantName}</h2>
        <DatePicker
          selected={interviewDate || new Date(interview.interviewDate)}
          onChange={(date) => setInterviewDate(date)}
          showTimeSelect
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date()}
          inline
        />
        <Button onClick={() => confirmRescheduleInterview(interview)}>
        <FontAwesomeIcon icon={faCalendar} /> Confirm Reschedule
        </Button>
      </div>
    );
    setShowModal(true);
  };

  const confirmRescheduleInterview = async (interview) => {
    if (!interviewDate) {
      toast.error('Please select a new interview date.');
      return;
    }

    if (!accessToken) {
      login();
      return;
    }

    try {
      const response = await createGoogleMeetEvent(interviewDate, accessToken);
      const meetingLink = response.data.hangoutLink;

      const interviewRef = doc(db, 'interviews', interview.id);
      await updateDoc(interviewRef, {
        interviewDate,
        meetingLink,
      });

      await addNotification(interview.applicantEmail, `Your interview has been rescheduled to ${interviewDate.toLocaleString()}. New meeting link: ${meetingLink}`);
      await addClientNotification(
        userEmail,
        `Interview with ${interview.applicantName} (${interview.applicantEmail}) has been rescheduled to ${interviewDate.toLocaleString()}. New meeting link: ${meetingLink}`
      );

      setScheduledMeetups(
        scheduledMeetups.map((meetup) =>
          meetup.id === interview.id ? { ...meetup, interviewDate, meetingLink } : meetup
        )
      );
      setInterviewDate(null);
      setShowModal(false);
      toast.success('Interview rescheduled successfully!');
    } catch (error) {
      console.error('Error rescheduling Google Meet event:', error);
      toast.error('There was an error rescheduling the interview. Please try again.');
    }
  };

  const handleCancelInterview = async (interviewId) => {
    const interview = scheduledMeetups.find((meetup) => meetup.id === interviewId);
    if (!interview) return;

    try {
      await deleteDoc(doc(db, 'interviews', interviewId));
      await addNotification(interview.applicantEmail, `Your interview scheduled on ${interview.interviewDate.toLocaleString()} has been canceled.`);
      await addClientNotification(userEmail, `Interview with ${interview.applicantName} (${interview.applicantEmail}) scheduled on ${interview.interviewDate.toLocaleString()} has been canceled.`);

      setScheduledMeetups(scheduledMeetups.filter((meetup) => meetup.id !== interviewId));
      toast.success('Interview canceled successfully!');
    } catch (error) {
      console.error('Error canceling interview:', error);
      toast.error('There was an error canceling the interview. Please try again.');
    }
  };

  const filteredApplicants = useMemo(() => {
    return applicants
      .filter((applicant) => {
        const searchFields = [
          applicant.name,
          applicant.email,
          applicant.jobTitle,
          applicant.jobDetails?.type,
          applicant.jobDetails?.industry,
        ];
        return searchFields.some(field => 
          field && field.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .filter((applicant) => activeTab === 'all' || applicant.stage === activeTab)
      .sort((a, b) => {
        if (a.priority === b.priority) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
        return b.priority - a.priority;
      });
  }, [applicants, searchTerm, activeTab]);

  const indexOfLastApplicant = currentPage * applicantsPerPage;
  const indexOfFirstApplicant = indexOfLastApplicant - applicantsPerPage;
  const currentApplicants = filteredApplicants.slice(indexOfFirstApplicant, indexOfLastApplicant);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredApplicants.length / applicantsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <ClientNavbar />
      <Container>
        <Header>
          <Title>Applicants Dashboard</Title>
          <Subtitle>Manage and track your job applicants efficiently</Subtitle>
        </Header>

        <FilterContainer>
          <SearchBar>
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search applicants"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBar>
          <FilterButton>
            Filter <FontAwesomeIcon icon={faFilter} />
          </FilterButton>
        </FilterContainer>

        <TabContainer>
          <Tab active={activeTab === 'all'} onClick={() => setActiveTab('all')}>All</Tab>
          <Tab active={activeTab === 'new'} onClick={() => setActiveTab('new')}>New</Tab>
          <Tab active={activeTab === 'shortlisted'} onClick={() => setActiveTab('shortlisted')}>Shortlisted</Tab>
          <Tab active={activeTab === 'interview'} onClick={() => setActiveTab('interview')}>Interview</Tab>
          <Tab active={activeTab === 'hired'} onClick={() => setActiveTab('hired')}>Hired</Tab>
          <Tab active={activeTab === 'rejected'} onClick={() => setActiveTab('rejected')}>Rejected</Tab>
        </TabContainer>

        <CardGrid>
          {currentApplicants.map((applicant) => (
            <Card
              key={applicant.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <CardHeader>
                <CardTitle>{applicant.jobTitle}</CardTitle>
                {applicant.priority && (
                  <PriorityTag>
                    <FontAwesomeIcon icon={faStar} /> Priority
                  </PriorityTag>
                )}
              </CardHeader>
              <CardContent>
                <p><FontAwesomeIcon icon={faUser} /> {applicant.name}</p>
                <p><FontAwesomeIcon icon={faEnvelope} /> {applicant.email}</p>
                <p><FontAwesomeIcon icon={faPhone} /> {applicant.phone}</p>
                <p><FontAwesomeIcon icon={faBriefcase} /> {applicant.jobDetails?.type}</p>
                <p><FontAwesomeIcon icon={faBuilding} /> {applicant.jobDetails?.industry}</p>
              </CardContent>
              <Button onClick={() => handleStageChange(applicant.id, 'shortlisted')}>
                <FontAwesomeIcon icon={faCheck} /> Shortlist
              </Button>
              <Button secondary onClick={() => handleScheduleInterview(applicant.id)}>
                <FontAwesomeIcon icon={faCalendar} /> Schedule Interview
              </Button>
              <Button onClick={() => handleCreateContract(applicant.id)}>
                <FontAwesomeIcon icon={faFileContract} /> Create Contract
              </Button>
              <Button danger onClick={() => handleStageChange(applicant.id, 'rejected')}>
                <FontAwesomeIcon icon={faTimes} /> Reject
              </Button>
              <Button onClick={() => handleMarkPriority(applicant.id)}>
                <FontAwesomeIcon icon={faStar} /> {applicant.priority ? 'Unmark Priority' : 'Mark Priority'}
              </Button>
            </Card>
          ))}
        </CardGrid>

        <Pagination>
          {pageNumbers.map((number) => (
            <PageButton key={number} active={currentPage === number} onClick={() => paginate(number)}>
              {number}
            </PageButton>
          ))}
        </Pagination>

        <InterviewSection>
          <InterviewTitle>Scheduled Interviews</InterviewTitle>
          {scheduledMeetups.length > 0 ? (
            scheduledMeetups.map((interview) => (
              <InterviewCard key={interview.id}>
                <InterviewInfo><strong>Applicant:</strong> {interview.applicantName}</InterviewInfo>
                <InterviewInfo><strong>Job:</strong> {interview.jobTitle}</InterviewInfo>
                <InterviewInfo><strong>Date:</strong> {interview.interviewDate.toLocaleString()}</InterviewInfo>
                <InterviewInfo><strong>Meeting Link:</strong> <a href={interview.meetingLink} target="_blank" rel="noopener noreferrer">Join Meeting</a></InterviewInfo>
                <Button secondary onClick={() => handleRescheduleInterview(interview.id)}>
                  <FontAwesomeIcon icon={faEdit} /> Reschedule
                </Button>
                <Button danger onClick={() => handleCancelInterview(interview.id)}>
                  <FontAwesomeIcon icon={faTimes} /> Cancel
                </Button>
              </InterviewCard>
            ))
          ) : (
            <p>No interviews scheduled yet.</p>
          )}
        </InterviewSection>

        {showModal && (
          <Modal
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
            {modalContent}
          </Modal>
        )}
        {showModal && <Overlay onClick={() => setShowModal(false)} />}
      </Container>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

const ApplicantsPageComponent = () => (
  <GoogleOAuthProvider clientId="670669559995-91vhb1856fk239ff8bg2gd5km8b0r812.apps.googleusercontent.com">
    <ApplicantsPage />
  </GoogleOAuthProvider>
);

export default ApplicantsPageComponent;