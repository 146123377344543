// src/components/FormSelect.js
import React from 'react';
import Select from 'react-select';
import { FiBriefcase, FiMapPin, FiSearch } from "../assets/icons/vander";

const industries = [
    'Marketing', 'Fullstack Engineer', 'Data Science', 'Product Management', 'Graphic Design',
    'UI/UX Design', 'Software Development', 'Project Management', 'Sales', 'Customer Support',
    'Human Resources', 'Finance', 'Legal', 'Education', 'Healthcare',
    'Real Estate', 'Logistics', 'Manufacturing', 'Hospitality', 'Other'
].map(industry => ({ value: industry, label: industry }));

const jobTypes = [
    { value: 'Full Time', label: 'Full Time' },
    { value: 'Part Time', label: 'Part Time' },
    { value: 'Remote', label: 'Remote' }
];

const countries = [
    "USA", "Canada", "United Kingdom", "Australia", "Germany", "France", "Spain", "Italy",
    "Brazil", "China", "India", "Japan", "Russia", "South Africa", "Mexico", "Indonesia",
    "Netherlands", "Turkey", "Saudi Arabia", "Switzerland", "Argentina", "Sweden", "Nigeria",
    "Poland", "Belgium", "Thailand", "Austria", "Norway", "Iran", "United Arab Emirates",
    "Colombia", "South Korea", "Malaysia", "Singapore", "Denmark", "Finland", "Chile",
    "Ireland", "Pakistan", "Egypt", "Philippines", "Portugal", "Czech Republic", "Romania",
    "Vietnam", "Israel", "Greece", "Hong Kong", "Peru"
].map(country => ({ value: country.toLowerCase(), label: country }));

export default function FormSelect({ keyword, setKeyword, selectedLocation, setSelectedLocation, selectedType, setSelectedType, selectedIndustry, setSelectedIndustry }) {
    const handleKeywordChange = (event) => {
        setKeyword(event.target.value);
    };

    const handleLocationChange = (selectedOption) => {
        setSelectedLocation(selectedOption);
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedType(selectedOption);
    };

    const handleIndustryChange = (selectedOption) => {
        setSelectedIndustry(selectedOption);
    };

    return (
        <form className="card-body text-start">
            <div className="registration-form text-dark text-start">
                <div className="row g-lg-0">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="mb-3 mb-sm-0">
                            <div className="filter-search-form position-relative filter-border">
                                <FiSearch className="fea icon-20 icons"/>
                                <input
                                    type="text"
                                    className="form-control filter-input-box bg-light border-0"
                                    placeholder="Search your keywords"
                                    value={keyword}
                                    onChange={handleKeywordChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="mb-3 mb-sm-0">
                            <div className="filter-search-form position-relative filter-border">
                                <FiMapPin className="fea icon-20 icons"/>
                                <Select
                                    options={countries}
                                    onChange={handleLocationChange}
                                    value={selectedLocation}
                                    placeholder="Select a location"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="mb-3 mb-sm-0">
                            <div className="filter-search-form position-relative filter-border">
                                <FiBriefcase className="fea icon-20 icons"/>
                                <Select
                                    options={jobTypes}
                                    onChange={handleTypeChange}
                                    value={selectedType}
                                    placeholder="Select job type"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="mb-3 mb-sm-0">
                            <div className="filter-search-form position-relative filter-border">
                                <Select
                                    options={industries}
                                    onChange={handleIndustryChange}
                                    value={selectedIndustry}
                                    placeholder="Select an industry"
                                    isClearable={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
