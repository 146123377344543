import React, { useState, useRef, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ClientNavbar from './clientnavbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding, faGlobe, faCalendarAlt, faClock, faBriefcase, faInfoCircle,
  faBold, faItalic, faUnderline, faUndo, faRedo, faAlignLeft, faAlignCenter,
  faAlignRight, faAlignJustify, faListUl, faListOl, faIndent, faOutdent,
  faLink, faUnlink, faEraser, faFont, faHeading, faTextHeight
} from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import OpenAI from 'openai';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as pdfjsLib from 'pdfjs-dist';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const HeroSection = styled.section`
  background-image: url('/path/to/your/image.jpg');
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  color: white;
  text-align: center;
  margin-bottom: 0;
`;

const HeroCaption = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`;

const InputFileButton = styled.label`
  display: inline-block;
  padding: 10px 15px;
  color: #fff;
  background: rgb(10, 88, 202);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
`;

const InputFile = styled.input`
  display: none;
`;

const FilePreviewContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #e9ecef;
`;

const JobDetailContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const JobDetailHeader = styled.div`
  display: flex;
  align-items: center;
`;

const JobDetailTitle = styled.h2`
  font-weight: 600;
  color: #333;
  margin-left: 20px;
`;

const JobDetailList = styled.div`
  margin-top: 20px;
`;

const JobDetailItem = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  color: #6c757d;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const ProgressWrapper = styled.div`
  margin-top: 30px;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.width}%;
  background: rgb(10, 88, 202);
  border-radius: 5px;
  height: 10px;
`;

const FormSection = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
`;

const VideoContainer = styled.div`
  margin-top: 20px;
  position: relative;
`;

const PersonalityQuestion = styled.div`
  margin-bottom: 20px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const ResumeBuilderContainer = styled.div`
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
`;

const EditableField = styled.div`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  background-color: #fff;
  margin-bottom: 10px;
  min-height: 300px;
  resize: vertical;
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  display: inline-block;
  padding: 8px 12px;
  color: #fff;
  background-color: ${(props) => props.color || 'rgb(10, 88, 202)'};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    background-color: ${(props) => props.hoverColor || '#0056b3'};
  }
`;

const Notification = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgb(10, 88, 202);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const personalityQuestions = [
  'How do you handle conflict?',
  'How do you approach new challenges?',
  'How do you motivate others?',
  'How do you prefer to communicate?',
  'How do you handle a stressful situation?',
  'How do you prefer to work in a team?',
  'How do you approach tasks at work?',
  'How do you make decisions?',
  'How do you respond to criticism?',
  'What role do you prefer in a project?',
  'How do you react to tight deadlines?',
  'What motivates you the most at work?',
  'How do you handle change?',
  'How do you solve problems?',
  'What is your approach to learning new skills?',
];

const resumeTemplates = [
  'Template 1',
  'Template 2',
  'Template 3',
];

const templatesStyles = {
  'Template 1': {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '20px',
  },
  'Template 2': {
    fontFamily: 'Georgia, serif',
    color: '#000',
    backgroundColor: '#f4f4f4',
    border: '2px solid #ccc',
    borderRadius: '10px',
    padding: '25px',
  },
  'Template 3': {
    fontFamily: 'Helvetica, sans-serif',
    color: '#444',
    backgroundColor: '#fafafa',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '15px',
  },
};

export default function JobApply() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const jobDetails = state?.jobDetails || {};
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    jobType: jobDetails.type || '',
    coverLetter: null,
    resume: null,
    termsAccepted: false,
    personalityAnswers: new Array(personalityQuestions.length).fill(''),
  });
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [videoError, setVideoError] = useState('');
  const [resumeContent, setResumeContent] = useState('');
  const [updatedResume, setUpdatedResume] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(resumeTemplates[0]);
  const [showProgressCircle, setShowProgressCircle] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [notification, setNotification] = useState('');
  const [showRewritePrompt, setShowRewritePrompt] = useState(false);
  const [coverLetterContent, setCoverLetterContent] = useState('');
  const [updatedCoverLetter, setUpdatedCoverLetter] = useState('');
  const [isCoverLetterEditing, setIsCoverLetterEditing] = useState(false);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const resumeRef = useRef(null);
  const coverLetterRef = useRef(null);

  const openai = new OpenAI({
    apiKey: 'sk-None-cKKUvLMKNBQp0IlnfCr2T3BlbkFJ3VME98HSdkFPkoSMkDy7',
    dangerouslyAllowBrowser: true,
  });

  useEffect(() => {
    return () => {
      if (webcamRef.current && webcamRef.current.srcObject) {
        const tracks = webcamRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, type, checked, files, value } = e.target;
    if (type === 'file') {
      setFormData(prev => ({ ...prev, [name]: files[0] }));
      if (name === 'resume') {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const pdfDoc = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
          const numPages = pdfDoc.numPages;
          let text = '';

          for (let i = 1; i <= numPages; i++) {
            const page = await pdfDoc.getPage(i);
            const content = await page.getTextContent();
            const pageText = content.items.map(item => item.str).join(' ');
            text += pageText + ' ';
          }

          setResumeContent(text);
          setShowRewritePrompt(true);
          console.log('Resume Content:', text);
        };
        reader.readAsArrayBuffer(files[0]);
      } else if (name === 'coverLetter') {
        const reader = new FileReader();
        reader.onload = async (event) => {
          setCoverLetterContent(event.target.result);
          setShowRewritePrompt(true);
        };
        reader.readAsText(files[0]);
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
      console.log(`Form Data Updated: ${name} - ${value}`);
    }
  };

  const handleNextStep = () => {
    console.log(`Next Step: ${step + 1}`);
    setStep(prevStep => Math.min(prevStep + 1, 6));
  };
  const handlePreviousStep = () => {
    console.log(`Previous Step: ${step - 1}`);
    setStep(prevStep => Math.max(prevStep - 1, 1));
  };

  const handlePersonalityAnswerChange = (index, answer) => {
    const updatedAnswers = [...formData.personalityAnswers];
    updatedAnswers[index] = answer;
    setFormData(prev => ({ ...prev, personalityAnswers: updatedAnswers }));
    console.log(`Personality Answer Updated: ${index} - ${answer}`);
  };

  const handleStartRecording = async () => {
    setVideoError('');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      webcamRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const url = URL.createObjectURL(event.data);
          setRecordedVideos((prev) => prev.concat({ url, blob: event.data }));
          console.log('Recorded Video:', url);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log('Recording started');
    } catch (error) {
      setVideoError('Could not start recording. Please allow camera and microphone access.');
      console.error('Error starting recording: ', error);
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      console.log('Recording stopped');
    }
  };

  const handleDeleteVideo = (index) => {
    setRecordedVideos((prev) => prev.filter((_, i) => i !== index));
    console.log(`Deleted Video: ${index}`);
  };

  const extractPersonalInfo = (resumeContent) => {
    const nameRegex = /^.*\n/;
    const contactInfoRegex = /^.*(\n.*){2,5}/;
    const profileRegex = /Profile\s*Description:.*\n((.*\n)*?)(?=Work\s*Experience:|Skills:|$)/i;

    const nameMatch = resumeContent.match(nameRegex);
    const contactInfoMatch = resumeContent.match(contactInfoRegex);
    const profileMatch = resumeContent.match(profileRegex);

    const personalInfo = {
      name: nameMatch ? nameMatch[0].trim() : '',
      contactInfo: contactInfoMatch ? contactInfoMatch[0].trim() : '',
      profile: profileMatch ? profileMatch[0].trim() : ''
    };

    console.log('Extracted Personal Info:', personalInfo);
    return personalInfo;
  };

  const generateDetailedPrompt = (part, jobDescription, jobSkills) => `
  Scan the whole resume, rewrite each section to overhaul, and match the job description and job skills fully. The generated resume should have a beautiful orderly look for modern resumes. Be creative and detailed. Follow all instructions.
  
  ${jobDescription}
  
  ${jobSkills}
  
  ${part}
  `;

  const generateCoverLetterPrompt = (coverLetterContent, jobDescription) => `
  Edit the following cover letter to align it with the job description and required skills without generating a completely new cover letter. The rewritten content should begin where the original cover letter begins and end where the original cover letter ends. The total length of the edited cover letter should be within 100 characters of the original. Only add content as necessary to reflect the job description and skills.

  Job Description:
  ${jobDescription}
  
  Cover Letter:
  ${coverLetterContent}
  `;

  const splitText = (text, maxLength) => {
    const parts = [];
    let currentIndex = 0;

    while (currentIndex < text.length) {
      parts.push(text.slice(currentIndex, currentIndex + maxLength));
      currentIndex += maxLength;
    }

    console.log('Split Text:', parts);
    return parts;
  };

  const formatResume = (personalInfo, updatedProfile, updatedSkills, updatedWorkExperience, remainingContent) => `
  ${personalInfo.name}

  ${personalInfo.contactInfo}

  ${updatedProfile.trim()}

  ${updatedSkills.trim()}

  ${updatedWorkExperience.trim()}

  ${remainingContent.trim()}
  `;

  const handleResumeRewrite = async () => {
    setShowProgressCircle(true);
    try {
      const jobDescription = jobDetails.description ? jobDetails.description.slice(0, 1000) : '';
      const jobSkills = jobDetails.skills ? jobDetails.skills.slice(0, 1000) : '';

      if (!resumeContent) {
        throw new Error('Resume content is not available');
      }

      const resumeParts = splitText(resumeContent, 3000);

      const personalInfo = extractPersonalInfo(resumeContent);

      let updatedProfile = '';
      let updatedSkills = '';
      let updatedWorkExperience = '';
      let remainingContent = '';

      for (let part of resumeParts) {
        const prompt = generateDetailedPrompt(part, jobDescription, jobSkills);
        const response = await openai.chat.completions.create({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: prompt
            }
          ],
        });

        const content = response.choices[0].message.content;

        if (content.includes('### Profile Summary ###')) {
          updatedProfile += content;
        } else if (content.includes('### Skills ###')) {
          updatedSkills += content;
        } else if (content.includes('### Work Experience ###')) {
          updatedWorkExperience += content;
        } else {
          remainingContent += content;
        }
      }

      const combinedUpdatedResume = formatResume(personalInfo, updatedProfile, updatedSkills, updatedWorkExperience, remainingContent);
      const maxLength = 6000;
      if (combinedUpdatedResume.length > maxLength) {
        combinedUpdatedResume = combinedUpdatedResume.slice(0, maxLength);
      }

      setUpdatedResume(combinedUpdatedResume);
      console.log('Final Updated Resume:', combinedUpdatedResume);
      setIsEditing(true);
      setShowRewritePrompt(false);
    } catch (error) {
      console.error('Error generating updated resume:', error);
      alert('Failed to update resume.');
    }
    setShowProgressCircle(false);
  };

  const handleCoverLetterRewrite = async () => {
    setShowProgressCircle(true);
    try {
      const jobDescription = jobDetails.description ? jobDetails.description.slice(0, 1000) : '';

      if (!coverLetterContent) {
        throw new Error('Cover letter content is not available');
      }

      const maxTokens = 1500; // Smaller chunk size to ensure we stay within limits
      const coverLetterParts = splitText(coverLetterContent, maxTokens);

      let updatedCoverLetterContent = '';

      for (let part of coverLetterParts) {
        const prompt = generateCoverLetterPrompt(part, jobDescription);

        const response = await openai.chat.completions.create({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: prompt
            }
          ],
          max_tokens: maxTokens // Limiting the output size to ensure we stay within limits
        });

        updatedCoverLetterContent += response.choices[0].message.content;
      }

      setUpdatedCoverLetter(updatedCoverLetterContent);
      console.log('Final Updated Cover Letter:', updatedCoverLetterContent);
      setIsCoverLetterEditing(true);
      setShowRewritePrompt(false);
    } catch (error) {
      console.error('Error generating updated cover letter:', error);
      alert('Failed to update cover letter.');
    }
    setShowProgressCircle(false);
  };

  const handleSaveResume = () => {
    setIsEditing(false);
    setNotification('Resume saved successfully!');
  };

  const handleSaveCoverLetter = () => {
    setIsCoverLetterEditing(false);
    setNotification('Cover letter saved successfully!');
  };

  const handleDownloadResume = (format) => {
    const selectedStyle = templatesStyles[selectedTemplate];
    const resumeContentHtml = resumeRef.current?.innerHTML;

    if (!resumeContentHtml) {
      alert('Resume content is not available.');
      return;
    }

    const convertHtmlToPdfMake = (html) => {
      const element = document.createElement('div');
      element.innerHTML = html;
      const pdfContent = [];
      const parseElement = (el) => {
        switch (el.nodeName) {
          case 'B':
          case 'STRONG':
            return { text: el.textContent, bold: true };
          case 'I':
          case 'EM':
            return { text: el.textContent, italics: true };
          case 'U':
            return { text: el.textContent, decoration: 'underline' };
          case 'H1':
            return { text: el.textContent, fontSize: 22, bold: true };
          case 'H2':
            return { text: el.textContent, fontSize: 20, bold: true };
          case 'H3':
            return { text: el.textContent, fontSize: 18, bold: true };
          case 'LI':
            return { text: el.textContent, margin: [0, 0, 0, 5] };
          case 'UL':
          case 'OL':
            return {
              ul: [...el.children].map(parseElement),
              margin: [0, 0, 0, 5]
            };
          case 'DIV':
          case 'P':
          default:
            return { text: el.textContent, margin: [0, 0, 0, 5] };
        }
      };
      pdfContent.push(...[...element.children].map(parseElement));
      return pdfContent;
    };

    const documentDefinition = {
      content: convertHtmlToPdfMake(resumeContentHtml),
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          ...selectedStyle,
        },
        content: {
          fontSize: 12,
          ...selectedStyle,
        },
      },
      defaultStyle: {
        font: selectedStyle.fontFamily,
      },
    };

    if (format === 'pdf') {
      pdfMake.createPdf(documentDefinition).download('Updated_Resume.pdf');
    } else {
      console.log('DOCX download logic not implemented');
    }
    console.log('Resume Downloaded');
    setNotification('Resume downloaded successfully!');
  };

  const handleDownloadCoverLetter = () => {
    const coverLetterContentHtml = coverLetterRef.current?.innerHTML;

    if (!coverLetterContentHtml) {
      alert('Cover letter content is not available.');
      return;
    }

    const convertHtmlToPdfMake = (html) => {
      const element = document.createElement('div');
      element.innerHTML = html;
      const pdfContent = [];
      const parseElement = (el) => {
        switch (el.nodeName) {
          case 'B':
          case 'STRONG':
            return { text: el.textContent, bold: true };
          case 'I':
          case 'EM':
            return { text: el.textContent, italics: true };
          case 'U':
            return { text: el.textContent, decoration: 'underline' };
          case 'H1':
            return { text: el.textContent, fontSize: 22, bold: true };
          case 'H2':
            return { text: el.textContent, fontSize: 20, bold: true };
          case 'H3':
            return { text: el.textContent, fontSize: 18, bold: true };
          case 'LI':
            return { text: el.textContent, margin: [0, 0, 0, 5] };
          case 'UL':
          case 'OL':
            return {
              ul: [...el.children].map(parseElement),
              margin: [0, 0, 0, 5]
            };
          case 'DIV':
          case 'P':
          default:
            return { text: el.textContent, margin: [0, 0, 0, 5] };
        }
      };
      pdfContent.push(...[...element.children].map(parseElement));
      return pdfContent;
    };

    const documentDefinition = {
      content: convertHtmlToPdfMake(coverLetterContentHtml),
      styles: {
        header: {
          fontSize: 22,
          bold: true,
        },
        content: {
          fontSize: 12,
        },
      },
    };

    pdfMake.createPdf(documentDefinition).download('Updated_Cover_Letter.pdf');
    console.log('Cover Letter Downloaded');
    setNotification('Cover letter downloaded successfully!');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let videoURLs = [];
    let coverLetterURL = '';

    if (recordedVideos.length) {
      try {
        for (const video of recordedVideos) {
          const videoRef = ref(storage, `jobApplications/${Date.now()}_video.webm`);
          await uploadBytes(videoRef, video.blob);
          const url = await getDownloadURL(videoRef);
          videoURLs.push(url);
          console.log('Uploaded Video URL:', url);
        }
      } catch (error) {
        console.error('Error uploading video:', error);
        alert('Failed to upload video.');
        return;
      }
    }

    if (formData.coverLetter) {
      const coverLetterRef = ref(storage, `coverLetters/${Date.now()}_${formData.coverLetter.name}`);
      try {
        const coverLetterSnapshot = await uploadBytes(coverLetterRef, formData.coverLetter);
        coverLetterURL = await getDownloadURL(coverLetterSnapshot.ref);
        console.log('Uploaded Cover Letter URL:', coverLetterURL);
      } catch (error) {
        console.error('Error uploading cover letter:', error);
        alert('Failed to upload cover letter.');
        return;
      }
    }

    const applicationData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      jobType: formData.jobType,
      termsAccepted: formData.termsAccepted,
      jobID: jobDetails.id,
      videoURLs,
      coverLetterURL,
      personalityAnswers: formData.personalityAnswers,
      updatedResume,
    };

    try {
      await addDoc(collection(db, 'jobApplications'), applicationData);
      await addDoc(collection(db, 'expertsNotification'), {
        message: `You have applied to ${jobDetails.title} job`,
        email: formData.email,
        timestamp: new Date(),
        jobLogo: jobDetails.logo,
      });
      setNotification('Application submitted successfully!');
      console.log('Application Data Submitted:', applicationData);
      setStep(1);
    } catch (error) {
      console.error('Error submitting application:', error);
      alert('Failed to submit application.');
    }
  };

  const handleCopyResume = () => {
    const textToCopy = resumeRef.current?.innerText;
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setNotification('Resume copied to clipboard!');
      }, () => {
        setNotification('Failed to copy resume.');
      });
    } else {
      alert('Resume content is not available.');
    }
  };

  const handleTextFormatting = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const handleUndoRedo = (command) => {
    document.execCommand(command, false, null);
  };

  return (
    <>
      <ClientNavbar />
     
      <Container>
        <JobDetailContainer>
          <JobDetailHeader>
            <img src={jobDetails.logo || 'https://via.placeholder.com/100'} alt="Company Logo" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            <JobDetailTitle>{jobDetails.title} - {jobDetails.companyName}</JobDetailTitle>
          </JobDetailHeader>
          <JobDetailList>
            <JobDetailItem><FontAwesomeIcon icon={faBuilding} /> {jobDetails.companyName}</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faGlobe} /> {jobDetails.location}</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faCalendarAlt} /> Expires on: {jobDetails.expiryDate}</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faClock} /> Experience: {jobDetails.experience} years</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faBriefcase} /> {jobDetails.industry}</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faInfoCircle} /> Qualifications: {jobDetails.qualifications}</JobDetailItem>
            <JobDetailItem><FontAwesomeIcon icon={faInfoCircle} /> Skills: {jobDetails.skills}</JobDetailItem>
          </JobDetailList>
        </JobDetailContainer>
        <FormSection>
          <Button onClick={() => navigate('/expertdashboard')} color="rgb(10, 88, 202)" hoverColor="#0056b3">
            Back to Expert Dashboard
          </Button>
          <form onSubmit={handleSubmit}>
            <div className="row">
              {step === 1 && (
                <>
                  <div className="col-md-6" style={{ marginBottom: '20px' }}>
                    <label htmlFor="name" className="form-label" style={{ fontWeight: 'bold' }}>Your Name:</label>
                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleInputChange} required />
                  </div>
                  <div className="col-md-6" style={{ marginBottom: '20px' }}>
                    <label htmlFor="email" className="form-label" style={{ fontWeight: 'bold' }}>Your Email:</label>
                    <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleInputChange} required />
                  </div>
                  <div className="col-md-6" style={{ marginBottom: '20px' }}>
                    <label htmlFor="phone" className="form-label" style={{ fontWeight: 'bold' }}>Your Phone No.:</label>
                    <input type="tel" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="col-12" style={{ marginBottom: '20px' }}>
                    <label htmlFor="coverLetter" className="form-label" style={{ fontWeight: 'bold' }}>Cover Letter:</label>
                    <InputFileButton htmlFor="coverLetter">Choose File</InputFileButton>
                    <InputFile type="file" id="coverLetter" name="coverLetter" accept=".pdf,.doc,.docx" onChange={handleInputChange} />
                    {formData.coverLetter && (
                      <FilePreviewContainer>
                        File: {formData.coverLetter.name}
                        <iframe
                          src={URL.createObjectURL(formData.coverLetter)}
                          title="Cover Letter Preview"
                          style={{ width: '100%', height: '500px', border: 'none', marginTop: '10px' }}
                        />
                      </FilePreviewContainer>
                    )}
                  </div>
                  {formData.coverLetter && (
                    <div className="col-12 text-center">
                      <Button type="button" color="rgb(10, 88, 202)" onClick={handleCoverLetterRewrite}>Rewrite Cover Letter</Button>
                    </div>
                  )}
                  {showProgressCircle && (
                    <div className="col-12 text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Processing...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {step === 3 && (
                <>
                  <div className="col-12" style={{ marginBottom: '20px' }}>
                    <label htmlFor="resume" className="form-label" style={{ fontWeight: 'bold' }}>Resume:</label>
                    <InputFileButton htmlFor="resume">Choose File</InputFileButton>
                    <InputFile type="file" id="resume" name="resume" accept=".pdf,.doc,.docx" onChange={handleInputChange} />
                    {formData.resume && (
                      <FilePreviewContainer>
                        File: {formData.resume.name}
                        <iframe
                          src={URL.createObjectURL(formData.resume)}
                          title="Resume Preview"
                          style={{ width: '100%', height: '500px', border: 'none', marginTop: '10px' }}
                        />
                      </FilePreviewContainer>
                    )}
                  </div>
                  {formData.resume && (
                    <div className="col-12 text-center">
                      <Button type="button" color="rgb(10, 88, 202)" onClick={handleResumeRewrite}>Rewrite Resume</Button>
                    </div>
                  )}
                  {showProgressCircle && (
                    <div className="col-12 text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Processing...</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              {step === 4 && (
                <div className="col-12" style={{ marginBottom: '20px' }}>
                  <p className="mb-3" style={{ fontWeight: 'bold' }}>Please answer the following questions in your video:</p>
                  <div style={{ padding: '15px', border: '2px solid rgb(10, 88, 202)', borderRadius: '10px', marginBottom: '20px', backgroundColor: '#e9ecef' }}>
                    {jobDetails?.questions && jobDetails.questions.length > 0 ? (
                      <ol>
                        {jobDetails.questions.map((question, index) => (
                          <li key={index} style={{ marginBottom: '10px', fontWeight: '500' }}>{question}</li>
                        ))}
                      </ol>
                    ) : <p>The client has posted no questions for this job.</p>}
                  </div>
                  {jobDetails?.questions && jobDetails.questions.length > 0 && jobDetails.questions.map((question, index) => (
                    <div key={index} style={{ marginBottom: '20px' }}>
                      <p style={{ fontWeight: 'bold' }}>Record your 2-minute video for: {question}</p>
                      <Webcam audio={true} ref={webcamRef} style={{ width: '100%', borderRadius: '8px' }} />
                      {isRecording ? (
                        <Button type="button" color="#dc3545" hoverColor="#c82333" onClick={handleStopRecording}>Stop Recording</Button>
                      ) : (
                        <Button type="button" onClick={handleStartRecording}>Start Recording</Button>
                      )}
                      {recordedVideos[index] && (
                        <VideoContainer>
                          <video src={recordedVideos[index].url} controls style={{ marginTop: '10px', maxWidth: '100%', borderRadius: '8px' }} />
                          <Button type="button" color="#dc3545" hoverColor="#c82333" onClick={() => handleDeleteVideo(index)}>Delete Video</Button>
                        </VideoContainer>
                      )}
                    </div>
                  ))}
                  {videoError && <p style={{ color: 'red' }}>{videoError}</p>}
                </div>
              )}
              {step === 5 && (
                <>
                  <div className="col-12" style={{ marginBottom: '20px' }}>
                    {personalityQuestions.map((question, index) => (
                      <PersonalityQuestion key={index}>
                        <label style={{ fontWeight: 'bold' }}>{question}</label>
                        <Select
                          value={formData.personalityAnswers[index]}
                          onChange={(e) => handlePersonalityAnswerChange(index, e.target.value)}
                          required
                        >
                          <option value="">Select an answer</option>
                          <option value="I address it head-on and seek to resolve it quickly.">I address it head-on and seek to resolve it quickly.</option>
                          <option value="I try to find a compromise that works for everyone.">I try to find a compromise that works for everyone.</option>
                          <option value="I prefer to avoid it and maintain harmony.">I prefer to avoid it and maintain harmony.</option>
                          <option value="I analyze the situation before taking any action.">I analyze the situation before taking any action.</option>
                        </Select>
                      </PersonalityQuestion>
                    ))}
                  </div>
                </>
              )}
              {step === 6 && (
                <div className="col-12" style={{ marginBottom: '20px' }}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="termsAccepted" name="termsAccepted" checked={formData.termsAccepted} onChange={handleInputChange} required />
                    <label className="form-check-label" htmlFor="termsAccepted">I accept the <Link to="#">Terms and Conditions</Link></label>
                  </div>
                </div>
              )}
              <div className="col-12 text-center">
                {step > 1 && (
                  <Button type="button" color="#6c757d" hoverColor="#5a6268" onClick={handlePreviousStep}>Previous</Button>
                )}
                {step < 6 ? (
                  <Button type="button" onClick={handleNextStep}>Next</Button>
                ) : (
                  <Button type="submit" color="#28a745" hoverColor="#218838">Apply Now</Button>
                )}
              </div>
            </div>
          </form>
          {isEditing && (
            <ResumeBuilderContainer>
              <Toolbar>
                <Button type="button" onClick={() => handleTextFormatting('bold')}>
                  <FontAwesomeIcon icon={faBold} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('italic')}>
                  <FontAwesomeIcon icon={faItalic} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('underline')}>
                  <FontAwesomeIcon icon={faUnderline} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyLeft')}>
                  <FontAwesomeIcon icon={faAlignLeft} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyCenter')}>
                  <FontAwesomeIcon icon={faAlignCenter} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyRight')}>
                  <FontAwesomeIcon icon={faAlignRight} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyFull')}>
                  <FontAwesomeIcon icon={faAlignJustify} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('insertUnorderedList')}>
                  <FontAwesomeIcon icon={faListUl} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('insertOrderedList')}>
                  <FontAwesomeIcon icon={faListOl} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('indent')}>
                  <FontAwesomeIcon icon={faIndent} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('outdent')}>
                  <FontAwesomeIcon icon={faOutdent} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('createLink', prompt('Enter a URL:', 'http://'))}>
                  <FontAwesomeIcon icon={faLink} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('unlink')}>
                  <FontAwesomeIcon icon={faUnlink} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('removeFormat')}>
                  <FontAwesomeIcon icon={faEraser} />
                </Button>
                <Button type="button" onClick={() => handleUndoRedo('undo')}>
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <Button type="button" onClick={() => handleUndoRedo('redo')}>
                  <FontAwesomeIcon icon={faRedo} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h1')}>
                  <FontAwesomeIcon icon={faHeading} /> H1
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h2')}>
                  <FontAwesomeIcon icon={faHeading} /> H2
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h3')}>
                  <FontAwesomeIcon icon={faHeading} /> H3
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Arial')}>
                  <FontAwesomeIcon icon={faFont} /> Arial
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Georgia')}>
                  <FontAwesomeIcon icon={faFont} /> Georgia
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Helvetica')}>
                  <FontAwesomeIcon icon={faFont} /> Helvetica
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '1')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Small
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '3')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Normal
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '5')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Large
                </Button>
              </Toolbar>
              <EditableField
                contentEditable
                ref={resumeRef}
                dangerouslySetInnerHTML={{ __html: updatedResume }}
              />
              <div className="text-center">
                <Button color="#28a745" hoverColor="#218838" onClick={handleSaveResume}>Save Resume</Button>
                <Button onClick={() => handleDownloadResume('pdf')}>Download as PDF</Button>
                <Button onClick={handleCopyResume}>Copy to Clipboard</Button>
                <Button color="#dc3545" hoverColor="#c82333" onClick={() => setIsEditing(false)}>Cancel</Button>
              </div>
              <div className="text-center mt-4">
                <label htmlFor="templateSelect" style={{ marginRight: '10px' }}>Choose Template:</label>
                <select id="templateSelect" onChange={(e) => setSelectedTemplate(e.target.value)} value={selectedTemplate}>
                  {resumeTemplates.map((template, index) => (
                    <option key={index} value={template}>{template}</option>
                  ))}
                </select>
              </div>
            </ResumeBuilderContainer>
          )}
          {isCoverLetterEditing && (
            <ResumeBuilderContainer>
              <Toolbar>
                <Button type="button" onClick={() => handleTextFormatting('bold')}>
                  <FontAwesomeIcon icon={faBold} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('italic')}>
                  <FontAwesomeIcon icon={faItalic} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('underline')}>
                  <FontAwesomeIcon icon={faUnderline} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyLeft')}>
                  <FontAwesomeIcon icon={faAlignLeft} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyCenter')}>
                  <FontAwesomeIcon icon={faAlignCenter} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyRight')}>
                  <FontAwesomeIcon icon={faAlignRight} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('justifyFull')}>
                  <FontAwesomeIcon icon={faAlignJustify} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('insertUnorderedList')}>
                  <FontAwesomeIcon icon={faListUl} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('insertOrderedList')}>
                  <FontAwesomeIcon icon={faListOl} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('indent')}>
                  <FontAwesomeIcon icon={faIndent} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('outdent')}>
                  <FontAwesomeIcon icon={faOutdent} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('createLink', prompt('Enter a URL:', 'http://'))}>
                  <FontAwesomeIcon icon={faLink} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('unlink')}>
                  <FontAwesomeIcon icon={faUnlink} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('removeFormat')}>
                  <FontAwesomeIcon icon={faEraser} />
                </Button>
                <Button type="button" onClick={() => handleUndoRedo('undo')}>
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
                <Button type="button" onClick={() => handleUndoRedo('redo')}>
                  <FontAwesomeIcon icon={faRedo} />
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h1')}>
                  <FontAwesomeIcon icon={faHeading} /> H1
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h2')}>
                  <FontAwesomeIcon icon={faHeading} /> H2
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('formatBlock', 'h3')}>
                  <FontAwesomeIcon icon={faHeading} /> H3
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Arial')}>
                  <FontAwesomeIcon icon={faFont} /> Arial
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Georgia')}>
                  <FontAwesomeIcon icon={faFont} /> Georgia
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontName', 'Helvetica')}>
                  <FontAwesomeIcon icon={faFont} /> Helvetica
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '1')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Small
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '3')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Normal
                </Button>
                <Button type="button" onClick={() => handleTextFormatting('fontSize', '5')}>
                  <FontAwesomeIcon icon={faTextHeight} /> Large
                </Button>
              </Toolbar>
              <EditableField
                contentEditable
                ref={coverLetterRef}
                dangerouslySetInnerHTML={{ __html: updatedCoverLetter }}
              />
              <div className="text-center">
                <Button color="#28a745" hoverColor="#218838" onClick={handleSaveCoverLetter}>Save Cover Letter</Button>
                <Button onClick={handleDownloadCoverLetter}>Download as PDF</Button>
                <Button onClick={handleCopyResume}>Copy to Clipboard</Button>
                <Button color="#dc3545" hoverColor="#c82333" onClick={() => setIsCoverLetterEditing(false)}>Cancel</Button>
              </div>
            </ResumeBuilderContainer>
          )}
          <ProgressWrapper>
            <ProgressBar width={(step / 6) * 100} />
          </ProgressWrapper>
        </FormSection>
      </Container>
      {notification && (
        <Notification>{notification}</Notification>
      )}
      <Footer />
      <ScrollTop />
    </>
  );
}