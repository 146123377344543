import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { FiTrash2, FiEdit2, FiArrowLeft } from 'react-icons/fi';
import EditJobPopup from './editjobpopup';
import heroImage from '../assets/images/hero/bg2.jpg';
import ClientNavbar from './clientnavbar';
import styled from 'styled-components';

const AllJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        fetchJobs(user.email);
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, navigate]);

  const fetchJobs = async (userEmail) => {
    const q = query(collection(db, 'jobs'), where('userEmail', '==', userEmail));
    const querySnapshot = await getDocs(q);
    const jobList = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setJobs(jobList);
  };

  const handleDeleteJob = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      await deleteDoc(doc(db, 'jobs', id));
      setJobs(jobs.filter(job => job.id !== id));
    }
  };

  const handleEditJob = (job) => {
    setSelectedJob(job);
  };

  const handleRemoveSkill = (jobId, skillValue) => {
    const updatedJobs = jobs.map(job => {
      if (job.id === jobId) {
        return {
          ...job,
          skills: job.skills.filter(skill => skill.value !== skillValue)
        };
      }
      return job;
    });
    setJobs(updatedJobs);
  };

  return (
    <>
      <ClientNavbar />
      <HeroSection>
        <HeroContent>
          <HeroHeader>My Job Listings</HeroHeader>
          <HeroCaption>Easily manage and track all your job postings</HeroCaption>
        </HeroContent>
      </HeroSection>
      <Container>
        <BackButton onClick={() => navigate('/clientdashboard')}>
          <FiArrowLeft /> Back to Client Dashboard
        </BackButton>
        <JobsGrid>
          {jobs.length > 0 ? jobs.map(job => (
            <JobCard key={job.id}>
              <CompanyLogo src={job.logo || 'default_logo_placeholder.png'} alt="Company Logo" />
              <JobDetails>
                <JobTitle>{job.title}</JobTitle>
                <JobInfo>{job.address}</JobInfo>
                <JobInfo>{job.category?.label}</JobInfo>
                <JobSalary>${job.minSalary} - ${job.maxSalary}</JobSalary>
                <SkillsContainer>
                  {Array.isArray(job.skills) && job.skills.map((skill, index) => (
                    <SkillButton key={index} onClick={() => handleRemoveSkill(job.id, skill.value)}>
                      {skill.label} <SkillRemoveButton>&times;</SkillRemoveButton>
                    </SkillButton>
                  ))}
                </SkillsContainer>
              </JobDetails>
              <JobCardActions>
                <ActionButton onClick={() => handleEditJob(job)}><FiEdit2 /> Edit</ActionButton>
                <ActionButton onClick={() => handleDeleteJob(job.id)}><FiTrash2 /> Delete</ActionButton>
              </JobCardActions>
            </JobCard>
          )) : <NoJobsMessage>No job postings found. Start adding your jobs!</NoJobsMessage>}
        </JobsGrid>
      </Container>
      {selectedJob && (
        <EditJobPopup
          isOpen={Boolean(selectedJob)}
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
          onUpdate={() => fetchJobs(auth.currentUser?.email)}
        />
      )}
    </>
  );
};

const HeroSection = styled.div`
  background-image: url(${heroImage});
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-size: cover;
  background-position: center;
  background-color: rgba(10, 88, 202, 0.7);
  background-blend-mode: multiply;
`;

const HeroContent = styled.div`
  text-align: center;
`;

const HeroHeader = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
  margin: 0;
`;

const HeroCaption = styled.p`
  font-size: 1.5rem;
  margin-top: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const JobsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
`;

const JobCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.15);
  }
`;

const CompanyLogo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
`;

const JobDetails = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const JobTitle = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
  color: rgb(10, 88, 202);
  margin: 0;
`;

const JobInfo = styled.p`
  font-size: 1rem;
  color: #666;
  margin: 5px 0;
`;

const JobSalary = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
  color: rgb(10, 88, 202);
  margin-top: 10px;
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
`;

const SkillButton = styled.button`
  background-color: #e0e0e0;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`;

const SkillRemoveButton = styled.span`
  background-color: #ff4d4d;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  cursor: pointer;
`;

const JobCardActions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const ActionButton = styled.button`
  background-color: rgb(10, 88, 202);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgb(8, 70, 160);
  }

  & svg {
    margin-right: 5px;
  }
`;

const NoJobsMessage = styled.p`
  text-align: center;
  font-size: 1.25rem;
  color: #666;
  margin-top: 20px;
`;

const BackButton = styled.button`
  background-color: rgb(10, 88, 202);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;

  &:hover {
    background-color: rgb(8, 70, 160);
  }

  & svg {
    margin-right: 5px;
  }
`;

export default AllJobs;
